import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Button, Backdrop, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { parse, format } from 'date-fns';
import { useParams } from 'react-router-dom';
import DealsList from '../DealsList';
import CheckInCheckOutButton from '../../CheckInCheckOutButton';
import RoomAndGuestButton from '../../RoomAndPeopleButton';
import { PeopleOutlined } from '../../../../atoms/Icon';

import actions from '../../../../../redux/actions';
import { OutlinedButton } from '../../../../atoms/Button/index';
import RoomListing from './RoomListing';

const useStyles = makeStyles(() => ({
  guestSelector: {
    backgroundColor: '#FFFFFF',
    color: '#4E4E4E',
    border: '1px solid #D9D9D9',
    borderRadius: '24px',
    maxHeight: '40px',
    margin: '4px',
    '&:hover': {
      border: '1px solid #D9D9D9',
      backgroundColor: '#F4F4F4',
      color: '#4E4E4E',
    },
  },
}));

function PilotDealsList() {
  const { slug: hotelKey } = useParams();
  const packageIds = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages?.map(
      (p) => p?.packageId
    )
  );
  const packages = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages
      ?.slice()
      ?.sort(
        (a, b) => (a?.totalPrice?.price || 0) - (b?.totalPrice?.price || 0)
      )
  );

  const roomCombinations = useMemo(() => {
    const combinations = {};
    const addedRoomCombinations = [];
    packages?.forEach((p) => {
      const uniqueRoomCombination = p?.rooms
        ?.map((r) => [r?.name, r?.key])
        .join(',');
      if (!addedRoomCombinations.includes(uniqueRoomCombination)) {
        combinations[uniqueRoomCombination] = [p?.packageId];
        addedRoomCombinations?.push(uniqueRoomCombination);
      } else {
        combinations[uniqueRoomCombination].push(p?.packageId);
      }
    });
    return combinations;
  }, [packages]);

  return (
    <div style={{ marginTop: 32 }}>
      {Object.values(roomCombinations || {})?.map((roomPackageIds) => (
        <div style={{ marginTop: 16 }} key={`${Math.random()}`}>
          <RoomListing roomPackageIds={roomPackageIds} />
        </div>
      ))}
      {(!packageIds || packageIds?.length === 0) && (
        <Typography
          variant="h5"
          color="text.light"
          width="100%"
          textAlign="center"
          mt={2}>
          No availability for selected dates/rooms.
        </Typography>
      )}
    </div>
  );
}

function LoadingDealsList() {
  return (
    <div style={{ marginTop: 32 }}>
      {Array.from(Array(3).keys())?.map((idx) => (
        <div style={{ marginTop: 16 }} key={idx}>
          <Skeleton variant="rectangular" height={232} width="100%" />
        </div>
      ))}
    </div>
  );
}

function DealsContainer({ loading }) {
  const classes = useStyles();
  const { slug: hotelKey } = useParams();
  const dispatch = useDispatch();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const {
    checkInDate = null,
    checkOutDate = null,
    rooms = { adults: 2, children: 0, rooms: 1 },
  } = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps || {}
  );

  const [deals, setDeals] = useState([]);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [roomAnchorEl, setRoomAnchorEl] = useState(null);
  const [showPilotDeals, setShowPilotDeals] = useState(true);

  const getRoomPriceMap = (hotelDeals) =>
    hotelDeals?.reduce((roomPriceMap, deal) => {
      if (!(deal?.roomName in roomPriceMap)) {
        roomPriceMap[deal?.roomName] = [];
      }
      roomPriceMap[deal?.roomName].push(deal);
      return roomPriceMap;
    }, {});

  const searchDates = {
    from: checkInDate && parse(checkInDate, 'yyyy-MM-dd', new Date()),
    to: checkOutDate && parse(checkOutDate, 'yyyy-MM-dd', new Date()),
  };

  const updateSearchProps = (searchProps) => {
    dispatch(
      actions.Bookings.setHotelDetailsSearchProps({
        checkInDate,
        checkOutDate,
        rooms,
        ...searchProps,
      })
    );
  };

  useEffect(() => {
    setDeals(getRoomPriceMap(hotelDetails?.results));
  }, [hotelDetails?.results]);

  return (
    <div id="deals-container">
      <Typography variant="h2">Take a look at the options...</Typography>

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 24 }}>
        <CheckInCheckOutButton
          date={searchDates}
          updateDate={({ from, to }) =>
            from &&
            to &&
            updateSearchProps({
              checkInDate: from && format(from, 'yyyy-MM-dd'),
              checkOutDate: to && format(to, 'yyyy-MM-dd'),
            })
          }
          overlayOpen={overlayOpen}
          setOverlayOpen={setOverlayOpen}
          dateStringFormat="eee, dd/MM/yy"
          disableScroll
        />
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: roomAnchorEl ? 1220 : 1099,
          }}
          open={overlayOpen}
          onClick={() => {
            setRoomAnchorEl(null);
            setOverlayOpen(false);
          }}
        />
        {!roomAnchorEl && (
          <Button
            startIcon={
              <PeopleOutlined sx={{ color: '#1D1D1D', fontSize: '30px' }} />
            }
            className={classes.guestSelector}
            onClick={(e) => {
              setRoomAnchorEl(e.currentTarget);
              setOverlayOpen(true);
            }}>
            {rooms?.total}
          </Button>
        )}
        <RoomAndGuestButton
          anchorEl={roomAnchorEl}
          setAnchorEl={setRoomAnchorEl}
          guestCount={rooms}
          setGuestCount={(newGuestCount) => {
            setOverlayOpen(false);
            if (newGuestCount) {
              updateSearchProps({
                rooms: newGuestCount,
              });
            }
          }}
          containerProps={{
            style: { display: roomAnchorEl ? 'flex' : 'none' },
          }}
        />
      </div>

      {showPilotDeals ? (
        loading ? (
          <LoadingDealsList />
        ) : (
          <PilotDealsList />
        )
      ) : (
        <div style={{ marginTop: 32 }}>
          {Object.keys(deals || {})?.map((roomName, idx) => (
            <div style={{ marginTop: idx === 0 ? 0 : 16 }}>
              <DealsList
                deals={deals[roomName]}
                roomName={roomName}
                key={idx}
              />
            </div>
          ))}
        </div>
      )}
      <div style={{ marginTop: 16, display: 'none', flex: 1 }}>
        <OutlinedButton
          style={{ marginLeft: 'auto' }}
          onClick={() => setShowPilotDeals(!showPilotDeals)}>
          View {showPilotDeals ? 'Other' : 'Pilot'} Deals
        </OutlinedButton>
      </div>
    </div>
  );
}

export default DealsContainer;
