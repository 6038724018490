import { makeStyles } from '@mui/styles';
import { HotelRounded } from '@mui/icons-material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Grid, Tooltip, Typography, Fade } from '@mui/material';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import { DraggableIconPlaceHolder } from '../../atoms/Icon';

const useStyles = makeStyles({
  hotelContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
});

function HotelButton({
  commonStyles,
  isClicked,
  handleAddAccommodation,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();

  return (
    <Grid item>
      <Droppable
        droppableId="droppable-accommodation"
        type="SECTION"
        isDropDisabled>
        {(droppableProvided, droppableSnapshot) => (
          <Tooltip
            title={
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                  Stays
                </Typography>
                {isKeyboardShortcutEnabled && (
                  <Box sx={commonStyles.shortcutKey}>S</Box>
                )}
              </Box>
            }
            enterDelay={500}
            TransitionComponent={Fade}
            placement="top"
            arrow
            componentsProps={commonStyles.componentsProps}>
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              <Draggable
                type="ACCOMMODATION"
                key="accommodation"
                draggableId="commandBar-accommodation"
                index={0}
                id={0}
                disableInteractiveElementBlocking>
                {(provided, snapshot) => (
                  <PortalAwareDraggableItem snapshot={snapshot}>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Box
                        sx={
                          snapshot.isDragging
                            ? commonStyles.draggableExpandedState
                            : isClicked
                            ? commonStyles.draggableIconClickStyle
                            : commonStyles.hoverStyle
                        }
                        onClick={handleAddAccommodation}>
                        <HotelRounded className={classes.hotelContainer} />
                      </Box>
                    </div>
                  </PortalAwareDraggableItem>
                )}
              </Draggable>
              {droppableProvided.placeholder &&
                droppableSnapshot.isUsingPlaceholder && (
                  <DraggableIconPlaceHolder />
                )}
            </div>
          </Tooltip>
        )}
      </Droppable>
    </Grid>
  );
}

export default HotelButton;
