import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography } from '@mui/material';
import {
  EventNoteOutlined,
  GroupOutlined,
  HotelOutlined,
  LockOutlined,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useMemo } from 'react';

const useStyles = makeStyles(() => ({
  roomImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, #000000 0%, #000000 20%)',
  },
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

function CheckoutRoomSummary() {
  const classes = useStyles();
  const { slug: hotelId } = useParams();

  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);
  const selectedPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
      (p) => p?.packageId === packageId
    )
  );

  const refundableCxlPolicies = useSelector((state) =>
    state.Bookings.cart.cxlPolicy[packageId]?.cxlPolicies?.filter(
      (policy) => new Date(policy?.fromDate) > new Date()
    )
  );

  const getCxlFinalDate = () => {
    const refundableCxlPolicy =
      refundableCxlPolicies?.length > 0 && refundableCxlPolicies[0];
    if (!refundableCxlPolicy) return null;
    const finalCxlPolicyDate = new Date(refundableCxlPolicy?.fromDate);
    finalCxlPolicyDate.setDate(finalCxlPolicyDate.getDate() - 1);
    finalCxlPolicyDate.setHours(23, 59, 50, 999);
    return finalCxlPolicyDate;
  };

  const cxlFinalDate = useMemo(
    () => getCxlFinalDate(),
    [refundableCxlPolicies]
  );

  const roomDetails = selectedPackage?.rooms[0];

  const room = useSelector(
    (state) =>
      state.Bookings.hotelDetailsPage?.data[hotelId]?.rooms[roomDetails?.key]
  );

  const searchProps = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps
  );

  return (
    <Grid container className={classes.box}>
      <Grid item xs={12} sm={4}>
        <img
          src={room.images[0]}
          alt="hotel-img"
          style={{
            objectFit: 'cover',
          }}
          className={classes.roomImage}
        />
      </Grid>
      <Grid
        container
        xs={12}
        sm={8}
        flexDirection="column"
        padding="20px 20px 20px 24px">
        <Typography variant="h2">{roomDetails.name}</Typography>
        <Box display="flex" alignItems="center" mt={1.5}>
          <Box display="flex" alignItems="center">
            <EventNoteOutlined sx={{ mr: 1 }} />
            <Typography variant="h5" color="text.secondary">
              {format(new Date(searchProps.checkInDate), 'MMM') !==
              format(new Date(searchProps.checkOutDate), 'MMM')
                ? `${format(
                    new Date(searchProps.checkInDate),
                    'MMM dd'
                  )} - ${format(new Date(searchProps.checkOutDate), 'MMM dd')}`
                : `${format(
                    new Date(searchProps.checkInDate),
                    'MMM dd'
                  )} - ${format(new Date(searchProps.checkOutDate), 'dd')}`}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" ml={3}>
            <GroupOutlined sx={{ mr: 1 }} />
            <Typography variant="h5" color="text.secondary">
              {searchProps.rooms?.total} guests
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" ml={3}>
            <HotelOutlined sx={{ mr: 1 }} />
            <Typography variant="h5" color="text.secondary">
              {searchProps.rooms?.rooms} room
              {searchProps.rooms?.rooms > 1 ? 's' : ''}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt={2}>
          {selectedPackage?.isRefundable ? (
            <LockOutlined
              sx={{ mr: 1, height: 18, width: 18 }}
              color="success"
            />
          ) : (
            <LockOutlined sx={{ mr: 1, height: 18, width: 18 }} color="error" />
          )}
          <Typography
            variant="h4"
            color={
              selectedPackage?.isRefundable ? 'success.main' : 'error.main'
            }
            sx={{ textDecoration: 'underline', fontSize: 15 }}>
            {selectedPackage?.isRefundable && cxlFinalDate
              ? `Don't worry you have until ${format(
                  cxlFinalDate,
                  'MMM dd, yyyy hh:mm a'
                )} to cancel for free`
              : 'This package is non-refundable'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CheckoutRoomSummary;
