import React, { useRef } from 'react';
import {
  Modal,
  ImageList,
  ImageListItem,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
} from '@mui/icons-material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImgWithFallback from '../../../../../atoms/ImgWithFallback';

const useStyles = makeStyles(({ breakpoints }) => ({
  imageListWrapper: (active) => ({
    width: '100%',
    height: '100%',
    maxWidth: 1222,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: active ? '#222' : '#FFF',
    padding: '60px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 8,
    outline: 'none',
    [breakpoints.down('lg')]: {
      padding: '32px 24px',
      margin: 0,
    },
  }),
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 20,
    top: 20,
    [breakpoints.down('lg')]: {
      right: 6,
      top: 6,
    },
  },
  carouselDots: {
    display: 'flex !important',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    bottom: '0px',
    marginTop: 'auto',
    '& > li': {
      minWidth: 120,
      minHeight: 80,
      height: 80,
      width: 120,
      margin: '0px 4px',
      opacity: 0.6,
    },
    '& > .slick-active': {
      opacity: 1,
    },
    [breakpoints.down('lg')]: {
      bottom: '60px',
    },
  },
  carousel: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .slick-list': {
      height: 'calc(100% - 80px - 24px - 28px)',
      overflow: 'hidden',
    },
    '& .slick-track': {
      height: '100%',
      [breakpoints.down('lg')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& .slick-slide': {
      [breakpoints.down('lg')]: {
        maxHeight: 300,
      },
      [breakpoints.down('sm')]: {
        maxHeight: 200,
      },
      '& div': {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      '& img': {
        width: 'auto !important',
        height: '100%',
        borderRadius: 16,
      },
    },
  },
  imageCarouselWrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
    paddingTop: 30,
  },
  pagingContainer: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    bottom: 100,
    [breakpoints.down('lg')]: {
      bottom: 160,
    },
  },
  navIcon: {
    color: '#FFF',
    height: 16,
    width: 16,
    cursor: 'pointer',
  },
}));

function ImageViewModal({
  open,
  activeImage,
  setActiveImage,
  images = [],
  handleClose = () => {},
}) {
  const classes = useStyles(activeImage !== null);
  const carouselRef = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));

  const handleSlideChange = (_, newIndex) => {
    setActiveImage(newIndex);
    document.getElementById(`hotel-slick-page-${newIndex}`)?.scrollIntoView();
  };

  const handleArrowNavigation = (e) => {
    if (e.key === 'ArrowRight') {
      carouselRef?.current?.slickNext();
    }
    if (e.key === 'ArrowLeft') {
      carouselRef?.current?.slickPrev();
    }
  };

  return (
    <Modal
      open={open}
      disableAutoFocus
      onClose={handleClose}
      onKeyDown={handleArrowNavigation}
      sx={{ padding: isTab ? 0 : 3 }}>
      <div className={classes.imageListWrapper}>
        <div className={classes.iconContainer}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setActiveImage(null);
              handleClose();
            }}>
            <CloseRounded
              sx={{ color: activeImage !== null ? '#FFF' : '#222' }}
            />
          </IconButton>
        </div>
        {activeImage !== null ? (
          <div className={classes.imageCarouselWrapper}>
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                right: isTab ? 0 : 40,
                top: isTab ? 120 : 0,
              }}>
              <ChevronLeftRounded
                onClick={() => carouselRef?.current?.slickPrev()}
                className={classes.navIcon}
                sx={{ mr: 3 }}
              />
              <ChevronRightRounded
                onClick={() => carouselRef?.current?.slickNext()}
                className={classes.navIcon}
              />
            </div>
            <Slider
              arrows={false}
              ref={carouselRef}
              beforeChange={handleSlideChange}
              slidesToShow={1}
              slidesToScroll={1}
              infinite
              initialSlide={activeImage}
              dots
              dotsClass={`slick-dots slick-thumb ${classes.carouselDots}`}
              accessibility={false}
              className={classes.carousel}
              customPaging={(i) => (
                <ImgWithFallback
                  id={`hotel-slick-page-${i}`}
                  alt={`hotel-slick-page-${i}`}
                  src={images[i]}
                  style={{
                    height: 80,
                    width: 120,
                    borderRadius: 8,
                  }}
                />
              )}>
              {images?.map((image, idx) => (
                <ImgWithFallback
                  src={image}
                  alt={`hotel-${idx}`}
                  loading="lazy"
                  key={idx}
                />
              ))}
            </Slider>{' '}
            <div className={classes.pagingContainer}>
              <Typography variant="h5" color="#FFF" fontSize="12px">
                {activeImage + 1}/{images?.length}
              </Typography>
            </div>
          </div>
        ) : (
          <ImageList
            variant="quilted"
            cols={isMobile ? 2 : 3}
            rowHeight={isMobile ? 142 : 240}
            gap={16}
            sx={{
              height: '100%',
              overflow: 'auto',
            }}>
            {images?.map((image, idx) => (
              <ImageListItem
                key={idx}
                cols={isMobile ? 1 : idx === 0 ? 2 : 1}
                rows={1}
                onClick={() => setActiveImage(idx)}>
                <ImgWithFallback
                  src={image}
                  alt={`hotel-${idx}`}
                  loading="lazy"
                  style={{
                    borderRadius: 6,
                    cursor: 'pointer',
                    objectFit: 'cover',
                  }}
                  height={isMobile ? 142 : 240}
                  width={(idx === 0 ? 2.09 : 1) * (isMobile ? 142 : 340)}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </div>
    </Modal>
  );
}

export default ImageViewModal;
