import { Close } from '@mui/icons-material';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    maxWidth: '600px',
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      width: '75%',
    },
    left: '50%',
    minHeight: '250px',
    top: '50%',
    transform: 'translate(-50%, -50%) !important',
    borderRadius: '8px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    padding: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '24px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
  },
  content: {
    color: '#4E4E4E',
  },
  shortcutContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '18px',
  },
  shortcutKey: {
    display: 'flex',
    width: '20px',
    height: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    padding: '4px 8px',
    fontSize: '14px',
    fontWeight: 500,
    backgroundColor: '#f4f4f4',
    color: '#8A8A8A',
  },
  closeButton: {
    color: '#000000',
    padding: '2px',
  },
  plusIcon: {
    fontSize: '14px',
    margin: '0 4px',
    fontWeight: 100,
  },
}));

function KeyboardShortcutModal({ open, onClose }) {
  const classes = useStyles();

  const shortcuts = [
    {
      label: 'Open command menu',
      key: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Typography className={classes.shortcutKey}>⌘</Typography>
          <Typography className={classes.plusIcon}>+</Typography>
          <Typography className={classes.shortcutKey}>K</Typography>
        </Box>
      ),
    },
    { label: 'Create new destination', key: 'D' },
    { label: 'Create new heading', key: 'H' },
    { label: 'Create new transportation', key: 'T' },
    { label: 'Create new stay', key: 'S' },
    { label: 'Create new activity', key: 'A' },
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography className={classes.title}>Keyboard shortcuts</Typography>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        {shortcuts.map((shortcut, index) => (
          <Box key={index} className={classes.shortcutContainer}>
            <Typography className={classes.content}>
              {shortcut.label}
            </Typography>
            {typeof shortcut.key === 'string' ? (
              <Typography className={classes.shortcutKey}>
                {shortcut.key}
              </Typography>
            ) : (
              shortcut.key
            )}
          </Box>
        ))}
      </Box>
    </Modal>
  );
}

export default KeyboardShortcutModal;
