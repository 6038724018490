import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Divider, useMediaQuery } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import RecommendationCitySearchbar from '../../molecules/Searchbar/RecommendationCitySearchbar';
import RecommendationCardContainer from './RecommendationCardContainer';
import recommendationCategories from '../../../assets/recommendationCategories.json';
import getPoiRecommendations from './api';
import CategorySelector from './CategorySelector';
import { useMapUtils } from '../MapUtils';
import actions from '../../../redux/actions';
import { FilterListIcon } from '../../atoms/Icon';
import { EVENTS, phTrackEvent } from '../../../analytics';

const useStyles = makeStyles(({ palette }) => ({
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  // city view
  titleContainer: {
    display: 'flex',
    width: '100%',
  },
  iconButton: {
    display: 'flex',
    padding: 0,
    marginRight: 4,
    color: palette.text.light,
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: 'transparent',
    },
  },

  // filter Tag
  filterPill: {
    backgroundColor: palette.system.white,
    border: `1px solid ${palette.system.grey}`,
    color: palette.text.main,
    borderRadius: 6,
    whiteSpace: 'nowrap',
    padding: '8px 16px',
    alignItems: 'center',
    display: 'flex',
    marginRight: 8,
    marginTop: 8,
  },
}));

// TODO: Flawed logic. Update using rankings
const getTopCategories = (categories = []) => {
  // Only show sub categories

  const mainCategories = Object.keys(recommendationCategories.order);
  const topCategories = categories.filter(
    (category) =>
      !mainCategories.includes(category) &&
      Boolean(recommendationCategories.ref[category])
  );

  return topCategories || [];
};

const transformRecommendation = ({
  title,
  images,
  openHours,
  price,
  categories = [],
  ...rest
}) => {
  return {
    title,
    thumbnail: images && images[0],
    images,
    openingHours: openHours,
    categories,
    rankedCategories: [...getTopCategories(categories), ...categories],
    price: (price && '$'.repeat(price + 1)) || '',
    ...rest,
  };
};

function RecommendationsView() {
  const classes = useStyles();
  const { focusPlace } = useMapUtils();
  const [filterOpen, setFilterOpen] = useState(false);
  const isDetailsActive = useSelector(
    (state) => state.View.poiDetails.isInView
  );

  const [loading, setLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);

  const dispatch = useDispatch();

  const { slug: tripId } = useParams();

  const { city = null, pois: recommendedOptions = [] } = useSelector(
    (state) => state.Recommendations.recommendations[tripId] || {}
  );

  const { cityLocationDetails } = useSelector(
    (state) => state.Bookings[tripId] || {}
  );

  const cityLocationSearch = useSelector(
    (state) => state.View.exploreCitySearch
  );

  const savedList = useSelector(
    (state) => Object.values(state.Recommendations.saved[tripId] || {}) || []
  );

  const isSavedVisible = useSelector(
    (state) => state.Bookings[tripId]?.isSaveListVisible
  );

  const mapPins = useSelector((state) => state.Map.mapPins[tripId] || {});
  const sections = useSelector((state) => state.Section.sections || {});

  const searchTags = useSelector((state) =>
    Array.from(
      new Set(
        Object.values(state.Location.locations || {})
          ?.filter((loc) => loc.tripID === tripId && loc.name !== '')
          ?.map((loc) => {
            const mapPin = (loc?.mapPin && mapPins[loc?.mapPin]) || {};
            const activityCount = loc.thingsToDo?.reduce(
              (currCount, sectionId) => {
                const section = sections[sectionId] || {};
                return currCount + (section?.todos?.length || 0);
              },
              0
            );

            if (!mapPin) return null;
            return {
              title: loc.name,
              latitude: mapPin?.lat || null,
              longitude: mapPin?.long || null,
              id: mapPin?.id,
              activityCount,
              placeId: mapPin?.placeId,
              hasMapPin: Boolean(loc.mapPin),
            };
          })
          ?.filter((tag) => Boolean(tag))
      )
    )
  );

  const [filterLabels, setFilterLabels] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updateRecommendations = async (
    coordinates = city?.coordinates,
    action = 'FETCH',
    sortOption = 'RATING'
  ) => {
    if (!coordinates || loading) return;
    if (
      coordinates?.latitude === city?.coordinates?.latitude &&
      coordinates?.longitude === city?.coordinates?.longitude &&
      action !== 'REFETCH' &&
      action !== 'UPDATE'
    )
      return;
    const setStatusSetter =
      action === 'REFETCH' ? setRefetchLoading : setLoading;

    setStatusSetter(true);

    // Fetch recommendations api
    const { pois = [] } = await getPoiRecommendations({
      coordinates,
      filterTags: filterLabels,
      isRefetch: action === 'REFETCH',
      sortOption,
    });

    dispatch(
      actions.Recommendations.setPois({
        pois: [
          ...(action === 'REFETCH' ? recommendedOptions : []),
          ...(pois || []),
        ],
        tripId,
      })
    );

    setStatusSetter(false);
  };

  const handleCitySelect = (newCity, disableTrack = false) => {
    dispatch(actions.Recommendations.setCity({ city: newCity, tripId }));
    if (newCity?.coordinates) {
      focusPlace({ ...newCity?.coordinates, zoom: 10 }, false);
      updateRecommendations(newCity?.coordinates);
    }
    if (!disableTrack) window?.heap?.track('explore-pois-search-init');
  };
  useEffect(() => {
    if (cityLocationSearch === cityLocationDetails?.title) {
      handleCitySelect({
        coordinates: {
          latitude: cityLocationDetails?.latitude,
          longitude: cityLocationDetails?.longitude,
        },
        title: cityLocationDetails?.title,
        placeId: cityLocationDetails?.placeId,
      });
    }
  }, []);

  const pageDidMount = useRef(false);
  const { state } = useLocation();

  useEffect(() => {
    if (!pageDidMount.current) {
      pageDidMount.current = true;
      return;
    }
    updateRecommendations(city?.locationId, 'UPDATE');
  }, [filterLabels]);

  useEffect(() => {
    if (state) {
      const { newCity } = state;
      // check newCity include all the fields
      if (
        newCity &&
        newCity.title &&
        newCity.coordinates &&
        newCity.id &&
        newCity.placeId
      ) {
        handleCitySelect(newCity);
      }
    }
  }, []);

  useEffect(() => {
    if (state && state.newCity) {
      return;
    }
    if (!city && searchTags.length > 0) {
      if (searchTags?.filter((tag) => tag?.hasMapPin).length === 0) {
        handleCitySelect(
          {
            title: searchTags[0]?.title,
          },
          true
        );
      } else {
        const defaultLocationToSearch = (searchTags || []).sort(
          (a, b) => b.activityCount - a.activityCount
        )[0];
        handleCitySelect(
          {
            title: defaultLocationToSearch?.title,
            coordinates: {
              latitude: defaultLocationToSearch?.latitude,
              longitude: defaultLocationToSearch?.longitude,
            },
            id: defaultLocationToSearch?.id,
            placeId: defaultLocationToSearch?.placeId,
          },
          true
        );
      }
    }
  }, []);

  const filterRef = useRef();

  return (
    <>
      <div id="explore-page" style={{ width: '100%', minHeight: 1 }} />
      <div className={classes.searchContainer}>
        <div style={{ width: '100%' }}>
          <RecommendationCitySearchbar
            onSelect={handleCitySelect}
            searchTags={searchTags?.filter((tag) => tag?.hasMapPin)}
            city={city}
          />
        </div>
      </div>
      {city?.coordinates ? (
        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            gap: '10px',
            // justifyContent: 'flex-start',
            // alignItems: 'center',
          }}>
          <Button
            ref={filterRef}
            marginRight={1}
            onClick={() =>
              setFilterOpen(
                document.getElementById(
                  isMobile ? 'explore-page' : 'categories-reference'
                )
              )
            }
            sx={{
              backgroundColor: filterOpen ? '#D9D9D9' : '#FFFFFF',
              color: '#4E4E4E',
              border: '1px solid #D9D9D9',
              borderRadius: '24px',
              maxHeight: '40px',
              margin: '4px',

              '&:hover': {
                border: '1px solid #D9D9D9',
                backgroundColor: '#F4F4F4',
                color: '#4E4E4E',
              },
            }}
            disableRipple
            startIcon={
              <FilterListIcon sx={{ color: '#1D1D1D', fontSize: '30px' }} />
            }>
            {`Filters ${
              filterLabels?.length > 0 ? `(${filterLabels?.length})` : ''
            }`}
          </Button>
        </div>
      ) : null}
      <Divider sx={{ width: '100%', marginTop: '15px' }} />
      <div>
        {isSavedVisible ? (
          // Init view
          <div>
            <RecommendationCardContainer
              recommendations={savedList
                ?.map((savedItem) => {
                  if (!savedItem) return null;
                  return {
                    ...(savedItem || {}),
                    ...JSON.parse(savedItem?.savedData || null),
                  };
                })
                ?.filter((item) => Boolean(item))}
              title="Saved places"
              fallbackText="😯 Oops, looks like you do not have anything saved here"
              isSavedList
            />
          </div>
        ) : (
          // City recommendations
          <RecommendationCardContainer
            recommendations={recommendedOptions?.map(
              (r) => transformRecommendation(r) || []
            )}
            loading={loading}
            title={
              loading
                ? 'Finding places that match your interests...'
                : recommendedOptions?.length > 0
                ? 'Top suggestions'
                : ''
            }
            handleRefetch={() => {
              updateRecommendations(city?.coordinates, 'REFETCH');
            }}
            handleSortFieldUpdate={(sortOption) => {
              phTrackEvent({
                event: EVENTS.EXPLORE_ACTIVITIES.SORT_UPDATE,
              });
              updateRecommendations(city?.locationId, 'UPDATE', sortOption);
            }}
            scrollRefetching={refetchLoading}
            fallbackText="😯 Oops, we couldn't find any places for this location, please try with a different combination of filters"
          />
        )}
      </div>
      <CategorySelector
        filterLabels={filterLabels}
        setFilterLabels={setFilterLabels}
        filterOpen={!isDetailsActive && filterOpen}
        setFilterOpen={setFilterOpen}
        source="recommendations"
      />
    </>
  );
}

export default RecommendationsView;
