import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Modal, Snackbar, useMediaQuery } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useTheme } from '@mui/styles';
import { getCompleteTrip } from '../../../../redux/slices/Trips';
import generateItineraryFromPrompt from './api';
import InitialScreen from './initialScreen';
import { checkIfTourIsCompleted } from '../../../../utils/tourUtils';
import actions from '../../../../redux/actions';
import FLAGS from '../../../../featureFlags';
import { onboardingTourNames } from '../../../../assets/onboarding/steps';

function QuickstartModal({
  tripId,
  fileId,
  handleOpen = () => {},
  handleClose = () => {},
  ...props
}) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const tripItems = useSelector((state) => state.Trips.trips[tripId].items);
  const [isLoading, setIsLoading] = useState(false);
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isOnboardingEnabled = useFeatureFlagEnabled(FLAGS.TRIP_ONBOARDING);
  const createTripFlowEnabled = useFeatureFlagEnabled(FLAGS.CREATE_TRIP_FLOW);

  const handleGenerateTripFromPrompt = async ({ description, interests }) => {
    setIsLoading(true);
    // Check if prompt has duration, if not add a default duration
    const durationIndicators = ['day', 'week', 'month', 'hour'];
    const descriptionHasDuration = durationIndicators.some((indicator) =>
      description?.toLowerCase()?.includes(indicator)
    );

    const userPrompt = `Give me a${
      descriptionHasDuration ? '' : ' 7 day'
    } itinerary for ${description}. ${
      interests
        ? `Include these ${
            Array.isArray(interests) ? interests.join(',') : interests
          } intrests as part of the plan.`
        : ''
    }`;

    // Generate plan from prompt
    const isPlanGenerated = await generateItineraryFromPrompt({
      userPrompt,
      tripId,
    });

    if (isPlanGenerated) {
      // Refresh the trip to get the updated trip plan
      await dispatch(getCompleteTrip({ tripId }));
      const tourName = 'quickStartFlowWeb';
      if (
        !checkIfTourIsCompleted(Object.values(onboardingTourNames)) &&
        isOnboardingEnabled &&
        createTripFlowEnabled &&
        !isMobile
      ) {
        dispatch(actions.View.setTourName(tourName));
        dispatch(actions.View.setTourCurrentStep(0));
        dispatch(actions.View.setTourOpen(true));
      }
    } else {
      // handle trip generation error
      setErrorPopupOpen(true);
    }

    setIsLoading(false);
    handleClose();
  };

  const checkForQuickstartOpen = () => {
    if (searchParams.get('autoImport')) {
      const description = searchParams.get('description');
      const filterLabels = searchParams.get('filterLabels');
      if (description) {
        handleOpen();
        handleGenerateTripFromPrompt({
          description,
          interests: filterLabels,
        });
      }
      setSearchParams({
        o: 'quickstart',
      });
    } else if (searchParams.get('quickstart') && tripItems.length === 0) {
      handleOpen();
      setSearchParams({
        o: 'quickstart',
      });
    }
  };

  useEffect(() => {
    checkForQuickstartOpen();
  }, []);

  return (
    <>
      <Modal {...props}>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <InitialScreen
            isLoading={isLoading}
            handleClose={handleClose}
            handleGenerateTripFromPrompt={handleGenerateTripFromPrompt}
          />
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={errorPopupOpen}
        onClose={() => setErrorPopupOpen(false)}
        autoHideDuration={6000}>
        <Alert
          onClose={() => setErrorPopupOpen(false)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}>
          Trip generation from Quickstart failed for some reason. Please try
          again.
        </Alert>
      </Snackbar>
    </>
  );
}

export default QuickstartModal;
