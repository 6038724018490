import React, { useContext } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { firebaseAuth } from '../../provider/AuthProvider';
import { ButtonWithIcon } from '../atoms/Button';
import { GoogleLogo, FacebookLogo, AppleLogo } from '../atoms/Icon';

const useStyles = makeStyles(() => ({
  grayColor: {
    color: '#757575',
  },
}));

function SocialMediaAuth() {
  const classes = useStyles();
  const { handleFacebookSignin, handleGoogleSignin, handleAppleSignin } =
    useContext(firebaseAuth);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Social Login */}
      <ButtonWithIcon
        id="GoogleLoginButton"
        onClick={handleGoogleSignin}
        style={{
          backgroundColor: '#FFFFFF',
          borderColor: '#D9D9D9',
          width: '100%',
          height: '3rem',
        }}
        variant="contained">
        <GoogleLogo />
        <Box ml="1.3rem" className={classes.grayColor}>
          Continue with Google
        </Box>
      </ButtonWithIcon>

      <ButtonWithIcon
        id="FacebookLoginButton"
        onClick={handleFacebookSignin}
        style={{
          backgroundColor: '#1877F2',
          width: '100%',
          height: '3rem',
          marginTop: 12,
        }}
        variant="contained">
        <FacebookLogo />
        <Box ml="1rem">Continue with Facebook</Box>
      </ButtonWithIcon>

      <ButtonWithIcon
        id="AppleLoginButton"
        onClick={handleAppleSignin}
        style={{
          backgroundColor: '#000',
          width: '100%',
          height: '3rem',
          marginTop: 12,
        }}
        variant="contained">
        <AppleLogo />
        <Box ml="4px" color="#fff">
          Continue with Apple
        </Box>
      </ButtonWithIcon>
    </Box>
  );
}

export default SocialMediaAuth;
