/* eslint-disable */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material';
import { BookmarkRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { computeAmenitiesV2, getRatingProps } from '../utils';
import PriceNav from './Navbar/PriceNav';
import classList from '../../../../classList';

const useStyles = makeStyles(({ breakpoints }) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    [breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  rating: {
    width: 32,
    height: 18,
    borderRadius: '10px',
    fontSize: 12,
    textAlign: 'center',
    color: '#FFF',
  },
  saveIconContainer: (saved) => ({
    display: 'flex',
    marginRight: 8,
    marginLeft: 'auto',
    alignItems: 'center',
    cursor: 'pointer',
    color: saved ? '#ED702E' : '#8A8A8A',
    '&:hover': {
      color: '#FFA766',
      '& > svg > path': {
        stroke: '#FFA766',
      },
    },
  }),
  saveIcon: (saved) => ({
    color: saved ? '#ED702E' : 'transparent',
    marginRight: 4,
    '& > path': {
      stroke: saved ? '#ED702E' : '#8A8A8A',
      strokeWidth: '1',
    },
  }),
}));

function HotelHeading({ saved, handleSaveClick }) {
  const { slug: hotelKey } = useParams();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const prevTripId = window.localStorage.getItem('prevTripId');

  const [showMore, setShowMore] = useState(false);

  const noOfReviews = hotelDetails?.reviews?.numberOfReviews || 0;

  // if noOfReviews is < 25 then we'll not show the ratingSentiment
  const [ratingSentiment, ratingColor] =
    noOfReviews > 25
      ? getRatingProps(hotelDetails?.reviews?.guestRatings?.OVERALL)
      : [null, null];

  const { topFeatures = [] } = computeAmenitiesV2(
    hotelDetails?.additional?.facilities?.map((f) => f?.Facility)
  );
  const descriptions = hotelDetails?.additional?.descriptions?.filter(
    (d) =>
      ['FullDescription', 'ShortDescription']?.includes(d?.Title) ||
      d?.Line === 1
  );
  const description =
    descriptions?.length > 0 ? descriptions[0]?.Descrption : '';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const classes = useStyles(saved);

  return (
    <div style={{ marginTop: 24 }}>
      <div style={{ display: 'flex' }}>
        <Typography variant="h2">{hotelDetails?.name}</Typography>
        {/* <div className={classes.title}>
          {ratingColor && ratingSentiment && (
            <div style={{ display: 'flex', marginTop: isTab ? 8 : 0 }}>
              <Typography
                className={classes.rating}
                style={{
                  backgroundColor: ratingColor,
                  marginLeft: 12,
                }}>
                {hotelDetails?.reviews?.guestRatings?.OVERALL?.toFixed(1)}
              </Typography>
              <Typography
                variant="h5"
                style={{ marginLeft: 6, color: '#4E4E4E' }}>
                <b>{ratingSentiment}</b>(
                {hotelDetails?.reviews?.numberOfReviews})
              </Typography>
            </div>
          )}
        </div> 
      */}

        {isMd && !isMobile && prevTripId && (
          <Typography
            variant="h5"
            className={classes.saveIconContainer}
            onClick={handleSaveClick}>
            <BookmarkRounded className={classes.saveIcon} />
            {saved ? 'Saved' : 'Save'}
          </Typography>
        )}
      </div>
      <Typography variant="h4" mt={isTab ? '6px' : '12px'}>
        {hotelDetails?.address}, {hotelDetails?.countryName}
      </Typography>

      {description && (
        <>
          <Typography
            variant="h4"
            sx={{
              transition: 'all 0.5s ease',
              display: 'inline-flex',
              maxHeight: showMore ? '100%' : 88,
              overflow: 'hidden',
              mt: 2,
            }}>
            {description}
          </Typography>

          <Typography
            variant="h5"
            color="#4E4E4E"
            className={
              showMore
                ? classList.stayDetailViewAllDescriptionBtn
                : classList.stayDetailViewLessDescriptionBtn
            }
            sx={{ display: 'inline-flex', cursor: 'pointer' }}
            onClick={() => setShowMore(!showMore)}>
            Show {showMore ? 'less' : 'more'}
          </Typography>
        </>
      )}

      {isMobile && (
        <div
          style={{
            borderTop: '1px solid #D9D9D9',
            marginTop: 16,
            width: '100%',
            display: 'flex',
            paddingTop: 16,
          }}>
          <PriceNav />
        </div>
      )}

      {topFeatures?.length > 0 ? (
        <div style={{ marginTop: 32 }}>
          <Typography variant="h2">Why Stay Here</Typography>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: 16,
              gap: 24,
            }}>
            {topFeatures?.map(({ text, Icon }, idx) => (
              <Typography
                style={{
                  padding: '8px 24px',
                  border: '1px solid #D9D9D9',
                  borderRadius: 8,
                  display: 'flex',
                  ...(isMobile
                    ? {
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }
                    : {}),
                }}
                key={idx}>
                <Icon sx={{ marginRight: '8px' }} />
                {text}
              </Typography>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default HotelHeading;
