const CHECKOUT_STEPS = {
  ROOM_SELECTION: 'Room Selection',
  GUEST_INFO: 'Guest Information',
  PAYMENT_DETAILS: 'Payment Details',
};

export const CHECKOUT_SESSION_STATUS = {
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_SUCCESS_BOOKING_PENDING: 'PAYMENT_SUCCESS_BOOKING_PENDING',
  BOOKING_CONFIRMED: 'BOOKING_CONFIRMED',
  PAYMENT_SUCCESS_BOOKING_FAILED: 'PAYMENT_SUCCESS_BOOKING_FAILED',
  SUCCESS: 'SUCCESS',
};

export default CHECKOUT_STEPS;
