import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../molecules/Logo';

const useStyles = makeStyles(({ breakpoints }) => ({
  appbar: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #DEDDDD',
    padding: '24px 60px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '6px 8px',
    },
    zIndex: 1098,
  },
  toolbar: {
    minHeight: '40px',
    [breakpoints.down('lg')]: {
      minHeight: '65px',
    },
    [breakpoints.down('sm')]: {
      minHeight: '80px',
    },
  },
  startContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  endContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
}));

function CancelNavbar({ hotelName, tripId }) {
  const navRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [navHeight, setNavHeight] = useState(64);

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(`/trips/${tripId}/planner`);
  };

  const classes = useStyles();

  useEffect(() => {
    setNavHeight(navRef?.current?.clientHeight);
  }, [navRef?.current]);

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appbar}
        elevation={0}
        id="cancel-navbar"
        ref={navRef}>
        <Toolbar className={classes.toolbar}>
          <div>
            <div className={classes.startContainer}>
              <IconButton onClick={navigateBack}>
                <ChevronLeftRounded />
              </IconButton>
              <Typography
                variant="h1"
                textOverflow="ellipsis"
                color="text.primary"
                sx={{
                  marginLeft: '4px',
                  fontSize: isMobile ? '16px' : 'auto',
                }}>
                {hotelName}
              </Typography>
            </div>
          </div>
          <div className={classes.endContainer}>
            <div style={{ marginLeft: '10px' }}>
              <Logo fullLogo imageWidth="100px" />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          minHeight: navHeight,
        }}
      />
    </>
  );
}

export default CancelNavbar;
