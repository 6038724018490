import React, { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BasicButton } from '../../../../../atoms/Button/index';
import ImageViewModal from './ImageViewModal';
import ImgWithFallback from '../../../../../atoms/ImgWithFallback';

const useStyles = makeStyles(() => ({
  imageList: {
    display: 'flex',
    height: 320,
    width: '100%',
  },
  image: {
    height: '100%',
    minWidth: 240,
    marginRight: '24px',
    borderRadius: 8,
    cursor: 'pointer',
    '&:first-child': {
      minWidth: 504,
    },
  },
  gradientOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 160,
    background: 'linear-gradient(to left, rgba(255,255,255,0.8), transparent)',
  },
  btnContainer: {
    position: 'absolute',
    right: 12,
    bottom: 20,
  },
}));

function ImageContainer() {
  const { slug: hotelKey } = useParams();
  const [activeImage, setActiveImage] = useState(null);
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );

  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <div style={{ overflow: 'hidden', position: 'relative' }}>
      <ImageViewModal
        open={modalOpen}
        images={hotelDetails?.additional?.photos}
        handleClose={() => setModalOpen(false)}
        activeImage={activeImage}
        setActiveImage={setActiveImage}
      />
      <div className={classes.imageList}>
        {hotelDetails?.additional?.photos?.map((hotelImage, idx) => (
          <div
            className={classes.image}
            onClick={() => {
              setModalOpen(true);
              setActiveImage(idx);
            }}>
            <ImgWithFallback
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                borderRadius: 8,
              }}
              src={hotelImage}
              key={idx}
              alt={`${hotelDetails?.name}-${idx}`}
            />
          </div>
        ))}
        {!isTab && <div className={classes.gradientOverlay} />}
        <div className={classes.btnContainer}>
          <BasicButton
            onClick={() => setModalOpen(true)}
            sx={{
              fontSize: 14,
              padding: '4px 8px',
            }}>
            See all photos
          </BasicButton>
        </div>
      </div>
    </div>
  );
}

export default ImageContainer;
