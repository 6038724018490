/**
 * Slice to control the view state of the app for modals/new headings etc
 */

import { createSlice } from '@reduxjs/toolkit';
import { getUser } from './Auth';
import graphqlClient from '../../graphql';
import QUERY from '../../graphql/queries';
import { EVENTS, phTrackEvent } from '../../analytics';
import { updateTourInLocalStorage } from '../../utils/tourUtils';

const initialState = {
  userId: null,
  plannerScrolled: false,
  poiDetails: {
    isInView: false,
    payload: {},
  },
  bookings: {
    isInView: false,
    payload: {},
  },
  isExploreOpen: false,
  activeExploreTab: 0,
  contribute: {
    action: -1,
    contributeStatus: false,
  },
  updateSubscription: {
    action: -1,
    subscriptionId: null,
  },
  highlightedSection: null,
  highlightedHeading: null,
  highlightedToDo: null,
  activeMapPin: null,
  commandBar: {
    activeLocationId: null,
    activeSectionId: null,
    accommodationDroppableSectionId: null,
    openAccommodationSearchbar: false,
    openTransportationBlade: false,
    transportationBladeType: 'flight',
    isRecommendationsOpen: true,
    isAiMenuOpen: false,
  },
  bookingCancelOpen: false,
  exploreCitySearch: '',
  showConfetti: false,
  tour: JSON.parse(localStorage.getItem('tour')) || {
    variant: 'default',
    isOpen: false,
    currentStep: 0,
    currentStepName: '',
    inProgress: false,
    tourName: '',
    completedTours: [],
    skippedStepCount: 0,
    lastStepSetAt: null,
  },
};

const ViewSlice = createSlice({
  name: 'VIEW',
  initialState,
  reducers: {
    setPlannerScrolled: (state, { payload }) => {
      state.plannerScrolled = payload;
    },
    setPoiView: (
      state,
      { payload: { isInView = false, poi = {}, cardIndex } }
    ) => {
      state.poiDetails = {
        isInView,
        payload: isInView ? poi : {},
        cardIndex: isInView ? cardIndex : null,
      };
    },
    setBookingsView: (
      state,
      { payload: { isInView = false, bookingsProps = {} } }
    ) => {
      state.bookings.isInView = isInView;
      state.bookings.payload = bookingsProps;
    },
    setExploreView: (state, { payload: isInView }) => {
      state.isExploreOpen = isInView;
    },
    setActiveExploreTab: (state, { payload: activeExploreTab }) => {
      state.activeExploreTab = activeExploreTab;
    },
    setHighlightedSection: (state, { payload: { section = null } }) => {
      state.highlightedSection = section;
    },
    setHighlightedHeading: (state, { payload: { section = null } }) => {
      state.highlightedHeading = section;
    },
    setHighlightedToDo: (state, { payload: { todo = null } }) => {
      state.highlightedToDo = todo;
    },
    setActiveMapPin: (state, { payload: { mapPin = null } }) => {
      state.activeMapPin = mapPin;
    },
    setCommandBar: (state, { payload }) => {
      state.commandBar = {
        ...state.commandBar,
        ...payload,
      };
    },
    setContribute: (state, { payload: { cancelled = false, ...payload } }) => {
      /* TRACKING START */
      if (payload?.action === 0 && cancelled) {
        phTrackEvent({
          event: EVENTS.PWYW.PAYMENT_SCREEN_BACK_BTN_CLICK,
          meta: {
            source: payload.source,
          },
          trackOnIntercom: true,
        });
      } else if (payload?.source && payload?.action === 0) {
        phTrackEvent({
          event: EVENTS.PWYW.CONTRIBUTE_OPEN,
          meta: {
            source: payload.source,
          },
          trackOnIntercom: true,
        });
      } else if (
        payload?.action === 1 &&
        payload?.price &&
        payload?.paymentMode
      ) {
        phTrackEvent({
          event: EVENTS.PWYW.PAYMENT_START,
          meta: {
            price: payload.price,
            paymentMode: payload.paymentMode,
            source: payload.source,
          },
          trackOnIntercom: true,
        });
      } else if (payload?.action === 2) {
        phTrackEvent({
          event: EVENTS.PWYW.VIEW_THANK_YOU_MODAL,
          trackOnIntercom: true,
        });
      }
      /* TRACKING END */

      state.contribute = {
        source: state.contribute?.source,
        ...payload,
      };

      if (payload?.action === -1) {
        graphqlClient.mutate({
          mutation: QUERY.UPDATE_USER,
          variables: {
            id: state.userId,
            disableTravelStats: true,
          },
        });
      } else if (payload?.action === 2) {
        state.contribute.contributeStatus = 'SUCCESS';
      }
    },
    setUpdateSubscription: (state, { payload }) => {
      state.updateSubscription = payload;
    },
    setExploreCitySearch: (state, { payload }) => {
      state.exploreCitySearch = payload;
    },
    setTourOpen: (state, { payload }) => {
      state.tour.isOpen = payload;
      state.tour.inProgress = true;
      updateTourInLocalStorage(state.tour);
    },
    setTourInProgress: (state, { payload }) => {
      state.tour.inProgress = payload;
      updateTourInLocalStorage(state.tour);
    },
    setTourName: (state, { payload }) => {
      state.tour.tourName = payload;
      updateTourInLocalStorage(state.tour);
    },
    setTourCurrentStep: (state, { payload }) => {
      state.tour.currentStep = payload;
      updateTourInLocalStorage(state.tour);
    },
    setTourCurrentStepName: (state, { payload }) => {
      state.tour.currentStepName = payload;
      updateTourInLocalStorage(state.tour);
    },
    setShowConfetti: (state, { payload }) => {
      state.showConfetti = payload;
    },
    setTourSkippedStepCount: (state, { payload }) => {
      state.tour.skippedStepCount = payload;
      updateTourInLocalStorage(state.tour);
    },
    setVariant: (state, { payload }) => {
      state.tour.variant = payload;
      updateTourInLocalStorage(state.tour);
    },
    setLastStepSetAt: (state, { payload }) => {
      state.tour.lastStepSetAt = payload;
      updateTourInLocalStorage(state.tour);
    },
    completeTour: (state) => {
      state.tour.isOpen = false;
      state.tour.currentStep = 0;
      state.tour.inProgress = false;
      state.tour.completedTours.push(
        `${state.tour.tourName}-${state.tour.variant}`
      );
      state.showConfetti = true;
      updateTourInLocalStorage(state.tour);
    },
  },
  extraReducers: {
    [getUser.fulfilled]: (state, { payload }) => {
      state.userId = payload?.id;
    },
  },
});

export const ViewActions = ViewSlice.actions;
export const ViewReducer = ViewSlice.reducer;
