import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Collapse, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ChevronRightRounded,
  PlaceRounded,
  OutlinedFlagRounded,
  PeopleRounded,
} from '@mui/icons-material';
import actions from '../../../redux/actions';
import { getTravelStats } from '../../../redux/slices/Trips';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: () => ({
    backgroundColor: palette.primary.extraLight,

    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    marginTop: 8,
    borderRadius: 4,
    [breakpoints.down('sm')]: {
      padding: '12px 16px',
    },
  }),
  coloredText: {
    color: palette.primary.main,
  },
  stat: {
    backgroundColor: '#FFF',
    borderRadius: 36,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: 12,
    margin: 8,
    width: 108,
    height: 90,
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 12,
    },
  },
  collapsedContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: 12,
    [breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  contributeBtnContainer: {
    display: 'flex',
    marginLeft: 'auto',
    marginTop: 12,
    [breakpoints.down('sm')]: {
      width: '100%',
      '& > .MuiButton-root': {
        width: '100%',
      },
    },
  },
}));

function TravelStats() {
  const [collapsed, setCollapsed] = useState(true);
  const classes = useStyles(collapsed);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const userId = useSelector((state) => state.Auth.firebaseUser.uid);
  const travelStats = useSelector((state) => state.Trips.travelStats);

  const shouldShowTravelStats = useSelector(
    (state) => !state.Auth.userData?.user?.disableTravelStats
  );
  const hasPaid = useSelector(
    (state) => state.View.contribute?.contributeStatus === 'SUCCESS'
  );

  useEffect(() => {
    if (shouldShowTravelStats) {
      dispatch(
        getTravelStats({
          variables: {
            userId,
          },
        })
      );
    }
  }, []);

  const handleContributeClick = () => {
    dispatch(actions.View.setContribute({ action: 0, source: 'travel-stats' }));
  };

  const stats = [
    {
      copy: 'Trips planned',
      count: travelStats?.tripsCount || 0,
      icon: PlaceRounded,
    },
    {
      copy: 'Activities added',
      count: travelStats?.activityCount || 0,
      icon: OutlinedFlagRounded,
    },
    {
      copy: 'Friends invited',
      count: travelStats?.friendsInvited || 0,
      icon: PeopleRounded,
    },
  ];

  if (hasPaid || !travelStats || !travelStats?.shouldShowTravelStats)
    return null;

  return (
    <div className={classes.root}>
      <div
        onClick={() => setCollapsed(!collapsed)}
        style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h5Sub"
          sx={{
            flexGrow: 1,
            fontWeight: 600,
            color: 'text.secondary',
          }}>
          Hey traveler!{' '}
          {collapsed ? (
            <>
              {' '}
              Your <span className={classes.coloredText}>latest stats</span> are
              in!{' '}
            </>
          ) : (
            <>
              {' '}
              Here are your{' '}
              <span className={classes.coloredText}>Pilot travel stats:</span>
            </>
          )}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}>
          {isMobile ? null : collapsed ? 'Reveal my stats' : 'Hide my stats'}
          {collapsed ? <ArrowDropDownRounded /> : <ArrowDropUpRounded />}
        </Typography>
      </div>
      <Collapse in={!collapsed}>
        <div className={classes.collapsedContainer}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 16,
              flexWrap: isMobile ? 'wrap' : 'nowrap',
            }}>
            {stats?.map((stat) => (
              <div className={classes.stat}>
                <Typography variant="h5" color="text.secondary">
                  {stat.copy}
                </Typography>
                <Typography
                  variant="h4Sub"
                  color="primary.main"
                  sx={{ alignItems: 'center', display: 'flex' }}>
                  <stat.icon sx={{ marginRight: 1 }} />
                  {stat.count}
                </Typography>
              </div>
            ))}
          </div>
          <div className={classes.rightContainer}>
            <Typography variant="h5" component="p">
              You&apos;re why Pilot exists. You wanted to create incredible
              travel experiences, free from paywalls and corporate influence.
              With a contribution, we can continue building new tools for your
              adventures.
            </Typography>
            <div className={classes.contributeBtnContainer}>
              <Button
                onClick={handleContributeClick}
                sx={{ padding: '6px 8px', justifyContent: 'space-between' }}>
                Yes, I want to help
                <ChevronRightRounded />
              </Button>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default TravelStats;
