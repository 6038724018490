import { useState, useEffect, useRef } from 'react';
import { Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ItineraryHeader from './ItineraryHeader';
import ItineraryBody from './ItineraryBody';
import { useItineraryDnd } from '../ItineraryDndProvider';
import { deleteLocation } from '../../../redux/slices/Location';
import { DateProvider } from '../../molecules/DatePickers/DateUtils';
import actions from '../../../redux/actions';

const useStyles = makeStyles(({ palette }) => ({
  container: {
    marginTop: '24px',
  },
  isDragging: {
    backgroundColor: '#F4F4F4',
    padding: '1% 1% 2% 0%',
    borderRadius: 8,
    border: `2px solid ${palette.primary.light}`,
    marginLeft: 'calc(3.6rem + 15px)',
  },
  highlight: {
    padding: '1% 1% 2% 0%',
    borderRadius: 8,
    border: `2px solid ${palette.primary.light}`,
    marginLeft: 'calc(3.6rem + 15px)',
  },
}));

function ItinerarySection({
  tripId,
  locationId,
  activeLocation,
  setActiveLocation,
  handleAddLocation,
  dragHandleProps,
  isDragging,
  citySuggestion,
}) {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.Location.locations);
  const location = useSelector((state) => state.Location.locations[locationId]);
  const commandBarState = useSelector((state) => state.View.commandBar);
  const currentTrip = useSelector((state) => state.Trips.trips[tripId]);
  const locationIds = currentTrip?.items
    ?.filter((item) => item.location !== null)
    ?.map((item) => item.location);
  const highlightedSection = useSelector(
    (state) => state.View.highlightedSection
  );

  const [isCollapsed, setCollapsed] = useState(true);
  const classes = useStyles();
  const locationRef = useRef(null);
  const { isNewLocation, setNewLocation } = useItineraryDnd();
  const isSectionHighlight = highlightedSection === locationId;
  const handleDelete = async (id) => {
    try {
      dispatch(
        deleteLocation({
          variables: {
            id,
            tripId,
          },
          mapPin: location?.mapPin,
        })
      );

      const activeLocationId = commandBarState?.activeLocationId;
      if (activeLocationId === locationId) {
        const currentLocationIndex = locationIds.findIndex(
          (locId) => locId === id
        );
        let nextLocationId =
          locationIds.length >= currentLocationIndex + 1
            ? locationIds[currentLocationIndex + 1]
            : null;

        // if the selected location is the last location in the trip then set the previous location as active
        if (!nextLocationId) {
          nextLocationId =
            locationIds.length > 0
              ? locationIds[currentLocationIndex - 1]
              : null;
        }

        const nextSectionId = locations[nextLocationId]?.thingsToDo?.[0];
        dispatch(
          actions.View.setCommandBar({
            activeLocationId: nextLocationId,
            activeSectionId: nextSectionId || null,
          })
        );
      }
      return true;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (isNewLocation === locationId) {
      locationRef?.current?.focus();
      setNewLocation(null);
    }
  }, [isNewLocation]);
  return (
    <div
      className={`${classes.container} ${
        isSectionHighlight ? classes.highlight : null
      } ${isDragging ? classes.isDragging : null}`}
      id={`scrollable-container-${locationId}`}
      // {...dragHandleProps}
    >
      <DateProvider location={location}>
        <ItineraryHeader
          isCollapsed={isCollapsed}
          setCollapsed={setCollapsed}
          location={location}
          handleDelete={handleDelete}
          handleAdd={handleAddLocation}
          dragHandleProps={dragHandleProps}
          locationRef={locationRef}
          isDragging={isDragging}
          isSectionHighlight={isSectionHighlight}
          citySuggestion={citySuggestion}
        />
        <Collapse in={isCollapsed}>
          {location?.name && location?.name !== '' ? (
            <ItineraryBody
              tripId={tripId}
              location={location}
              activeLocation={activeLocation}
              setActiveLocation={setActiveLocation}
              isDragging={isDragging}
              isSectionHighlight={isSectionHighlight}
            />
          ) : null}
        </Collapse>
      </DateProvider>
    </div>
  );
}

export default ItinerarySection;
