import { makeStyles } from '@mui/styles';
import { OutlinedFlagRounded } from '@mui/icons-material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Box, Grid, Tooltip, Typography, Fade } from '@mui/material';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import { DraggableIconPlaceHolder } from '../../atoms/Icon';

const useStyles = makeStyles({
  flagContainer: {
    height: 22,
    width: 22,
    color: '#4e4e4e',
  },
});

function ActivityButton({
  commonStyles,
  isClicked,
  handleAddActivity,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();

  return (
    <Grid item sx={{ mr: '-10px' }}>
      <Droppable
        droppableId="droppable-activity"
        type="ACTIVITY"
        isDropDisabled>
        {(droppableProvided, droppableSnapshot) => (
          <Tooltip
            title={
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                  Activity
                </Typography>
                {isKeyboardShortcutEnabled && (
                  <Box sx={commonStyles.shortcutKey}>A</Box>
                )}
              </Box>
            }
            enterDelay={500}
            TransitionComponent={Fade}
            placement="top"
            arrow
            componentsProps={commonStyles.componentsProps}>
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              <Draggable
                type="ACTIVITY"
                key="activity"
                draggableId="commandBar-activity"
                index={0}
                id={0}
                disableInteractiveElementBlocking>
                {(provided, snapshot) => (
                  <PortalAwareDraggableItem snapshot={snapshot}>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <Box
                        sx={
                          snapshot.isDragging
                            ? commonStyles.draggableExpandedState
                            : isClicked
                            ? commonStyles.draggableIconClickStyle
                            : commonStyles.hoverStyle
                        }
                        onClick={handleAddActivity}>
                        <OutlinedFlagRounded
                          className={classes.flagContainer}
                        />
                      </Box>
                    </div>
                  </PortalAwareDraggableItem>
                )}
              </Draggable>
              {droppableProvided.placeholder &&
                droppableSnapshot.isUsingPlaceholder && (
                  <DraggableIconPlaceHolder />
                )}
            </div>
          </Tooltip>
        )}
      </Droppable>
    </Grid>
  );
}
export default ActivityButton;
