import { useRef, useContext, useState, useEffect, useMemo } from 'react';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import ConfettiExplosion from 'react-confetti-explosion';
import {
  CircularProgress,
  Grid,
  Typography,
  Divider,
  Box,
  Snackbar,
  SnackbarContent,
  useMediaQuery,
} from '@mui/material';
import {
  AirplanemodeActive,
  CameraAlt as CameraAltIcon,
} from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
  useMouseSensor,
  useTouchSensor,
} from 'react-beautiful-dnd';
import Tour from 'reactour';
import { firebaseAuth } from '../../provider/AuthProvider';
import Notes from './Notes';
import {
  LightGreyButton,
  SecondaryOutlinedButton,
} from '../atoms/Button/index';
import AddModuleButton from '../molecules/AddModuleButton/index';
import TransportBlock from '../molecules/TransportBlock';
import TripNavbar from '../organisms/Navbar/TripNavbar';
import ItinerarySection from '../organisms/createTrip/ItinerarySection';
import MessengerBlade from '../organisms/MessengerBlade';
import Map from '../organisms/Map';
import { MapProvider } from '../organisms/MapUtils';
import {
  ItineraryDndProvider,
  useItineraryDnd,
} from '../organisms/ItineraryDndProvider';
import Transportation from '../organisms/Transportation';
import { ImageSelectorModal } from '../molecules/Modal';
import FilesTab from '../organisms/FilesTab';
import AppLayout from '../templates/AppLayout';
import LoadingLayout from '../templates/LoadingLayout';
import CityPage from '../organisms/Recommendations/CityPage';
import { handleTripCoverImage } from '../../utils';
import { getCompleteTrip, updateTrip } from '../../redux/slices/Trips';
import { createLocation } from '../../redux/slices/Location';
import {
  handleDndItems,
  handleDndSections,
  handleDndActivities,
  handleDndHotels,
} from '../../redux/slices/sharedThunks';

import CitiesList from '../../assets/citiesList';
// import Recommendations from '../organisms/Recommendations/Recommendations';
import classList from '../classList';
import ExportPDFButton from '../atoms/Button/ExportPDFButton';
import TravelRestrictions from '../organisms/TravelRestrictions';
import ImportFiles from '../organisms/ImportFiles';
import AutoPilot from '../organisms/AutoPilot';
import { Events, trackEvents } from '../../intercom';
import BookingsModal from '../molecules/Modal/BookingsModal';
import ExploreV1 from '../organisms/Recommendations/ExploreV1';
import TimezoneFixPopup from '../molecules/GlobalMessagePopup/TimezoneFixPopup';
import TripDateSelector from '../molecules/TripDateSelectorExternal';
import { useSounds, SOUNDS } from '../../sounds';
import { EVENTS, phTrackEvent } from '../../analytics';
import { BookingsActions } from '../../redux/slices/Bookings';
import CommandBar from '../organisms/CommandBar';
import FLAGS from '../../featureFlags';
import BookingView from '../organisms/Stays/V2/BookingView';
import ExportPDFModal from '../molecules/Modal/ExportPDFModal';
import getCfConnector from '../cfConnector';
import config from '../config';
import {
  onboardingTourNames,
  flowVariants,
  getSteps,
  stepName,
  steps,
  tourName as tourNames,
} from '../../assets/onboarding/steps';
import GuideStep from '../atoms/GuideStep';
import actions from '../../redux/actions';
import { checkIfTourIsCompleted } from '../../utils/tourUtils';
import { useTour } from '../molecules/Tour/useTour';

const useStyles = makeStyles((theme) => ({
  container: (tripNavHeight) => ({
    height: `calc(100vh - ${tripNavHeight}px)`,
    overflow: 'hidden',
  }),
  plannerContainer: (tripNavHeight) => ({
    overflow: 'auto',
    height: `calc(100vh - ${tripNavHeight}px)`,
    paddingBottom: `${tripNavHeight + 60}px`,
    backgroundColor: '#FFF',
    flexDirection: 'column',

    // hard coded from design for responsiveness, don't change
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '46.8%',
      maxWidth: 470,
    },
    [theme.breakpoints.up('md')]: {
      width: '46.8%',
      maxWidth: 767,
    },
  }),
  exploreContainer: {
    // hard coded from design for responsiveness, don't change
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '46.8%',
      maxWidth: 473,
    },
    [theme.breakpoints.up('md')]: {
      width: '66.1%',
      maxWidth: 677,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 767,
    },
  },
  mapContainer: () => ({
    display: 'flex',
    flex: 1,
    height: `calc(100vh - 55px)`,
    // hard coded from design for responsiveness, don't change
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '0%',
    },
  }),
  coverImage: {
    height: 200,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px',
    margin: '24px 0px 8px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px 24px 0',
    },
  },
  emptyCoverImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    width: '100%',
    backgroundColor: '#F4F4F4',
    borderRadius: '8px',
    margin: '24px 0 0 0',
    '&:hover': {
      cursor: 'pointer',
      '& .MuiTypography-root': {
        color: theme.palette.text.secondary,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px 24px 0',
    },
  },
  spacing: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
  },
  exploreSpacing: {
    padding: '0px calc(9.6% - 1rem)',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  secondarySpacing: {
    marginLeft: 'calc(9.6% - 2.4rem - 12px)',
    marginRight: 'calc(9.6% - 1rem)',
  },
  instructionsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0% 9.6% 0% calc(9.6% + 1.2rem + 6px)',
    [theme.breakpoints.down('sm')]: {
      padding: '0% 9.6% 0% 9.6%',
    },
  },
  instruction: {
    marginTop: '4vw',
    color: '#8A8A8A',
  },
  locationDroppable: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
  },
  item: {
    backgroundColor: 'transparent',
  },
  itemDragging: {
    backgroundColor: '#FFF',
    border: '2px solid #DEDDDD',
    borderRadius: 4,
  },
  spacingTripTitle: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
    marginTop: '8px',
    marginBottom: '8px',
  },
  iconButtonPropsRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '9px 8px',
  },
  dateStringPropsRoot: {
    marginLeft: '8px',
    marginRight: '0em',
  },
  divider: {
    padding: '0% 0 0% calc(9.6% + 1.2rem + 6px)',
    '& .MuiDivider-middle': {
      marginLeft: '0em',
      marginRight: '0em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0% 0 0% 9.6%',
    },
  },
  droppableSpacing: {
    marginBottom: '5%',
  },
  innerTabsContainer: {
    padding: '0% calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    marginTop: 140,
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
      marginTop: 18,
    },
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  buttonWrapper1: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down(1600)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  buttonLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    [theme.breakpoints.down(1650)]: {
      justifyContent: 'space-between',
      marginTop: 8,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      marginTop: 8,
    },
  },

  buttonsRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    [theme.breakpoints.down(1600)]: {
      // justifyContent: 'space-between',
      marginTop: 8,
    },
    [theme.breakpoints.down('sm')]: {
      // justifyContent: 'space-between',
      marginTop: 8,
    },
  },
}));

function Itinerary() {
  const [openMessenger, setOpenMessenger] = useState(false);
  const [shouldDateUpdate, setShouldDateUpdate] = useState(true);
  const dispatch = useDispatch();
  const [pdfLoading, setPdfLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const plannerScrolled = useSelector((state) => state.View.plannerScrolled);
  const tripsObj = useSelector((state) => state.Trips);
  const tripNavHeight = plannerScrolled ? 64 : 90;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  /* FEATURE FLAGS START */
  const showImportBookings = false;
  const isCommandBarEnabled = useFeatureFlagEnabled(FLAGS.UNIVERSAL_ADD_BAR);
  /* FEATURE FLAGS END */

  const { playSound } = useSounds();
  // redux state
  const { slug, tab } = useParams();
  const trip = useSelector((state) => state.Trips.trips[slug]);
  const tripItems = useSelector((state) => state.Trips.trips[slug]?.items);
  const tripSections = useSelector((state) => state.Section?.sections) || {};
  const bookingDataObj = useSelector((state) => state.Bookings[slug]);

  const showPoiDetailsPage = useSelector(
    (state) => state.View.poiDetails.isInView
  );
  const {
    inProgress: tourInProgress,
    isOpen: tourOpen,
    tourName: activeTourName,
  } = useSelector((state) => state.View.tour);
  const isOnboardingEnabled = useFeatureFlagEnabled(FLAGS.TRIP_ONBOARDING);
  const createTripFlowEnabled = useFeatureFlagEnabled(FLAGS.CREATE_TRIP_FLOW);

  const [searchParams] = useSearchParams();
  const { getTour } = useTour();

  window.showStep = (step, tourName, variant = 'default') => {
    if (tourName) {
      dispatch(actions.View.setTourName(tourName));
    }
    dispatch(actions.View.setVariant(variant));
    dispatch(actions.View.setTourCurrentStep(step));
    dispatch(
      actions.View.setTourCurrentStepName(getTour().steps[step]?.stepName)
    );

    getTour().openTour();
  };
  const isFirstTrip = Object.keys(tripsObj.trips || {}).length === 1;

  useEffect(() => {
    if (trip?.status !== 'SUCCESS') {
      return;
    }
    dispatch(
      actions.View.setVariant(
        isCommandBarEnabled ? flowVariants.commandBar : flowVariants.default
      )
    );
    if (
      !isFirstTrip &&
      !checkIfTourIsCompleted(
        [...Object.values(onboardingTourNames), tourNames.commandBarFlowWeb],
        flowVariants.commandBar
      ) &&
      isCommandBarEnabled &&
      !isMobile &&
      !getTour().tourInProgress
    ) {
      getTour().startTour(tourNames.commandBarFlowWeb);
      phTrackEvent({
        event: EVENTS.ONBOARDING.COMMAND_BAR_OB.SESSION_START,
      });
      return;
    }
    if (
      checkIfTourIsCompleted(Object.values(onboardingTourNames)) ||
      checkIfTourIsCompleted(
        Object.values(onboardingTourNames),
        flowVariants.commandBar
      ) ||
      !isFirstTrip ||
      !createTripFlowEnabled
    ) {
      return;
    }

    if (
      searchParams.get('o') === 'quickstart' &&
      isOnboardingEnabled &&
      !tourInProgress &&
      tripItems?.length > 0 &&
      trip?.status === 'SUCCESS' &&
      !isMobile &&
      Object.keys(tripSections)?.length > 0
    ) {
      const tourName = isMobile ? 'quickStartFlowMobile' : 'quickStartFlowWeb';
      if (!checkIfTourIsCompleted(tourName)) {
        getTour().startTour(tourName);
        phTrackEvent({ event: EVENTS.ONBOARDING.PLAN_OB_QS.SESSION_START });
      }
    }
    if (
      trip?.status === 'SUCCESS' &&
      searchParams.get('o') === 'create-a-trip' &&
      isOnboardingEnabled &&
      !tourInProgress
    ) {
      const tourName = isMobile ? 'emptyTripFlowMobile' : 'emptyTripFlowWeb';
      const blankTripFlowName = isMobile
        ? 'blankTripFlowMobile'
        : 'blankTripFlowWeb';
      const tourSteps = getSteps(tourName, {
        filterWithFeatureFlags: true,
        variant: isCommandBarEnabled
          ? flowVariants.commandBar
          : flowVariants.default,
      });
      if (
        !checkIfTourIsCompleted(tourName) &&
        !checkIfTourIsCompleted(blankTripFlowName)
      ) {
        const tripLocations = tripItems?.filter((item) => item.location);
        const tripHasTitle = trip.title;

        if (tripLocations?.length === 1 && tripHasTitle) {
          dispatch(actions.View.setTourCurrentStep(2));
          dispatch(actions.View.setTourSkippedStepCount(2));
          dispatch(actions.View.setTourCurrentStepName(tourSteps[2].stepName));
        } else {
          dispatch(actions.View.setTourCurrentStep(tripHasTitle ? 2 : 0));
          dispatch(
            actions.View.setTourCurrentStepName(
              tourSteps[tripHasTitle ? 1 : 0].stepName
            )
          );
          dispatch(actions.View.setTourSkippedStepCount(tripHasTitle ? 1 : 0));
        }
        dispatch(
          actions.View.setTourName(
            tripLocations?.length === 1 ? tourName : blankTripFlowName
          )
        );
        dispatch(actions.View.setTourInProgress(true));
        setTimeout(() => {
          dispatch(actions.View.setTourOpen(true));
          phTrackEvent({
            event: EVENTS.ONBOARDING.PLAN_OB_SCRATCH.SESSION_START,
          });
        }, 1000);
      }
    }
    if (
      searchParams.get('o') === 'sample-trip' &&
      isOnboardingEnabled &&
      !tourInProgress &&
      !isMobile
    ) {
      const tourName = isMobile ? 'sampleTripFlowMobile' : 'sampleTripFlowWeb';
      if (!checkIfTourIsCompleted(tourName)) {
        setTimeout(() => {
          getTour().startTour(tourName);
          phTrackEvent({
            event: EVENTS.ONBOARDING.PLAN_OB_ST.SESSION_START,
          });
        }, 1000);
      }
    }
  }, [tripItems, tripSections, trip, isOnboardingEnabled]);

  const tmpAudioDivRef = useRef();
  useEffect(() => {
    // TODO: REMOVE THIS IN BY JUNE 20 2024
    if (new URLSearchParams(location?.search).get('addToTrip') === '1') {
      navigate(`/join/${slug}`);
    } else if (
      !trip ||
      trip?.status === 'IDLE' ||
      new URLSearchParams(location?.search).get('forceRefresh') === 'true'
    )
      dispatch(getCompleteTrip({ tripId: slug })).then(() => {
        playSound(SOUNDS.tripWelcomeTone);
      });
  }, []);

  useEffect(() => {
    // disable scroll on body
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    // under explore tab, date should be updated for the 1st time only.
    if (trip && shouldDateUpdate) {
      setShouldDateUpdate(false);
      if (!bookingDataObj) {
        dispatch(
          BookingsActions.initializeTripState({
            tripId: slug,
            startDate: trip?.startDate,
            endDate: trip?.endDate,
          })
        );
      } else if (
        bookingDataObj?.startDate !== trip?.startDate ||
        bookingDataObj?.endDate !== trip?.endDate
      ) {
        dispatch(
          BookingsActions.setSearchDate({
            tripId: slug,
            date: {
              from: trip?.startDate && new Date(trip?.startDate),
              to: trip?.endDate && new Date(trip?.endDate),
            },
          })
        );
      }
    }
  }, [trip]);

  // trigger to update data in the components.
  const { user } = useContext(firebaseAuth);

  // to add an activity to the last column in a location. Used for pins.
  const [triggerAddActivity, setTriggerAddActivity] = useState(null);
  const [updateItineraryState, setUpdateItineraryState] = useState(false);
  // state that opens up a map popup if set with the suggestion from google-places-api.
  const [openMapPopup, setOpenMapPopup] = useState(null);
  const [changeCoverModalOpen, setChangeCoverModalOpen] = useState(false);
  const [errorSnackbarOpened, setErrorSnackbarOpened] = useState(false);
  const [isExportPDFModalOpen, setExportPDFModalOpen] = useState(false);
  const [popupAccessAlert, setPopupAccessAlert] = useState(false);

  const classes = useStyles(tripNavHeight);

  const [gettingCity, setGettingCity] = useState(-1);
  const citySuggestionRef = useRef({
    suggestion: null,
  });
  const toggleExportPDFModal = () => {
    if (activeTourName === 'quickStartFlowWeb' && tourOpen) {
      getTour().closeTour();
      return setExportPDFModalOpen((val) => !val);
    }
    if (isExportPDFModalOpen && tourInProgress) {
      getTour().complete();
    }
    return setExportPDFModalOpen((val) => !val);
  };

  // function to render a hidden iframe and render the pdf on the print window.
  const exportOptions = useSelector((state) => state.Trips.exportPdf.options);
  const handleExportPdf = async () => {
    const type =
      exportOptions.format === 'img' ? 'image/png' : 'application/pdf';
    setPdfLoading(true);
    toggleExportPDFModal();
    try {
      const connector = await getCfConnector();
      const res = await connector.post(
        config.exportTripAPI,
        {
          tripId: slug,
          includeActivityNotes: exportOptions.includeNotes,
          includeImages: exportOptions.includeImages,
          exportMode: exportOptions.format,
        },
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: type,
          },
        }
      );
      const blob = new Blob([res.data], { type });
      const blobUrl = URL.createObjectURL(blob);
      setPdfLoading(false);
      if (!window.open(blobUrl, '_blank')) {
        return setPopupAccessAlert(true);
      }
      trackEvents(Events.TripExported);
    } catch (error) {
      setPdfLoading(false);
    }
    return null;
  };

  function CoverImage() {
    const [filledCoverImageHovered, setFilledCoverImageHovered] =
      useState(false);
    if (!['', 'empty'].includes(trip?.coverImage))
      return (
        <div
          id="trip-cover-image"
          className={`${classes.coverImage} ${classList.tripCoverImage}`}
          onMouseEnter={() => setFilledCoverImageHovered(true)}
          onMouseLeave={() => setFilledCoverImageHovered(false)}>
          <img
            style={{ objectFit: 'cover', height: 200, width: '100%' }}
            src={trip?.coverImage}
            alt={trip?.title}
          />
          {filledCoverImageHovered && (
            <LightGreyButton
              startIcon={<CameraAltIcon />}
              style={{ position: 'absolute', right: '12px', bottom: '8px' }}
              onClick={() => {
                setChangeCoverModalOpen(true);
                setFilledCoverImageHovered(false);
              }}>
              Change cover photo
            </LightGreyButton>
          )}
        </div>
      );
    return (
      <div
        className={`${classes.emptyCoverImage} ${classList.emptyTripCoverImage}`}
        onClick={() => setChangeCoverModalOpen(true)}>
        <Typography variant="h5" color="text.light">
          + Add a trip photo
        </Typography>
      </div>
    );
  }

  const getRandomCity = () => {
    const randIdx = Math.floor(Math.random() * CitiesList.length);
    citySuggestionRef.current.suggestion = CitiesList[randIdx];
  };

  const handleAddLocation = async (index, setNewLocation) => {
    if (index === 0) {
      getTour()
        .onActiveStep(stepName.ENTER_DESTINATION)
        .closeTour()
        .goToNextStep();
    }

    setGettingCity(index + 1);
    if (trip?.items.some((item) => item.location)) {
      getRandomCity();
    }

    try {
      const res = await dispatch(
        createLocation({
          variables: {
            name: '',
            tripID: slug,
            index: index + 1,
          },
        })
      );
      setNewLocation(res.payload.createLocation.id);
      setUpdateItineraryState(true);
    } catch (e) {
      return e;
    }
    setGettingCity(-1);
    return true;
  };

  const showBookingConfirmation = useMemo(() => {
    return Boolean(
      trip?.activeBookingId &&
        trip?.bookings.some((item) => item?.id === trip?.activeBookingId)
    );
  }, [trip?.activeBookingId, trip?.bookings]);

  if (!trip || trip.status !== 'SUCCESS') {
    return <LoadingLayout />;
  }
  if (trip.error) {
    return (
      <Typography>{`Error Occured! Contact Admin. ${trip.error}`}</Typography>
    );
  }
  const tripOwner = trip?.owner || [];
  const tripSharedUsers = trip?.sharedUsers || [];
  const tripUsers = [tripOwner, ...tripSharedUsers];
  const tripStartDate = trip.startDate;
  const tripEndDate = trip.endDate;

  function TripDateSelectorMain() {
    return (
      <TripDateSelector
        trip={trip}
        tripStartDate={tripStartDate}
        tripEndDate={tripEndDate}
      />
    );
  }

  function CustomTravelRestrictionButton({ onClick = () => {} }) {
    return (
      <SecondaryOutlinedButton
        startIcon={
          <AirplanemodeActive
            sx={{ color: '#1D1D1D', height: 14, width: 14 }}
          />
        }
        onClick={onClick}
        id="travel-restriction-button"
        style={{ width: '100%' }}>
        Visas & Requirements
      </SecondaryOutlinedButton>
    );
  }

  return (
    <AppLayout>
      <Helmet>
        <title>{trip?.title ? `${trip.title} | Pilot` : ' '}</title>
      </Helmet>
      <div ref={tmpAudioDivRef} />
      <ImageSelectorModal
        handleUpload={(e) => handleTripCoverImage(e, slug)}
        changeCoverModalOpen={changeCoverModalOpen}
        setChangeCoverModalOpen={setChangeCoverModalOpen}
        handleUpdateCoverImage={(image) => {
          dispatch(
            updateTrip({
              variables: {
                id: slug,
                coverImage: image,
              },
            })
          ).then(() => {
            phTrackEvent({
              event: EVENTS.PLAN_HEADER.COVER_PHOTO_UPDATE,
            });
            playSound(SOUNDS.photoUpload);
          });
        }}
        showInitialImages
        setErrorSnackbarOpened={setErrorSnackbarOpened}
        trip={trip}
      />

      <BookingsModal />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={popupAccessAlert}
        autoHideDuration={5000}
        onClose={() => setPopupAccessAlert(false)}
        key="auto-flight-search-success-popup">
        <SnackbarContent
          sx={{
            backgroundColor: 'success.main',
            justifyContent: 'center',
          }}
          message="Please enable popups to download your trip."
        />
      </Snackbar>
      <ExportPDFModal
        onExport={handleExportPdf}
        open={isExportPDFModalOpen}
        onClose={toggleExportPDFModal}
      />

      <ItineraryDndProvider
        trip={trip}
        updateItineraryState={updateItineraryState}
        setUpdateItineraryState={setUpdateItineraryState}
        triggerAddActivity={triggerAddActivity}
        setTriggerAddActivity={setTriggerAddActivity}>
        <TripNavbar
          user={user}
          tripData={trip}
          people={tripUsers}
          setOpenMessenger={setOpenMessenger}
          activePath={tab}
          refetch={() =>
            dispatch(getCompleteTrip({ tripId: slug, setLoader: false }))
          }
          TripDateSelector={TripDateSelectorMain}
        />
        {tab === 'planner' ? (
          <Grid container className={classes.container}>
            <MapProvider>
              <Grid item className={classes.plannerContainer} id="planner">
                {showBookingConfirmation ? <BookingView /> : null}
                <div
                  style={
                    showBookingConfirmation
                      ? {
                          maxHeight: 0,
                          overflow: 'hidden',
                        }
                      : {}
                  }>
                  <Box>
                    <Grid item xs={12} className={classes.spacing}>
                      <CoverImage />
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      flex-direction="column"
                      className={classes.spacingTripTitle}>
                      <Box className={classes.buttonWrapper}>
                        <div className={classes.buttonLeft}>
                          <TravelRestrictions
                            CustomButton={CustomTravelRestrictionButton}
                          />
                        </div>
                        <div className={classes.buttonsRight}>
                          <ExportPDFButton
                            style={{ flex: 1 }}
                            handleExport={toggleExportPDFModal}
                            pdfLoading={pdfLoading}
                          />
                          {showImportBookings && <ImportFiles />}
                        </div>
                      </Box>
                    </Grid>
                    <div className={classes.secondarySpacing}>
                      <Grid className={classes.divider}>
                        <Divider variant="middle" />
                      </Grid>
                      <TripItems
                        slug={slug}
                        tripName={trip?.title || ''}
                        handleAddLocation={handleAddLocation}
                        openMapPopup={openMapPopup}
                        setOpenMapPopup={setOpenMapPopup}
                        citySuggestion={citySuggestionRef}
                        loadingIdx={gettingCity}
                        isCommandBarEnabled={isCommandBarEnabled}
                      />
                    </div>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                className={`${classes.mapContainer} ${classList.mapContainer}`}>
                <Map
                  mapPins={[...(trip?.mapPins || [])]}
                  tripId={slug}
                  triggerAddActivity={triggerAddActivity}
                  setTriggerAddActivity={setTriggerAddActivity}
                  openMapPopup={openMapPopup}
                  setOpenMapPopup={setOpenMapPopup}
                  setUpdateItineraryState={setUpdateItineraryState}
                  isCommandBarEnabled={isCommandBarEnabled}
                />
                {trip?.displayTimezoneFixPopup && (
                  <div
                    style={{
                      position: 'fixed',
                      left: '48.5%',
                      bottom: '20px',
                    }}>
                    <TimezoneFixPopup tripId={slug} />
                  </div>
                )}
              </Grid>
            </MapProvider>
          </Grid>
        ) : tab === 'notes' ? (
          <Notes tripID={slug} tripNotes={trip?.notes} />
        ) : tab === 'files' ? (
          <MapProvider>
            <FilesTab trip={trip} />
          </MapProvider>
        ) : (
          <Grid container className={classes.container}>
            <MapProvider>
              <Grid
                item
                className={`${classes.plannerContainer} ${classes.exploreContainer}`}
                sx={{
                  paddingBottom: showPoiDetailsPage ? '0px !important' : 'auto',
                }}
                id="explore">
                {showPoiDetailsPage ? <CityPage /> : null}
                <div
                  style={
                    showPoiDetailsPage
                      ? {
                          maxHeight: 0,
                          overflow: 'hidden',
                        }
                      : {}
                  }>
                  <div className={classes.exploreSpacing}>
                    <ExploreV1 />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                id="map-container"
                className={`${classes.mapContainer} ${classList.mapContainer}`}>
                <Map
                  mapPins={[...(trip?.mapPins || [])]}
                  tripId={slug}
                  triggerAddActivity={triggerAddActivity}
                  setTriggerAddActivity={setTriggerAddActivity}
                  openMapPopup={openMapPopup}
                  setOpenMapPopup={setOpenMapPopup}
                  setUpdateItineraryState={setUpdateItineraryState}
                  isCommandBarEnabled={isCommandBarEnabled}
                />
                {trip?.displayTimezoneFixPopup && (
                  <div
                    style={{
                      position: 'fixed',
                      left: '48.5%',
                      bottom: '20px',
                    }}>
                    <TimezoneFixPopup tripId={slug} />
                  </div>
                )}
              </Grid>
            </MapProvider>
          </Grid>
        )}
      </ItineraryDndProvider>
      <MessengerBlade
        setOpenMessenger={setOpenMessenger}
        openMessenger={openMessenger}
        chatId={slug}
        tripName={trip?.title}
        tripUsers={tripUsers}
      />
      <AutoPilot />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        open={errorSnackbarOpened}
        onClose={() => setErrorSnackbarOpened(false)}
        message="Error uploading. Cover photos must be under 10MB."
        ContentProps={{
          sx: {
            backgroundColor: '#F45E62',
            color: '#FFFFFF',
            fontWeight: 500,
            fontSize: 16,
            boxShadow: 'none',
            textAlign: 'center',
            justifyContent: 'center',
          },
        }}
      />
    </AppLayout>
  );
}

function TripItems({
  tripName,
  slug,
  handleAddLocation,
  triggerAddActivity,
  setTriggerAddActivity,
  citySuggestion,
  loadingIdx,
  isCommandBarEnabled,
}) {
  const dispatch = useDispatch();
  const reduxItems = useSelector((state) => state.Trips.trips[slug].items);
  const locations = useSelector((state) => state.Location.locations);
  const tourStep = useSelector((state) => state.View.tour.currentStep);
  const tourStepName = useSelector((state) => state.View.tour.currentStepName);
  const isTourOpen = useSelector((state) => state.View.tour.isOpen);
  const tourName = useSelector((state) => state.View.tour.tourName);
  const tourVariant = useSelector((state) => state.View.tour.variant);
  const lastStepSetAt = useSelector((state) => state.View.tour.lastStepSetAt);
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const { setNewSection } = useItineraryDnd();

  const showConfetti = useSelector((state) => state.View.showConfetti);
  const tourSteps =
    getSteps(tourName, {
      filterWithFeatureFlags: true,
      variant: tourVariant,
    }) || steps[tourName];
  const commandBarState = useSelector((state) => state.View.commandBar);
  const [openFlightBlade, setOpenFlightBlade] = useState(false);
  const { setNewLocation } = useItineraryDnd();
  const sensor = [useMouseSensor, useTouchSensor];
  const classes = useStyles();
  const ref = useRef({
    transportationType: 'flight',
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const openFlightBladeUsingCommandBar =
    commandBarState?.openTransportationBlade;

  useEffect(() => {
    if (openFlightBladeUsingCommandBar) {
      setOpenFlightBlade(commandBarState?.transportationBladeIndex || 0);
      ref.current.transportationType = commandBarState?.transportationBladeType;

      // again setting the command bar state to false
      dispatch(actions.View.setCommandBar({ openTransportationBlade: false }));
    }
  }, [openFlightBladeUsingCommandBar]);

  useEffect(() => {
    if (!openFlightBlade) {
      dispatch(actions.View.setCommandBar({ openTransportationBlade: false }));
    }
  }, [openFlightBlade]);
  const fstLocation = reduxItems.findIndex((item) => item.location !== null);
  const itemsWithLoading = JSON.parse(JSON.stringify(reduxItems));
  if (loadingIdx >= 0) itemsWithLoading.splice(loadingIdx, 0, 'loading');

  const removedLocation = Object.values(locations || {}).find(
    (loc) => loc?.tempRemove === true
  );

  const removedLocationId = removedLocation?.id ?? null;
  let locationToRemoveIndex = null;

  itemsWithLoading.forEach((item, index) => {
    if (item.location === removedLocationId) locationToRemoveIndex = index;
  });

  if (locationToRemoveIndex || locationToRemoveIndex === 0) {
    itemsWithLoading.splice(locationToRemoveIndex, 1);
  }

  const handleAddDestination = async (activeLocationIndex) => {
    try {
      const res = await dispatch(
        createLocation({
          variables: {
            name: '',
            tripID: slug,
            index: activeLocationIndex,
          },
        })
      );

      dispatch(
        actions.View.setCommandBar({
          activeLocationId: res.payload.createLocation.id,
          activeSectionId: null,
        })
      );
    } catch (e) {
      return e;
    }
  };

  const handleAddSection = (activeLocationId, activeSectionIndex) => {
    const newColumnId = `local-section-${Date.now()}`;
    const newSection = {
      id: newColumnId,
      name: '',
      todos: [],
    };

    dispatch(
      actions.Section.addLocalSection({
        localId: newColumnId,
        section: newSection,
      })
    );

    dispatch(
      actions.Location.addLocalSectionToLocation({
        locationId: activeLocationId,
        sectionId: newColumnId,
        index: activeSectionIndex - 1,
      })
    );
    setNewSection(newColumnId);
  };

  const handleAddActivity = (activeSectionId, activeTodoIndex) => {
    const newTodoId = `local-todo-${Date.now()}`;
    const newActivity = {
      id: newTodoId,
      title: '',
    };

    dispatch(
      actions.Activity.createLocalActivity({
        localId: newTodoId,
        todo: newActivity,
      })
    );
    dispatch(
      actions.Section.addLocalActivityToSection({
        sectionId: activeSectionId,
        activityId: newTodoId,
        index: activeTodoIndex - 1,
      })
    );

    // sets this to ensure the last added input is highlighted.
    dispatch(
      actions.Activity.setNewActivity({
        activityId: newTodoId,
      })
    );
  };

  const handleAddAccommodation = (droppableSectionId) => {
    dispatch(
      actions.View.setCommandBar({
        accommodationDroppableSectionId: droppableSectionId,
      })
    );
  };

  const handleAddTransportation = (index) => {
    dispatch(
      actions.View.setCommandBar({
        openTransportationBlade: true,
        transportationBladeIndex: index > 1 ? index - 1 : 0,
      })
    );
  };

  const handleDragEnd = ({ source, destination, draggableId, type }) => {
    if (!destination) return;
    if (source.droppableId === 'droppable-destination') {
      handleAddDestination(destination.index);
    } else if (source.droppableId === 'droppable-heading') {
      handleAddSection(destination.droppableId, destination.index);
    } else if (source.droppableId === 'droppable-activity') {
      handleAddActivity(destination.droppableId, destination.index);
    } else if (source.droppableId === 'droppable-accommodation') {
      handleAddAccommodation(destination.droppableId);
    } else if (source.droppableId === 'droppable-transportation') {
      handleAddTransportation(destination.index);
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let dndAction;
    switch (type) {
      case 'ITEM':
        dndAction = handleDndItems;
        break;
      case 'SECTION':
        dndAction = handleDndSections;
        break;
      case 'ACCOMMODATION':
        dndAction = handleDndHotels;
        break;
      case 'ACTIVITY':
        dndAction = handleDndActivities;
        break;
      default:
        return;
    }
    dispatch(dndAction({ source, destination, draggableId }));
  };
  const { getTour } = useTour();

  useEffect(() => {
    if (tourStepName === stepName.FIRST_ACTIVITY_HOVER_INFO && isTourOpen) {
      const container = document.getElementsByClassName(
        'activity-inlineblade-container'
      )[0];
      container?.addEventListener('wheel', (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
      });
    }
    if (tourStepName === stepName.MAP_SEARCH_BAR) {
      globalThis?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [tourStepName, isTourOpen]);

  useEffect(() => {
    if (showConfetti) {
      setTriggerConfetti(true);
      dispatch(actions.View.setShowConfetti(false));
    }
  }, [showConfetti]);
  return (
    <DragDropContext
      sensors={sensor}
      enableDefaultSensors={false}
      onDragEnd={handleDragEnd}>
      <div className={classes.droppableSpacing}>
        {tourName && tourSteps?.length > 0 && (
          <Tour
            disableFocusLock={[
              stepName.FIRST_ACITVITY_OPEN_DETAILS,
              stepName.MAP_SEARCH_BAR,
              stepName.ADD_TODO,
              stepName.SET_HEADING,
              stepName.ENTER_DESTINATION,
              stepName.TRIP_NAME_INTRO_STEP,
              stepName.COMMAND_BAR_INTRO_STEP,
            ].includes(tourStepName)}
            scrollDuration={110}
            scrollOffset={0}
            rounded={5}
            getCurrentStep={(step) => {
              if (!tourSteps[step]) {
                // return dispatch(actions.View.setTourCurrentStep(2));
                return getTour().complete();
              }
              return dispatch(
                actions.View.setTourCurrentStepName(tourSteps[step].stepName)
              );
            }}
            goToStep={tourStep}
            startAt={tourStep}
            highlightedMaskClassName="tour-mask"
            onRequestClose={() => {
              if (Date.now() - lastStepSetAt < 500) {
                return;
              }
              if (tourStep === tourSteps.length - 1) {
                return getTour().complete();
              }
              if (!getTour().steps[tourStep]) {
                // return dispatch(actions.View.setTourCurrentStep(2));
                return getTour().complete();
              }
              if (getTour().steps[tourStep]?.waitForInteraction) {
                getTour().closeTour();
              }
              if (!getTour().steps[tourStep]?.disableIncrement) {
                getTour().goToNextStep();
              }
              return null;
            }}
            className="tour-mask"
            CustomHelper={(props) => {
              const {
                heading = '',
                description = '',
                footer = '',
                position = 'top',
                arrowStyles = {},
                phEvent = null,
                containerStyles = {},
              } = props.content;
              if (phEvent) {
                phTrackEvent({
                  event: phEvent,
                });
              }
              return (
                <GuideStep
                  heading={heading}
                  description={description}
                  footer={footer}
                  position={position}
                  currentStep={
                    Number.isNaN(getTour().getCount().completed)
                      ? props.current + 1
                      : getTour().getCount().completed
                  }
                  totalSteps={
                    Number.isNaN(getTour().getCount().total)
                      ? props.totalSteps
                      : getTour().getCount().total
                  }
                  arrowStyles={arrowStyles}
                  containerStyles={containerStyles}
                />
              );
            }}
            steps={tourSteps}
            isOpen={isTourOpen}
          />
        )}
        {triggerConfetti && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: '50%',
              width: '100%',
              height: '100%',
              zIndex: 1000,
            }}>
            <ConfettiExplosion
              onComplete={() => setTriggerConfetti(false)}
              particleCount={350}
              duration={2500}
              force={0.8}
              width={2000}
            />
          </Box>
        )}
        {!isMobile && isCommandBarEnabled && <CommandBar tripId={slug} />}
        <Droppable
          droppableId={slug}
          type="ITEM"
          direction="vertical"
          ignoreContainerClipping>
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={classes.locationDroppable}
              {...provided.droppableProps}>
              {itemsWithLoading?.length ? (
                itemsWithLoading.map((item, index) => {
                  if (item === 'loading') {
                    return (
                      <div
                        style={{
                          paddingTop: '5px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CircularProgress
                          size={20}
                          style={{ marginRight: 4 }}
                        />
                      </div>
                    );
                  }
                  return (
                    <Draggable
                      type="ITEM"
                      key={item.location || item.transportation}
                      draggableId={item.location || item.transportation}
                      id={index}
                      index={index}
                      disableInteractiveElementBlocking>
                      {(draggableProvided, draggableSnapshot) => {
                        return (
                          <div
                            ref={draggableProvided.innerRef}
                            className={classes.item}
                            {...draggableProvided.draggableProps}>
                            {item.location ? (
                              <ItinerarySection
                                tripId={slug}
                                locationId={item.location}
                                key={item.location}
                                handleAddLocation={() =>
                                  handleAddLocation(index, setNewLocation)
                                }
                                dragHandleProps={
                                  draggableProvided.dragHandleProps
                                }
                                triggerAddActivity={triggerAddActivity}
                                setTriggerAddActivity={setTriggerAddActivity}
                                isDragging={draggableSnapshot.isDragging}
                                citySuggestion={
                                  index === fstLocation ? citySuggestion : null
                                }
                              />
                            ) : (
                              <TransportBlock
                                tripId={slug}
                                update
                                transportId={item.transportation}
                                locationId={item.transportation}
                                items={reduxItems}
                                dragHandleProps={
                                  draggableProvided.dragHandleProps
                                }
                                handleAddTransportation={(type) => {
                                  ref.current.transportationType = type;
                                  setOpenFlightBlade(index);
                                }}
                                isDragging={draggableSnapshot.isDragging}
                              />
                            )}
                            {openFlightBlade === index ? (
                              <div style={{ margin: '4% 0' }}>
                                <Transportation
                                  open={openFlightBlade === index}
                                  setOpen={setOpenFlightBlade}
                                  newFlight
                                  items={reduxItems || []}
                                  tripId={slug}
                                  index={index}
                                  type={
                                    ref.current.transportationType ||
                                    reduxItems[index]?.type
                                  }
                                />
                              </div>
                            ) : null}

                            {!(
                              itemsWithLoading[index]?.transportation &&
                              itemsWithLoading[index + 1]?.transportation
                            ) &&
                              (draggableSnapshot.isDragging ? null : (
                                <AddModuleButton
                                  handleAddTransportation={(type) => {
                                    ref.current.transportationType = type;
                                    setOpenFlightBlade(index);

                                    // analytics
                                    const {
                                      FLIGHT_ADD,
                                      OTHER_TRANSPORT_ADD,
                                      BUS_TRAIN_ADD,
                                    } = EVENTS.PLAN_ADD;
                                    const transportEvent =
                                      type === 'flight'
                                        ? FLIGHT_ADD
                                        : type === 'bus'
                                        ? BUS_TRAIN_ADD
                                        : OTHER_TRANSPORT_ADD;

                                    phTrackEvent({
                                      event: transportEvent,
                                    });
                                  }}
                                  handleAddLocation={() => {
                                    handleAddLocation(index, setNewLocation);
                                    phTrackEvent({
                                      event: EVENTS.PLAN_ADD.LOCATION_START,
                                    });
                                  }}
                                  type={
                                    itemsWithLoading[index]?.transportation
                                      ? 'transportation'
                                      : 'location'
                                  }
                                  isCommandBarEnabled={isCommandBarEnabled}
                                />
                              ))}
                            {draggableProvided.placeholder}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })
              ) : (
                <>
                  {openFlightBlade === 0 ? (
                    <div style={{ margin: '4% 0' }}>
                      <Transportation
                        open={openFlightBlade === 0}
                        setOpen={setOpenFlightBlade}
                        newFlight
                        items={reduxItems || []}
                        tripId={slug}
                        index={0}
                        type={ref.current.transportationType}
                      />
                    </div>
                  ) : tripName !== '' ? (
                    <div className={classes.instructionsBox}>
                      <Typography className={classes.instruction}>
                        You&apos;re one step closer! Get started by adding a
                        flight or destination with the buttons below.
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.instructionsBox}>
                      <Typography className={classes.instruction}>
                        Time to Personalize: Enter your trip name and choose a
                        cover photo.
                      </Typography>
                    </div>
                  )}
                  {isMobile || !isCommandBarEnabled ? (
                    <AddModuleButton
                      needsInteraction={openFlightBlade === 0}
                      handleAddTransportation={(type) => {
                        ref.current.transportationType = type;
                        setOpenFlightBlade(0);
                      }}
                      handleAddLocation={() =>
                        handleAddLocation(0, setNewLocation)
                      }
                    />
                  ) : null}
                </>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
}

export default Itinerary;
