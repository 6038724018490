import { gql } from '@apollo/client';
import graphqlClient from './index';
import QUERY from './queries';

export const createUserDetails = async (user, setErrors) => {
  try {
    const { uid, displayName } = user;
    let { email } = user;
    const firstName = displayName.split(' ')[0];
    const lastName = displayName.split(' ').splice(1).join(' ');
    if (email === null) email = '';
    await graphqlClient.mutate({
      mutation: QUERY.CREATE_USER,
      variables: {
        id: uid,
        firstName,
        lastName,
        email,
        welcomeScreen: false,
      },
    });
  } catch (err) {
    setErrors(err.message);
  }
};

// An http post request - graphql equivalent to update user details
export const updateUserDetails = async (user) => {
  try {
    const { uid, displayName, email } = user;
    const firstName = displayName.split(' ')[0];
    const lastName = displayName.split(' ').splice(1).join(' ');

    return await graphqlClient.mutate({
      mutation: QUERY.UPDATE_USER,
      variables: {
        id: uid,
        firstName,
        lastName,
        email,
        welcomeScreen: false,
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateTripUsers = async (trip) => {
  try {
    const { id, newUser } = trip;

    return await graphqlClient.mutate({
      mutation: QUERY.UPDATE_TRIP_NAME,
      variables: {
        id,
        newUser,
      },
    });
  } catch (err) {
    return err;
  }
};

// An http post request - graphql equivalent for get trip
export const getTrips = async (userId) => {
  try {
    return await graphqlClient.query({
      query: QUERY.GET_ALL_TRIPS,
      variables: {
        userId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUserHandle = async (handle) => {
  try {
    return await graphqlClient.query({
      query: QUERY.GET_USER_HANDLE,
      variables: {
        handleName: handle,
      },
      // requests for userids from a handle will always be from the server
      fetchPolicy: 'network-only',
    });
  } catch (err) {
    return null;
  }
};

export const getUserName = async (id) => {
  try {
    const resp = await graphqlClient.query({
      query: gql`
        query getUser($id: ID!) {
          getUser(id: $id) {
            firstName
          }
        }
      `,
      variables: { id },
    });
    return resp.data.getUser;
  } catch (err) {
    return err;
  }
};

// An http post request - graphql equivalent for get shared trip
export const getSharedTrips = async (userId) => {
  try {
    return await graphqlClient.query({
      query: QUERY.GET_SHARED_TRIPS,
      variables: {
        userId,
      },
    });
  } catch (err) {
    return err;
  }
};

// An http get request for wishlist
export const getWishlist = async (id) => {
  try {
    const { data } = await graphqlClient.query({
      query: QUERY.GET_WISHLIST,
      variables: { id },
      fetchPolicy: 'network-only',
    });
    return data.getWishlist;
  } catch (err) {
    return err;
  }
};

// An http search request for people
export const searchPeople = async (searchQuery) => {
  try {
    const { data } = await graphqlClient.query({
      query: QUERY.SEARCH_QUERY,
      variables: { searchQuery },
      fetchPolicy: 'network-only',
    });
    return data.userSearch;
  } catch (err) {
    return err;
  }
};

export const updateCheckoutSessionStatus = async (id, status) => {
  try {
    return await graphqlClient.mutate({
      mutation: QUERY.UPDATE_CHECKOUT_SESSION_STATUS,
      variables: { id, status },
    });
  } catch (err) {
    return err;
  }
};

export const initiateUserSession = async (userId, sessionId) => {
  try {
    const { data } = await graphqlClient.mutate({
      mutation: QUERY.INITIATE_USER_SESSION,
      variables: { userId, sessionId },
      fetchPolicy: 'network-only',
    });
    return data?.initiateUserSession;
  } catch (err) {
    return err;
  }
};
