import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { AddRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ClickAwayListener, CircularProgress, Fade } from '@mui/material';
import TravelRestrictions from './TravelRestrictions';
import { AddConnectingTransportButton } from '../atoms/Button';
import TransportationBlade from './TransportationBlade';
import {
  createFlight,
  deleteFlight,
  createTransportation,
} from '../../redux/slices/Transportation';
import { updateFile } from '../../redux/slices/Files';
import classList from '../classList';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    marginLeft: 'calc(3.6rem - 44px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 19px - 28px)',
    },
  },
}));

function Transportation({
  open,
  setOpen,
  newFlight,
  tripId,
  defaultFlightProps = {},
  handleDeleteTransportation = () => {},
  id,
  index,
  type = 'flight', // Should be used to indicate transportation type. Options: flight, bus, train, other
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const divRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [connectingFlightLoading, setConnectingFlightLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const [clickedAway, setClickedAway] = useState(false);
  const [flightList, setFlightList] = useState(
    defaultFlightProps?.details || [
      {
        title: '',
        notes: '',
        link: '',
        cost: null,
        currency: null,
        toAirport: '',
        fromAirport: '',
        toDate: null,
        fromDate: null,
      },
    ]
  );
  const [newFlightIds, setNewFlightIds] = useState(['new-flight']);
  useEffect(() => {
    if (newFlight) {
      // Indicates a new Transportation Block
      divRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
      divRef.current?.focus();
    }
  }, []);

  const handleCreateTransportation = async () => {
    try {
      setLoading(true);

      // create flights for each stop created
      const flights = await Promise.all(
        flightList.map(async (flight) => {
          if (flight?.id?.startsWith('local-flight') || !flight?.id) {
            const newCreatedFlight = await dispatch(
              createFlight({
                variables: {
                  ...flight,
                },
                extra: {
                  type,
                },
              })
            );
            if (flight?.files) {
              flight?.files?.forEach((file) =>
                dispatch(
                  updateFile({
                    variables: {
                      id: file,
                      attachedToType: 'flight',
                      attachedToID: newCreatedFlight?.payload?.createFlight?.id,
                    },
                  })
                )
              );
            }
            return newCreatedFlight?.payload?.createFlight;
          }
          return flight;
        })
      );

      await new Promise((resolve, reject) => {
        dispatch(
          createTransportation({
            variables: {
              trip: tripId,
              type,
              details: flights.map((flight) => flight.id),
              index: index + 1,
            },
            extra: {
              attachedFlights: flights,
              index,
            },
          })
        )
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    } catch (err) {
      return err;
    }
    setLoading(false);
    return null;
  };

  const handleUpdateTransportationBlade = (data, flightIndex) => {
    try {
      setFlightList([
        ...flightList.slice(0, flightIndex),
        {
          ...flightList[flightIndex],
          ...data,
        },
        ...flightList.slice(flightIndex + 1),
      ]);
    } catch (err) {
      // Handle error
    }
  };

  const deleteConnectingFlight = async (flightIndex) => {
    const flightToBeDeleted = flightList[flightIndex];
    const updatedFlightList = [
      ...(flightList?.slice(0, flightIndex) || []),
      ...(flightList?.slice(flightIndex + 1) || []),
    ];

    if (updatedFlightList.length === 0) {
      handleDeleteTransportation();
      return;
    }
    if (!newFlight) {
      await dispatch(
        deleteFlight({
          variables: {
            id: flightToBeDeleted.id,
            transportId: id,
          },
        })
      );
    }
    setFlightList(updatedFlightList);
  };

  const addConnectingFlight = async (e) => {
    e.stopPropagation();
    setConnectingFlightLoading(true);
    try {
      let newFlightId = `local-flight-${Date.now()}`;
      if (!newFlight) {
        await dispatch(
          createFlight({
            variables: {
              transportId: id,
            },
            extra: {
              type,
            },
          })
        ).then((res) => {
          newFlightId = res.payload.createFlight.id;
        });
      }
      const newFlightList = [
        ...flightList,
        {
          id: newFlightId,
          title: '',
          notes: '',
          link: '',
          cost: null,
          currency: null,
          toAirport: '',
          fromAirport: '',
          toDate: null,
          fromDate: null,
          automated: true,
        },
      ];
      setFlightList(newFlightList);
      setNewFlightIds([...newFlightIds, newFlightId]);
    } catch (err) {
      return err;
    }
    setConnectingFlightLoading(false);
    return null;
  };

  const handleClickOutside = async (e) => {
    if (e?.target?.id === 'bookings-modal') return;
    setNewFlightIds([]);
    if (!clickedAway) {
      if (newFlight) {
        setClickedAway(true);
        await handleCreateTransportation();
      }
      setTransition(true);
      setTimeout(() => {
        setTransition(false);
        setOpen(false);
      }, 300);
    }
  };

  return (
    <div
      ref={divRef}
      // style={{
      //   marginLeft: 'calc(3.6rem + 19px - 28px)',
      // }}

      className={`${classList.item} ${classes.root}`}>
      <ClickAwayListener onClickAway={handleClickOutside}>
        <Fade in={open && !transition} timeout={300}>
          <div>
            {type === 'flight' && (
              <div style={{ display: 'flex', marginLeft: 28 }}>
                <TravelRestrictions flightList={flightList} />
                <div style={{ flexGrow: 1 }} onClick={handleClickOutside} />
              </div>
            )}
            {flightList?.map((flight, idx) => (
              <TransportationBlade
                key={flight.id}
                id={flight.id}
                transportId={id}
                open={open}
                setOpen={setOpen}
                newFlight={newFlight}
                defaultFlightProps={flight}
                type={type}
                index={idx}
                handleUpdateTransportationBlade={
                  handleUpdateTransportationBlade
                }
                handleDeleteConnectingFlight={() => deleteConnectingFlight(idx)}
                loading={idx === 0 ? loading : false}
                canShowDeleteIcon={flightList?.length > 1}
                handleClickOutside={handleClickOutside}
                newFlightIds={newFlightIds}
                setNewFlightIds={setNewFlightIds}
              />
            ))}
            {/* TODO: weigh out the cons vs pros of using mousedown vs onClick  */}
            <AddConnectingTransportButton
              onMouseDown={addConnectingFlight}
              style={{ marginLeft: 28, width: 'calc(100% - 28px)' }}
              disableFocusRipple
              disableRipple
              disabled={connectingFlightLoading}
              endIcon={
                connectingFlightLoading ? (
                  <CircularProgress style={{ height: 16, width: 16 }} />
                ) : null
              }
              startIcon={<AddRounded style={{ color: '#8A8A8A' }} />}>
              Add connecting {type.toLowerCase()}
            </AddConnectingTransportButton>
          </div>
        </Fade>
      </ClickAwayListener>
    </div>
  );
}

export default Transportation;
