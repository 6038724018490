export function getTourFromLocalStorage() {
  const tour = localStorage.getItem('tour');
  return tour
    ? JSON.parse(tour)
    : {
        completedTours: [],
      };
}
export function checkIfTourIsCompleted(tourName, variant = 'default') {
  const tour = getTourFromLocalStorage();
  if (!tour || !tour?.completedTours) {
    return false;
  }
  if (typeof tourName === 'string') {
    const key = `${tourName}-${variant}`;
    return tour?.completedTours.includes(key);
  }
  if (Array.isArray(tourName)) {
    return tourName.some((_tour) =>
      tour?.completedTours?.includes(`${_tour}-${variant}`)
    );
  }
  return false;
}

export function updateTourInLocalStorage(tour) {
  localStorage.setItem('tour', JSON.stringify(tour));
}
