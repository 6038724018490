import {
  CancelOutlined,
  ChevronLeftRounded,
  ChevronRightRounded,
  EventNoteOutlined,
  GroupOutlined,
  HotelOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  getCurrencyFormatter,
  getSortedAmenities,
} from '../HotelDetails/utils';
import { ChatBubble } from '../../../atoms/Icon';
import { openIntercom } from '../../../../intercom';
import actions from '../../../../redux/actions';
import { useMapUtils } from '../../MapUtils';
import { getNumberOfNights } from '../bookingsUtils';
import { EVENTS, phTrackEvent } from '../../../../analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '130px calc(9.6% - 1rem) 0% calc(9.6% - 1rem)',
    [theme.breakpoints.up('sm')]: {
      padding: '0% calc(9.6% - 1rem) 0% calc(9.6% + 1.2rem + 6px)',
    },
    marginTop: 32,
  },

  backContainer: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    cursor: 'pointer',
  },

  hotelInfoContainer: {
    marginTop: 12,
    borderRadius: 8,
    overflow: 'hidden',
    border: '1px solid #D9D9D9',
    width: '100%',
    alignSelf: 'center',
  },
  spacer: {
    padding: '28px 24px',
  },
  restrictWidth: {
    maxWidth: 520,
    margin: '0 auto',
  },
  roomContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
}));

function HotelInfoHeader({ booking }) {
  const classes = useStyles();

  const getformattedDateRangeString = () => {
    const checkInDate = booking.checkInDate
      ? new Date(booking.checkInDate)
      : null;
    const checkOutDate = booking.checkOutDate
      ? new Date(booking.checkOutDate)
      : null;

    if (checkInDate && checkOutDate) {
      return format(checkInDate, 'MMM') !== format(checkOutDate, 'MMM')
        ? `${format(checkInDate, 'MMM do')} - ${format(checkOutDate, 'MMM do')}`
        : `${format(checkInDate, 'MMM do')} - ${format(checkOutDate, 'do')}`;
    }
    return '';
  };

  const getFormattedDateString = (date) => {
    return date ? format(new Date(date), 'MMM dd, yyyy') : '';
  };

  return (
    <div className={classes.hotelInfoContainer}>
      <div
        style={{
          height: 210,
          width: '100%',
          position: 'relative',
        }}>
        <img
          height="100%"
          width="100%"
          style={{ objectFit: 'cover' }}
          alt="hotel"
          src={booking?.image}
          onError={(e) => {
            e.target.src =
              'https://images.unsplash.com/photo-1455587734955-081b22074882?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
          }}
        />
        {booking?.status === 'CANCELED' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <Typography
              variant="h2"
              sx={{
                display: 'flex',
                color: 'white',
                fontSize: 18,
              }}>
              Your reservation is canceled!
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.spacer}>
        <div className={classes.restrictWidth}>
          <Typography variant="h2">{booking?.name}</Typography>
          <Box display="flex" alignItems="center" mt={2}>
            <Box display="flex" alignItems="center">
              <EventNoteOutlined sx={{ mr: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {getformattedDateRangeString()}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" ml={3}>
              <GroupOutlined sx={{ mr: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {booking?.guestsCount} guests
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" ml={3}>
              <HotelOutlined sx={{ mr: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {booking?.roomsCount} room{booking?.roomsCount > 1 && 's'}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={3}>
            <Box display="flex" sx={{ flexDirection: 'column' }}>
              <Typography variant="h5">Check-in</Typography>
              <Typography variant="h2" mt={0.5}>
                {getFormattedDateString(booking.checkInDate)}
              </Typography>
              {/* <Typography variant="h4" mt={0.5}>
                {booking.checkInTime}
              </Typography> */}
            </Box>
            <Box display="flex" ml={6} sx={{ flexDirection: 'column' }}>
              <Typography variant="h5">Check-out</Typography>
              <Typography variant="h2" mt={0.5}>
                {getFormattedDateString(booking.checkOutDate)}
              </Typography>
              {/* <Typography variant="h4" mt={0.5}>
                {booking.checkOutTime}
              </Typography> */}
            </Box>
          </Box>

          <Typography variant="h3" mt={2}>
            Confirmation code:{' '}
            <span style={{ fontWeight: 400 }}>{booking?.confirmationCode}</span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

function RoomsInfo({ booking }) {
  const classes = useStyles();
  return (
    <div className={`${classes.hotelInfoContainer} ${classes.spacer}`}>
      <div className={classes.restrictWidth}>
        <Typography variant="h2">Rooms & amenities</Typography>
        <div style={{ marginTop: 20 }} />
        <div className={classes.roomsContainer}>
          {booking.rooms.map((room) => (
            <div key={room.name} className={classes.roomContainer}>
              <img
                height={102}
                width={102}
                style={{ objectFit: 'cover', borderRadius: 8 }}
                alt="room"
                src={room.image}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3">{room.name}</Typography>
                <div style={{ marginTop: 12 }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 4,
                    flexWrap: 'wrap',
                  }}>
                  {getSortedAmenities(room?.amenities)
                    ?.slice(0, 3)
                    ?.map((property) => (
                      <Typography
                        variant="h4"
                        color="#4E4E4E"
                        alignItems="center"
                        display="flex"
                        noWrap
                        mr={3}>
                        <property.icon sx={{ mr: 1, color: '#222' }} />
                        {property?.text}
                      </Typography>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function CxlPolicyInfo({ booking }) {
  const classes = useStyles();
  return (
    <div className={`${classes.spacer} ${classes.hotelInfoContainer}`}>
      <div className={classes.restrictWidth}>
        <Typography variant="h2" mt={1}>
          Important information
        </Typography>
        <Typography variant="h4" mt={2}>
          Early check-in and late check-out are subject to availability and may
          incur additional charges. Please contact the front desk in advance if
          you require these services.
        </Typography>
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        <Typography variant="h2" mt={2}>
          Cancellation policy
        </Typography>
        <div style={{ marginTop: 16 }} />
        <Typography variant="h4">
          {booking.cxlPolicy.isRefundable
            ? `Free cancellation until ${format(
                new Date(booking.cxlPolicy.refundableUntil),
                `MMM dd, yyyy hh:mm a (z)`
              )}`
            : 'This booking is not refundable'}
        </Typography>
        <Typography variant="h4" mt={1}>
          {booking?.cxlPolicy?.remarks}
        </Typography>
        <Typography variant="h4" mt={1} mb={1}>
          No-shows will be charged the full amount of the stay.
        </Typography>
      </div>
    </div>
  );
}

function PaymentInfo({ booking }) {
  const classes = useStyles();

  const navigate = useNavigate();
  const { slug: tripId } = useParams();

  const [isExpanded, setIsExpanded] = useState(false);
  const CollapseIcon = isExpanded
    ? KeyboardArrowUpRounded
    : KeyboardArrowDownRounded;

  const handleCancelBooking = () => {
    phTrackEvent({
      event: EVENTS.CHECKOUT_MANAGE_BOOKING.CANCEL_BOOKING_START,
      meta: {
        bookingId: booking?.id,
      },
    });
    navigate(`/trips/${tripId}/booking/${booking.id}/cancel`);
  };

  const numberOfNights = getNumberOfNights({
    from: booking?.checkInDate,
    to: booking?.checkOutDate,
  });

  return (
    <div className={`${classes.spacer} ${classes.hotelInfoContainer}`}>
      <div className={classes.restrictWidth}>
        <Typography variant="h2">Payment information</Typography>
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Typography
            variant="h6"
            fontWeight="bold"
            display="flex"
            alignItems="center">
            Total
            <CollapseIcon
              onClick={() => {
                phTrackEvent({
                  event: isExpanded
                    ? EVENTS.CHECKOUT_MANAGE_BOOKING.TOTAL_PAYMENT_INFO_COLLAPSE
                    : EVENTS.CHECKOUT_MANAGE_BOOKING.TOTAL_PAYMENT_INFO_EXPAND,
                });
                setIsExpanded(!isExpanded);
              }}
            />
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            {getCurrencyFormatter(booking?.currency).format(
              ((booking?.price || 0) + (booking?.taxes || 0)).toFixed(2)
            )}
          </Typography>
        </Box>
        <Collapse in={isExpanded}>
          <>
            <Typography variant="caption" sx={{ color: 'text.light' }}>
              {booking?.rooms?.length} x {booking?.rooms[0]?.name}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h4">
                {getCurrencyFormatter(booking?.currency).format(
                  ((booking?.price || 0) / (numberOfNights || 1)).toFixed(2)
                )}{' '}
                x {`${numberOfNights} night${numberOfNights > 1 ? 's' : ''}`}
              </Typography>
              <Typography variant="h4">
                {getCurrencyFormatter(booking?.currency).format(
                  (booking?.price || 0).toFixed(2)
                )}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Typography variant="h4">Taxes</Typography>
              <Typography variant="h4">
                {getCurrencyFormatter(booking?.currency).format(
                  (booking?.taxes || 0).toFixed(2)
                )}
              </Typography>
            </Box>
          </>
        </Collapse>
        {booking?.status === 'CANCELED' ? null : (
          <Box
            onClick={handleCancelBooking}
            sx={{
              marginTop: 2,
              padding: '12px 0px 12px 4px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#F5F5F5',
              },
            }}>
            <CancelOutlined sx={{ fontSize: 20 }} />
            <Typography
              variant="h4"
              sx={{
                ml: 1,
                textDecoration: 'underline',
                fontSize: 18,
              }}>
              Cancel Booking
            </Typography>
            <ChevronRightRounded sx={{ ml: 'auto' }} />
          </Box>
        )}
      </div>
    </div>
  );
}

function SupportInfo() {
  const classes = useStyles();
  return (
    <div className={`${classes.spacer} ${classes.hotelInfoContainer}`}>
      <div className={classes.restrictWidth}>
        <Typography variant="h2">Contact Support</Typography>
        <Typography variant="h4" mt={2}>
          If you need assistance regarding your booking we are available 24/7
          from anywhere in the world
        </Typography>
        <Box
          onClick={() => {
            phTrackEvent({
              event: EVENTS.CHECKOUT_MANAGE_BOOKING.SUPPORT_START,
            });
            openIntercom('message');
          }}
          sx={{
            marginTop: 2,
            padding: '12px 0px 12px 4px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#F5F5F5',
            },
          }}>
          <ChatBubble />
          <Typography
            variant="h4"
            sx={{
              ml: 1,
              textDecoration: 'underline',
              fontSize: 18,
            }}>
            Send a support message
          </Typography>
          <ChevronRightRounded sx={{ ml: 'auto' }} />
        </Box>
      </div>
    </div>
  );
}

function BookingView() {
  const classes = useStyles();
  const { slug: tripId } = useParams();
  const dispatch = useDispatch();
  const trip = useSelector((state) => state.Trips.trips[tripId]);
  const selectedBooking = trip?.bookings?.find(
    (item) => item?.id === trip?.activeBookingId
  );
  const { focusPlace, currentMap } = useMapUtils();
  const bookingDetails = selectedBooking?.bookingDetails;
  const booking = {
    id: selectedBooking?.id,
    status: selectedBooking?.status,
    name: bookingDetails?.name,
    checkInDate: bookingDetails?.checkInDate,
    checkOutDate: bookingDetails?.checkOutDate,
    guestsCount: bookingDetails?.users?.length,
    roomsCount: bookingDetails?.rooms?.length,
    address: bookingDetails?.address,
    price: bookingDetails?.price?.total,
    taxes: bookingDetails?.price?.taxes,
    currency: bookingDetails?.price?.currency || 'USD',
    confirmationCode: selectedBooking?.id,
    image: bookingDetails?.image,
    rooms: bookingDetails?.rooms?.map((room) => ({
      name: room?.name,
      amenities: [...(room?.amenities || []), room?.basis],
      image: room?.images?.[0],
    })),
    cxlPolicy: bookingDetails?.cxlPolicy,
    coordinates: {
      latitude: bookingDetails?.latitude,
      longitude: bookingDetails?.longitude,
    },
  };

  useEffect(() => {
    let timer;
    try {
      if (
        booking?.coordinates?.latitude &&
        booking?.coordinates?.longitude &&
        selectedBooking &&
        currentMap
      ) {
        // delay to focus place after fitBounds is called.
        timer = setTimeout(() => {
          focusPlace(
            {
              latitude: parseFloat(booking?.coordinates?.latitude),
              longitude: parseFloat(booking?.coordinates?.longitude),
            },
            false
          );
        }, 1000);
      }
    } catch {
      // handle error
    }
    return () => clearTimeout(timer);
  }, [selectedBooking, currentMap]);

  return (
    <div className={classes.root}>
      <div
        className={classes.backContainer}
        onClick={() =>
          dispatch(
            actions.Trips.setActiveBookingId({ tripId, bookingId: null })
          )
        }>
        <ChevronLeftRounded sx={{ fontSize: 16 }} />
        <Typography variant="h4">Back to your Itinerary</Typography>
      </div>

      <HotelInfoHeader booking={booking} />
      <div style={{ marginTop: 16 }} />
      <RoomsInfo booking={booking} />
      <div style={{ marginTop: 16 }} />
      <CxlPolicyInfo booking={booking} />
      <div style={{ marginTop: 16 }} />
      <PaymentInfo booking={booking} />
      <div style={{ marginTop: 16 }} />
      <SupportInfo booking={booking} />
      <div style={{ paddingBottom: 32 }} />
    </div>
  );
}

export default BookingView;
