import {
  Box,
  Divider,
  List,
  ListItem,
  Popover,
  Typography,
  Switch,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { useSelector } from 'react-redux';
// import { firebaseAuth } from '../../../../provider/AuthProvider';
import { styled } from '@mui/material/styles';

const LabSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: 0,
    padding: 24,
    width: 318, // this is fixed in the designs, hence implemented a fixed width.
    '& .MuiListItem-root': {
      '&:hover': {
        backgroundColor: '#FFF',
      },
    },
  },
  avatar: {
    backgroundColor: palette.primary.main,
    width: 48,
    height: 48,
  },
  firstrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  labIcon: {
    height: 30,
    width: 30,
    backgroundColor: 'blue',
    '.parent:hover .child': {
      backgroundColor: 'blue',
    },
    webkitMask: 'url(/images/PilotLabsIcon.svg) no-repeat center',
    mask: 'url(/images/PilotLabsIcon.svg) no-repeat center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileLink: {
    textTransform: 'capitalize',
    marginLeft: 16,
  },
  listItemProfile: {
    paddingBottom: 16,
    borderBottom: '1px solid #D2D2D2',
    '&:hover': {
      cursor: 'pointer',
      '& $profileLink': {
        color: palette.primary.main,
        textDecoration: 'underline',
      },
    },
  },
  socialContainer: {
    marginTop: 18,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Labfeatures = [
  'Quick Start (generative AI trip builder)',
  'Discover (inspo for your next adventure)',
];

function LabPopover({
  anchorEl,
  setAnchorEl,
  handleLabStateChange,
  labStatus,
}) {
  const classes = useStyles();

  return (
    <Popover
      id="lab-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      backdropProps={{
        onclick: () => setAnchorEl(null),
      }}>
      <List className={classes.root}>
        <ListItem>
          <div className={classes.firstrow}>
            <div
              style={{
                display: 'flex',
              }}>
              <img src="/images/PilotLabsIcon.svg" alt="lab-icon" height={25} />
              <Typography
                style={{
                  marginLeft: '5px',
                }}
                variant="h4"
                color="black">
                Pilot Labs
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
              }}>
              <LabSwitch
                id="PilotLabButton"
                checked={labStatus}
                onChange={handleLabStateChange}
              />
            </div>
          </div>
        </ListItem>
        <Divider
          style={{
            marginBottom: '10px',
          }}
        />
        <ListItem>
          <Typography variant="body2" color="textSecondary">
            Enable access to Pilot&apos;s newest early stage features,
            including:
          </Typography>
        </ListItem>
        <Box className={classes.socialContainer}>
          <ul>
            {
              // eslint-disable-next-line react/no-array-index-key
              Labfeatures.map((feature, index) => (
                <li key={index}>
                  <Typography variant="body2" color="textSecondary">
                    {feature}
                  </Typography>
                </li>
              ))
            }
          </ul>
        </Box>
        <ListItem
          style={{
            marginTop: 15,
          }}>
          <Typography variant="body2" color="textSecondary">
            Note: Your feedback is welcome as all features are in active
            development.
          </Typography>
        </ListItem>
      </List>
    </Popover>
  );
}

export default LabPopover;
