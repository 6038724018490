import React from 'react';
import { Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import { AccessTimeRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { computeAmenitiesV2 } from '../utils';

function HotelPerksSection() {
  const { slug: hotelKey } = useParams();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { perksFeatures = [] } = computeAmenitiesV2(
    hotelDetails?.additional?.facilities
  );
  const noOfCols = isMobile ? 1 : 3;
  return (
    <div style={{ padding: isMobile ? '0px 12px' : 0 }}>
      <Typography variant="h2">...and the perks!</Typography>
      <Grid container mt={4} rowGap={3}>
        {perksFeatures?.map((text, idx) => {
          return (
            <Grid item xs={12 / noOfCols} key={idx}>
              <Typography
                variant="h4"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <AccessTimeRounded
                  sx={{
                    height: 20,
                    width: 20,
                    transform: 'rotate(-60deg)',
                    marginRight: 1,
                  }}
                />
                {text}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default HotelPerksSection;
