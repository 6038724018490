import { useState, useRef, useEffect, useContext } from 'react';
import {
  CircularProgress,
  Grid,
  Typography,
  InputBase,
  useTheme,
  useMediaQuery,
  Tooltip,
  Fade,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Favorite,
  OutlinedFlagRounded,
  DragIndicatorRounded,
  DeleteOutlineRounded,
  DescriptionOutlined,
  AddRounded,
  AttachMoney,
  Link,
} from '@mui/icons-material';
import { Draggable } from 'react-beautiful-dnd';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { gql } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import InlineBlade from '../../../molecules/InlineBlade';
import { firebaseAuth } from '../../../../provider/AuthProvider';
import client from '../../../../graphql/index';
import { useMapUtils } from '../../MapUtils';
import {
  createActivity as createReduxActivity,
  deleteActivity as deleteReduxTodo,
  updateActivity as updateReduxActivity,
  createNewActivityInLocation,
} from '../../../../redux/slices/Activity';
import { updateMapPinTitle } from '../../../../redux/slices/Map';
import actions from '../../../../redux/actions';
import { PlacesSearchBar } from '../../../molecules/SearchBar';
import classList from '../../../classList';
import { LocationPinIcon, PaperClip } from '../../../atoms/Icon';
import IconDatePicker from '../../../molecules/IconDatePicker';
import AvatarStack from '../../../molecules/AvatarStack';
import CustomLottiePlayer from '../../../molecules/CustomLottiePlayer';

import {
  parseISODate,
  updateSessionStorageForLastEditedSection,
} from '../../../../utils';
import FLAGS from '../../../../featureFlags';
import { switchLink, trackTpLink } from '../../../../tp-switcher';
import { useSounds, SOUNDS } from '../../../../sounds';
import { EVENTS, phTrackEvent } from '../../../../analytics';
import { OrangeOutlinedButton } from '../../../atoms/Button/index';
import useTour from '../../../molecules/Tour/useTour';
import { stepName } from '../../../../assets/onboarding/steps';

const useTodoItemStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #D9D9D9',
    borderRadius: 4,
    display: 'flex',
    padding: '3px 0.5%',
    margin: '8px 0% 0% 1%',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
  },
  isDragging: {
    border: `2px solid ${palette.primary.light}`,
    borderRadius: 4,
  },
  favorite: {
    fontSize: 16,
    color: '#E44F3C',
    fontWeight: 'bold',
    stroke: '#E44F3C',
    strokeWidth: '1.5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  favoriteInactive: {
    fontSize: 16,
    color: 'transparent',
    fontWeight: 'bold',
    stroke: '#A7A7A7',
    strokeWidth: '1.5px',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  iconEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  likesCount: {
    marginRight: 4,
    marginLeft: 4,
    minWidth: '9px',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIcon: ({
    isActivityHovered,
    isActivityFocused,
    isActivityHighlight,
  }) => ({
    color: isActivityFocused
      ? palette.primary.main
      : isActivityHovered || isActivityHighlight
      ? palette.primary.light
      : '#222',
  }),
  inputContainer: {
    display: 'flex',
    flex: 1,
    marginLeft: '8px',
  },
  actionIcon: {
    marginTop: 8,
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
      color: '#474747',
    },
  },
  actionIconDrag: {
    marginTop: 8,
    fontSize: '1.2rem',
    color: 'rgba(138, 138, 138, 1)',
    '&:hover': {
      color: '#474747',
    },
  },
  actionIconDragSpan: {
    marginLeft: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionContainer: {
    position: 'absolute',
    margin: 'auto',
    top: 12,
    left: 'calc(-3.6rem - 15px)',
    minWidth: 'calc(3.6rem + 15px)',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hovered: {
    border: `1px solid ${palette.primary.light}`,
  },
  focused: {
    border: `1px solid ${palette.primary.main}`,
  },
  spacingRight: {
    paddingRight: '0rem',
  },
  todoItem: {
    position: 'relative',
    marginLeft: 'calc(3.6rem - 15px)',
    // marginRight: 12,
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px)',
      marginRight: 12,
    },
  },
  newTodoItem: {
    marginLeft: 'calc(3.6rem - 15px)',
    [breakpoints.up('sm')]: {
      marginLeft: 'calc(3.6rem + 15px + 8px)',
    },
  },
  activityBladeIconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  activityBladeIcon: {
    fontSize: '16px',
    color: '#8A8A8A',
  },
  tooltip: {
    backgroundColor: '#fff',
    fontSize: 12,
    padding: '2px 4px',
    marginTop: '15px',
    color: '#8A8A8A',
    border: '1px solid #DAD9D9',
    top: '6px',
    maxWidth: (props) => props.maxWidth,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tooltipArrow: {
    '&:before': {
      border: '1px solid #DAD9D9',
    },
    color: '#fff',
  },
  extraTooltipNumber: {
    fontSize: '12px',
    color: '#8A8A8A',
  },

  ellipsisContent: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: (props) => props.maxWidth,
  },
  filled: {
    display: 'flex',
    maxWidth: '140px',
    borderStyle: 'hidden',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    borderRadius: '4px',
    height: '80%',
    margin: '0 4px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  greyText: {
    color: '#8A8A8A',
    fontSize: 14,
    marginLeft: '0.25rem',
    alignSelf: 'center',
  },
}));

function TooltipWrapper({
  title,
  maxWidth = '80px',
  link = false,
  controlled = false,
  children,
}) {
  const classes = useTodoItemStyles({ maxWidth });
  const [show, setShow] = useState(false);

  const formatLink = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return switchLink(url) || url;
  };

  const titleVal = link ? (
    <a
      href={formatLink(title)}
      onClick={() => trackTpLink(title)}
      target="_blank"
      rel="noreferrer">
      <div className={classes.ellipsisContent}>{title}</div>
    </a>
  ) : (
    <div className={classes.ellipsisContent}>{title}</div>
  );

  if (!controlled) {
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        arrow
        placement="top"
        title={titleVal}
        TransitionComponent={Fade}>
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      onClick={() => setShow(false)}
      open={show}
      arrow
      placement="top"
      title={titleVal}
      // disableInteractive
      TransitionComponent={Fade}>
      {children}
    </Tooltip>
  );
}

export function PlaceholderTodo({
  locationId,
  locationName,
  locationBias,
  handleThingsToDoButtonClick,
  isSectionHighlight,
}) {
  const [title, setTitle] = useState('');
  const { playSound } = useSounds();
  const loading = useSelector((state) => state.Activity.placeholderLoading);
  const [enterTrigger, setEnterTrigger] = useState(false);
  const [isActivityFocused, setActivityFocused] = useState(false);
  const [isActivityHovered, setActivityHovered] = useState(false);
  const inputRef = useRef();
  const buttonRef = useRef();
  const { handleLocationSelect } = useMapUtils();
  const classes = useTodoItemStyles({ isActivityHovered, isActivityFocused });
  const dispatch = useDispatch();

  const createActivityInSection = async ({
    title: activityTitle,
    mapPin,
    ...activityProps
  }) => {
    if (activityTitle === '') return;
    dispatch(
      createNewActivityInLocation({
        activityTitle,
        locationId,
        mapPin: mapPin?.id,
        ...activityProps,
      })
    );
    phTrackEvent({
      event: EVENTS.PLAN_ACTIVITY.ADD_START,
    });
  };

  return (
    <div className={`${isSectionHighlight ? '' : classes.newTodoItem}`}>
      <Grid
        className={`
      ${classes.root}
      ${
        isActivityFocused
          ? classes.focused
          : isActivityHovered
          ? classes.hovered
          : null
      }
      `}
        onMouseEnter={() => setActivityHovered(true)}
        onMouseLeave={() => setActivityHovered(false)}>
        <OutlinedFlagRounded
          fontSize="small"
          className={classes.activityIcon}
        />
        <Grid item className={classes.inputContainer}>
          <PlacesSearchBar
            isActive={isActivityFocused && title?.length > 2}
            tripLocation={false}
            locationBias={locationBias}
            popperPlacement="bottom"
            CustomSearchBar={InputBase}
            inputRef={inputRef}
            handleSelect={async (option) => {
              playSound(SOUNDS.softPop);
              const location = await handleLocationSelect(
                option.place_id,
                undefined,
                'ACTIVITY'
              );
              setEnterTrigger(true);
              await createActivityInSection(location);
              inputRef?.current?.blur();
            }}
            value={title}
            onChange={(newTitle) => setTitle(newTitle)}
            searchBarProps={{
              autoComplete: 'off',
              placeholder: 'new activity',
              onFocus: () => {
                setActivityFocused(true);
              },
              onBlur: async (e) => {
                if (
                  buttonRef.current &&
                  buttonRef.current.contains(e.relatedTarget)
                ) {
                  return;
                }
                setActivityFocused(false);
                setActivityHovered(false);
                if (enterTrigger) {
                  setEnterTrigger(false);
                } else {
                  createActivityInSection({ title });
                }
              },
              onKeyPress: async (e) => {
                if (e.key === 'Enter') {
                  inputRef?.current?.blur();
                }
              },
              onClick: () => {
                if (title !== '') {
                  setActivityFocused(false);
                  setActivityHovered(false);
                }
              },
              inputProps: {
                style: { padding: 3, cursor: 'pointer' },
                sx: {
                  '&::placeholder': {
                    color: '#8A8A8A',
                    opacity: isActivityFocused ? 'auto' : 1,
                  },
                },
              },
            }}
            name="activityTitle"
            {...(loading === locationId
              ? {
                  endAdornment: (
                    <CircularProgress size={16} style={{ marginRight: 4 }} />
                  ),
                }
              : {})}
          />
        </Grid>
      </Grid>
      {isActivityFocused && (
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
          <OrangeOutlinedButton
            onClick={handleThingsToDoButtonClick}
            startIcon={<OutlinedFlagRounded sx={{ height: 14, width: 14 }} />}
            sx={{
              padding: '2px 8px',
              fontSize: 12,
              borderRadius: '20px',
            }}
            ref={buttonRef}>
            {`Things to do in ${locationName}`}
          </OrangeOutlinedButton>
        </div>
      )}
    </div>
  );
}

function TodoItem({
  todoId,
  column,
  locationId,
  index,
  isDragging,
  isSectionHighlight,
  tripId,
  locationBias,
}) {
  const isQuickAddActivityEnabled = useFeatureFlagEnabled(
    FLAGS.QUICK_ADD_ACTIVITIES
  );
  const todo = useSelector((state) => state.Activity.todos[todoId]);
  const isNewActivity = useSelector((state) => state.Activity.isNewActivity);
  const activeMapPin = useSelector((state) => state.View.activeMapPin);
  const highlightedTodo = useSelector((state) => state.View.highlightedToDo);
  const {
    inProgress: tourInProgress,
    tourName,
    currentStep: tourCurrentStep,
    currentStepName: tourCurrentStepName,
  } = useSelector((state) => state.View.tour);
  const { user } = useContext(firebaseAuth);
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );
  const userId = user.uid;
  const { id } = todo;
  const { playSound } = useSounds();

  // state to handle the activity title changes
  const [title, setTitle] = useState(todo?.title);
  const [notes, setNotes] = useState(todo?.description);
  const [cost, setCost] = useState(todo?.cost);
  const [currency, setCurrency] = useState(todo?.currency);
  const [costPer, setCostPer] = useState(todo?.costPer);
  const [date, setDate] = useState(
    parseISODate(todo?.activityTime, todo?.version)
  );
  const [link, setLink] = useState(todo?.links);
  const [address, setAddress] = useState(todo?.streetAddress);
  const timeRef = useRef(null);
  const { getTour, tourOpen } = useTour();
  // fetching files to display in preview icons
  const files = useSelector((state) => {
    if (todo?.files && todo?.files.length !== 0 && state.Files?.files) {
      return todo?.files.map((itemId) => state.Files.files[itemId]);
    }
    return [];
  });

  // states to handle the activity like changes
  const [isLiked, setLike] = useState(
    todo?.likes?.some((likeObj) => likeObj.id === userId)
  );
  const [likes, setCount] = useState(
    todo?.likes?.map((likeObj) => likeObj.id) || []
  );

  const trip = useSelector((state) => state.Trips.trips[tripId]);
  const tripOwner = trip?.owner || [];
  const tripSharedUsers = trip?.sharedUsers || [];
  const tripUsers = [tripOwner, ...tripSharedUsers];
  const likedUsers = tripUsers.filter((tripUser) =>
    likes.includes(tripUser.id)
  );

  const firstNames = likedUsers.map((tripUser) => tripUser.firstName);

  let namesString = firstNames.join(', ');

  if (firstNames.length > 1) {
    const lastCommaIndex = namesString.lastIndexOf(',');
    namesString = `${namesString.slice(0, lastCommaIndex)} &${namesString.slice(
      lastCommaIndex + 1
    )}`;
  }

  // states to handle conditional styles
  const [isActivityFocused, setActivityFocused] = useState(false);
  const [isActivityHovered, setActivityHovered] = useState(false);
  const [isActivityHighlight, setActivityHighlight] = useState(false);
  const [openInlineBlade, setOpenInlineBlade] = useState(false);
  const [mobileSingleClick, setMobileSingleClick] = useState(false);
  const [likeAnimation, setLikeAnimation] = useState(false);

  const classes = useTodoItemStyles({
    isActivityFocused,
    isActivityHovered,
    isActivityHighlight,
  });

  // refs to keep track of elements that trigger events
  const inputRef = useRef();
  const containerRef = useRef();
  const delayRef = useRef();

  // for checking single and double clicks on mobile
  let wait = false;
  let timer;
  let waitForStep = true;

  // state to coordinate if the blur event is due to the enter keypress.
  const [enterTrigger, setEnterTrigger] = useState(false);

  const dispatch = useDispatch();
  // focus on map on select
  const { focusPin, place, setPlace, handleLocationSelect } = useMapUtils();

  useEffect(() => {
    if (isQuickAddActivityEnabled && todo?.mapPin === activeMapPin) {
      setActivityHighlight(true);
    } else {
      setActivityHighlight(false);
    }
  }, [activeMapPin, isQuickAddActivityEnabled]);

  useEffect(() => {
    if (highlightedTodo) {
      const highlightTimer = setTimeout(() => {
        dispatch(actions.View.setHighlightedToDo({ todo: null }));
      }, 4000);
      return () => clearTimeout(highlightTimer);
    }
  }, [highlightedTodo]);

  // check if device is ios
  function iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  const handleDelete = async () => {
    try {
      const filesToBeAdded = [];
      if (todo?.files?.length > 0) {
        const tempArr = fileRelations?.filter((relation) =>
          todo.files.includes(relation.fileId)
        );
        tempArr?.forEach((relation) => {
          if (
            tempArr.filter((rel) => rel.fileId === relation.fileId).length === 1
          ) {
            filesToBeAdded.push(relation.fileId);
          }
        });
      }
      dispatch(
        deleteReduxTodo({
          variables: {
            id,
            thingsToDoId: column?.id,
            ...(filesToBeAdded?.length > 0 && {
              filesToBeAdded,
              tripId,
            }),
          },
          mapPin: todo?.mapPin,
          sectionId: column?.id,
          files: todo?.files,
        })
      );

      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
      // updated the last updated section under destination
      updateSessionStorageForLastEditedSection(locationId, column.id);
    } catch (err) {
      // handle error here
    }
  };

  // function to dynamically update activity title
  const updateActivityTitle = (source, updatedActivity = {}) => {
    const { mapPin, title: newTitle, ...activityProps } = updatedActivity;
    if (title || newTitle) {
      // Update this activity
      if (todo.id.includes('local-todo')) {
        const todosLen = column.todos.length;
        const newTodoId = `local-todo-${todosLen}`;
        const newActivity = {
          id: newTodoId,
          title: '',
        };
        if (window?.heap) window?.heap.track('Activity Created');
        dispatch(
          createReduxActivity({
            variables: {
              thingsToDoId: column.id,
              title: newTitle || title,
              likes: [],
              mapPin: mapPin?.id,
              index,
              ...activityProps,
            },
            sectionId: column.id,
            index,
            localId: todo?.id,
            shouldAppendActivity: source === 'Enter',
            localActivity: newActivity,
          })
        );
        getTour()
          .onActiveStep(stepName.ACTIVITY_ADDED_TO_TRIP_MOBILE)
          .openTour();

        // updated the last updated section under destination
        updateSessionStorageForLastEditedSection(locationId, column.id);
        phTrackEvent({
          event: EVENTS.PLAN_ACTIVITY.ADD_START,
        });
        dispatch(
          actions.Activity.setNewActivity({
            activityId: newTodoId,
          })
        );
      } else {
        try {
          const { title: cachedTitle } = client.readFragment({
            id: `Todo:${todo.id}`,
            fragment: gql`
              fragment todo on Todo {
                title
              }
            `,
          });
          if (cachedTitle === title) return;

          dispatch(
            updateReduxActivity({
              variables: {
                id,
                title,
                likes,
                ...activityProps,
              },
            })
          );
        } catch (err) {
          // do nothing
        }
      }
    } else if (todo.id.includes('local-todo')) {
      /*
        Checking if the activity is a local-todo, if so, delete it.
        Otherwise, revert the title back to what it was.
      */
      handleDelete();
    } else {
      setTitle(todo.title);
    }
    if (tourInProgress && tourName === 'sampleTripFlowMobile') {
      if (tourCurrentStep === 2 && !tourOpen) {
        setTimeout(() => {
          // dispatch(actions.View.setTourCurrentStep(tourCurrentStep + 1));
          dispatch(actions.View.setTourOpen(true));
        }, 1000);
      }

      // if (tourCurrentStep === 1) {
      //   dispatch(actions.View.setTourOpen(false));
      // }
      inputRef?.current?.blur();
    }
  };

  const addLocalActivity = async () => {
    const todosLen = column.todos.length;
    const newTodoId = `local-todo-${todosLen}`;
    const newActivity = {
      id: newTodoId,
      title: '',
    };

    dispatch(
      actions.Activity.createLocalActivity({
        localId: newTodoId,
        todo: newActivity,
      })
    );
    dispatch(
      actions.Section.addLocalActivityToSection({
        sectionId: column.id,
        activityId: newTodoId,
        index,
      })
    );

    // sets this to ensure the last added input is highlighted.
    dispatch(
      actions.Activity.setNewActivity({
        activityId: newTodoId,
      })
    );
    getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
  };

  const handleUpdate = (activity, additionalFields) => {
    if (
      activity?.title &&
      activity?.title !== todo?.title &&
      todo.mapPin !== null &&
      !activity?.mapPin
    ) {
      dispatch(
        updateMapPinTitle({
          mapPinId: todo.mapPin,
          title: activity.title,
          tripId,
        })
      );
    }
    dispatch(
      updateReduxActivity({
        variables: {
          id,
          ...activity,
        },
        ...additionalFields,
      })
    );
  };

  const handleLikesActivity = (newLikes) => {
    handleUpdate({
      likes: newLikes,
    });
  };

  const handleTitleUpdate = async (newTitle) => {
    if (newTitle === '') return;
    setTitle(newTitle);
    handleUpdate({
      title: newTitle,
    });
  };

  const handleCostUpdate = (newCost) => {
    const {
      amount: costAmount,
      currency: costCurrency,
      per: costPerOption,
    } = newCost;
    setCost(costAmount);
    setCurrency(costCurrency);
    setCostPer(costPerOption);

    handleUpdate({
      cost: costAmount,
      currency: costCurrency,
      costPer: costPerOption,
    });
  };

  const handleNotesUpdate = (newNotes) => {
    setNotes(newNotes);
    handleUpdate({
      description: newNotes,
    });
  };

  const handleDateUpdate = (newDate) => {
    // const ISODate = removeTimezoneOffset(newDate)?.toISOString();
    setDate(newDate);
    handleUpdate({
      activityTime: newDate,
    });
  };

  const handleLinkUpdate = (newLink) => {
    setLink(newLink);
    handleUpdate({
      links: newLink,
    });
  };

  const handleLocationUpdate = async ({
    streetAddress,
    city,
    state,
    country,
    zipCode,
    mapPin: newMapPin,
    title: newTitle,
  }) => {
    if (id.includes('local-todo')) {
      await updateActivityTitle('', {
        streetAddress,
        city,
        state,
        country,
        zipCode,
        mapPin: newMapPin,
        title: newTitle,
      });
      return;
    }
    setAddress(streetAddress);
    if (todo?.mapPin !== newMapPin?.id) {
      dispatch(
        updateMapPinTitle({
          mapPinId: newMapPin?.id,
          title,
          tripId,
        })
      );
    }
    handleUpdate({
      streetAddress,
      city,
      state,
      country,
      zipCode,
      mapPin: newMapPin?.id,
      title: newTitle,
    });
  };

  // array to keep track of preview icons
  let previewIcons = [];
  let extraTooltip = [];

  if (link) {
    previewIcons.push(
      <TooltipWrapper title={link} link>
        <Link
          className={classes.activityBladeIcon}
          onClick={() => {
            setOpenInlineBlade(true);
          }}
        />
      </TooltipWrapper>
    );
  }

  if (cost) {
    previewIcons.push(
      <TooltipWrapper title={`$${cost} ${currency || 'USD'}`}>
        <AttachMoney
          className={classes.activityBladeIcon}
          style={{ fontSize: '14px' }}
          onClick={() => {
            setOpenInlineBlade(true);
          }}
        />
      </TooltipWrapper>
    );
  }

  if (address) {
    previewIcons.push(
      <TooltipWrapper title={address}>
        <div
          style={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setOpenInlineBlade(true);
          }}>
          <LocationPinIcon
            className={classes.activityBladeIcon}
            fill="#8A8A8A"
          />
        </div>
      </TooltipWrapper>
    );
  }

  if (todo?.files && todo?.files.length !== 0) {
    const filesNamesArray = files.map((file) => file.name);
    const filesNames = filesNamesArray.join(', ');

    previewIcons.push(
      <TooltipWrapper title={filesNames}>
        <div
          style={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setOpenInlineBlade(true);
            phTrackEvent({
              event: EVENTS.PLAN_ACTIVITY.FILE_VIEW,
            });
            dispatch(actions.Files.setMultipleFilesPreview(todo?.files));
          }}>
          <PaperClip className={classes.activityBladeIcon} stroke="#8A8A8A" />
        </div>
      </TooltipWrapper>
    );
  }

  if (notes) {
    previewIcons.push(
      <TooltipWrapper title={notes} maxWidth="135px">
        <DescriptionOutlined
          className={classes.activityBladeIcon}
          style={{ fontSize: '14px' }}
          onClick={() => {
            setOpenInlineBlade(true);
          }}
        />
      </TooltipWrapper>
    );
  }

  const previewIconsLength = previewIcons.length;
  if (previewIconsLength === 3) {
    extraTooltip =
      link ||
      (cost ? `$${cost} ${currency || 'USD'}` : false) ||
      address ||
      (todo?.files.length !== 0 ? 'File name' : false) ||
      notes;
    previewIcons = previewIcons.slice(1);
    previewIcons.unshift(
      <TooltipWrapper title={extraTooltip}>
        <div
          className={classes.extraTooltipNumber}
          onClick={() => {
            setOpenInlineBlade(true);
          }}>
          +1
        </div>
      </TooltipWrapper>
    );
  } else if (previewIconsLength > 3) {
    extraTooltip = (
      <div style={{ display: 'flex' }}>
        {previewIcons.slice(0, previewIconsLength - 2)}
      </div>
    );
    previewIcons = previewIcons.slice(previewIconsLength - 2);
    previewIcons.unshift(
      <TooltipWrapper title={extraTooltip}>
        <div
          className={classes.extraTooltipNumber}
          onClick={() => {
            setOpenInlineBlade(true);
          }}>
          +{previewIconsLength - 2}
        </div>
      </TooltipWrapper>
    );
  }

  if (date) {
    const dateVal = todo?.version ? parseISODate(date, true) : new Date(date);
    const options = {
      month: 'short',
      day: 'numeric',
    };

    if (dateVal.getHours() !== 0 || dateVal.getMinutes() !== 0) {
      options.hour = 'numeric';
      options.minute = 'numeric';
      options.hour12 = true;
    }
    const formatted = dateVal.toLocaleString('default', options);

    previewIcons.push(
      <TooltipWrapper title={formatted} maxWidth="150px" controlled>
        <div
          style={{
            height: '16px',
            display: 'flex',
            alignItems: 'center',
          }}>
          <IconDatePicker
            useRange={false}
            onDateUpdate={(d) => handleDateUpdate(d)}
            displayDate={false}
            defaultDate={dateVal}
            iconButtonProps={{
              size: 'small',
              style: { padding: 0 },
              className: classes.iconContainer,
            }}
            iconProps={{
              style: { fontSize: '10px', color: '#8A8A8A', width: '13px' },
            }}
            showComponentTooltip={false}
          />
        </div>
      </TooltipWrapper>
    );
  }

  // To check if the todoitem needs to be focused on input
  useEffect(() => {
    if (isNewActivity === id) {
      inputRef?.current?.focus();
      setActivityHovered(true);
    }
  }, [isNewActivity]);

  useEffect(() => {
    return () => {
      clearTimeout(timeRef.current);
    };
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // function to hover the associated map pin on activity hover
  const hoverPin = (shouldHover) => {
    if (todo?.mapPin) {
      dispatch(
        actions.Map.setHoveredPin({
          pinId: shouldHover ? todo?.mapPin : null,
        })
      );
    }
  };

  // function to check single or double click on mobile screens
  const todoClickedMobile = () => {
    if (wait === true) {
      wait = false;
      clearTimeout(timer);
      return 'double';
    }
    wait = true;
    timer = setTimeout(() => {
      wait = false;
      return 'single';
    }, 300);

    return 'single';
  };

  useEffect(() => {
    if (
      tourName === 'sampleTripFlowWeb' &&
      tourCurrentStepName === stepName.FIRST_ACTIVITY_HOVER_INFO &&
      index === 0
    ) {
      getTour().openTour();
      setActivityFocused(true);
      // dispatch(actions.View.setTourOpen(true));
    }
    if (
      tourName === 'sampleTripFlowMobile' &&
      tourCurrentStep === 3 &&
      !tourOpen
    ) {
      getTour().openTour();
      setActivityFocused(true);
      setTimeout(() => {
        waitForStep = false;
      }, 1000);
    }
    if (
      waitForStep === false &&
      tourName === 'sampleTripFlowMobile' &&
      tourCurrentStep === 3 &&
      tourOpen
    ) {
      getTour().closeTour();
      setActivityFocused(true);
    }
    if (
      tourName === 'quickStartFlowMobile' &&
      tourCurrentStep === 1 &&
      tourOpen
    ) {
      getTour().goToNextStep();
      setActivityFocused(true);
      setTimeout(() => {
        waitForStep = false;
      }, 1000);
    }
  }, [tourInProgress, tourName, tourCurrentStep, isActivityFocused]);

  useEffect(() => {
    if (
      isActivityFocused &&
      tourOpen &&
      ((tourName === 'sampleTripFlowMobile' && tourCurrentStep === 3) ||
        (tourCurrentStep === 5 &&
          ['emptyTripFlowWeb', 'blankTripFlowWeb'].includes(tourName)))
    ) {
      getTour().goToNextStep().closeTour();
    }
  }, [isActivityFocused]);

  useEffect(() => {
    if (tourOpen) {
      getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
    }
  }, [isDragging]);

  function CloseIfDragging({ isSnapshotDragging }) {
    useEffect(() => {
      if (isSnapshotDragging) {
        getTour().onActiveStep(stepName.FIRST_ACTIVITY_HOVER_INFO).closeTour();
      }
    }, [isSnapshotDragging]);
    return null;
  }
  return (
    <div
      className="activity-blade-container"
      id={`activity-blade-${todo?.mapPin}`}>
      <Draggable
        draggableId={id}
        index={index}
        type="ACTIVITY"
        disableInteractiveElementBlocking
        isDragDisabled={id.includes('local')}>
        {(provided, snapshot) => (
          <Grid
            item
            container
            xs={12}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={`${classes.spacingRight} ${classList.todoItem}`}
            onMouseLeave={() => {
              if (isActivityHovered && !isActivityFocused) {
                hoverPin(false);
                setActivityHovered(false);
              }
            }}>
            {isDragging || isSectionHighlight ? null : (
              <div className={classes.todoItem}>
                <Grid
                  item
                  className={`${classes.actionContainer} ${classList.itemActionContainer}`}>
                  {isActivityHovered && !isActivityFocused && !isMobile ? (
                    <>
                      <DeleteOutlineRounded
                        className={classes.actionIcon}
                        onClick={() => {
                          playSound(SOUNDS.pongPop);
                          handleDelete();
                        }}
                      />
                      <AddRounded
                        className={classes.actionIcon}
                        onClick={() => addLocalActivity()}
                      />
                    </>
                  ) : null}
                  {/* DragHandleProps seems to require a html element hence the span element */}
                  <span
                    className={classes.actionIconDragSpan}
                    {...provided.dragHandleProps}>
                    {isActivityHovered && !isActivityFocused && !isMobile ? (
                      <DragIndicatorRounded
                        className={classes.actionIconDrag}
                      />
                    ) : null}
                  </span>
                </Grid>
              </div>
            )}

            <div
              className={classList.item}
              id="activity-blade-close"
              style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              {!openInlineBlade ? (
                <Grid
                  style={{ display: 'flex', alignItems: 'center' }}
                  onMouseLeave={() => {
                    setMobileSingleClick(false);
                  }}>
                  {mobileSingleClick && (
                    <span
                      className={classes.actionIconDragSpan}
                      style={{ marginLeft: '0', marginRight: '4px' }}
                      {...provided.dragHandleProps}>
                      <DragIndicatorRounded
                        className={classes.actionIconDrag}
                      />
                    </span>
                  )}
                  <CloseIfDragging isSnapshotDragging={snapshot.isDragging} />
                  <Grid
                    // {...provided.dragHandleProps}
                    className={`
                    activity-blade-in-container
                    ${classes.root}
                    ${
                      isActivityHovered ||
                      isActivityHighlight ||
                      highlightedTodo === id
                        ? classes.hovered
                        : null
                    }  
                    ${snapshot.isDragging ? classes.isDragging : null}
                  `}
                    style={{
                      marginLeft: isDragging || isSectionHighlight ? 12 : 0,
                    }}
                    onMouseEnter={() => {
                      setActivityHovered(true);
                      hoverPin(true);
                    }}
                    ref={containerRef}>
                    <OutlinedFlagRounded
                      style={{ fontSize: 16, marginLeft: 4 }}
                      className={classes.activityIcon}
                    />
                    <Grid item className={classes.inputContainer}>
                      <PlacesSearchBar
                        key={`activity-search-${todo?.id}`}
                        isActive={isActivityFocused && title?.length > 2}
                        locationBias={locationBias}
                        tripLocation={false}
                        popperPlacement="bottom"
                        CustomSearchBar={InputBase}
                        inputRef={inputRef}
                        handleSelect={async (option) => {
                          const location = await handleLocationSelect(
                            option.place_id,
                            undefined,
                            'ACTIVITY'
                          );
                          playSound(SOUNDS.softPop);
                          setEnterTrigger(true);
                          setActivityFocused(false);
                          setActivityHovered(false);
                          hoverPin(false);
                          if (!delayRef.current) {
                            await updateActivityTitle('Enter', location);
                          } else {
                            delayRef.current = null;
                          }
                          inputRef?.current?.blur();
                        }}
                        value={title}
                        onChange={(newTitle) => setTitle(newTitle)}
                        searchBarProps={{
                          autoComplete: 'off',
                          placeholder: 'new activity',
                          onFocus: () => {
                            if (
                              // waitForStep === false &&
                              tourName === 'quickStartFlowMobile' &&
                              tourCurrentStep === 2 &&
                              tourOpen
                            ) {
                              dispatch(actions.View.setTourCurrentStep(3));
                              dispatch(actions.View.setTourOpen(false));
                            }
                            if (
                              tourCurrentStep === 2 &&
                              tourName === 'sampleTripFlowMobile' &&
                              tourInProgress &&
                              tourOpen
                            ) {
                              dispatch(actions.View.setTourCurrentStep(3));
                            }

                            setActivityFocused(true);
                            if (todo?.mapPin) {
                              focusPin(todo.mapPin);
                            }
                          },
                          onBlur: async () => {
                            timeRef.current = setTimeout(async () => {
                              if (!enterTrigger) {
                                setActivityFocused(false);
                                setActivityHovered(false);
                                hoverPin(false);
                                await updateActivityTitle('Blur');
                              } else {
                                setEnterTrigger(false);
                              }
                            }, 250); // Delay to ensure button click is processed first
                          },
                          onKeyPress: async (e) => {
                            if (e.target.value.length > 0 && tourOpen) {
                              getTour().closeTour().goToNextStep();
                            }
                          },
                          onKeyUp: async (e) => {
                            if (e.key === 'Enter' || e.key === 'Escape') {
                              setEnterTrigger(true);
                              setActivityFocused(false);
                              setActivityHovered(false);
                              hoverPin(false);
                              delayRef.current = true;
                              await updateActivityTitle('Enter');
                            }
                          },
                          onClick: () => {
                            if (title !== '') {
                              if (!isMobile) {
                                setOpenInlineBlade(true);
                                setActivityFocused(false);
                                setActivityHovered(false);
                                hoverPin(false);
                                // change nav bar focus
                                dispatch(
                                  actions.View.setCommandBar({
                                    activeLocationId: locationId,
                                    activeSectionId: column?.id,
                                  })
                                );
                              } else {
                                const clickType =
                                  todoClickedMobile() || 'single';
                                if (clickType === 'single') {
                                  setActivityFocused(true);
                                  setMobileSingleClick(true);
                                } else if (clickType === 'double') {
                                  setMobileSingleClick(false);
                                  setOpenInlineBlade(true);
                                  setActivityFocused(false);
                                  setActivityHovered(false);
                                  hoverPin(false);
                                }
                              }
                            }
                          },

                          inputProps: {
                            style: { padding: 3, cursor: 'pointer' },
                          },
                        }}
                      />
                    </Grid>
                    <Grid className={classes.activityBladeIconContainer}>
                      {previewIcons}
                    </Grid>
                    <Grid item style={{ display: 'flex', shrink: 1 }}>
                      <Typography
                        className={`${classes.text} ${classes.likesCount}`}>
                        {likes?.length > 0 ? likes.length : ''}
                      </Typography>
                      <Typography
                        className={classes.text}
                        style={{ marginRight: 4, position: 'relative' }}>
                        {!isActivityFocused || isMobile ? (
                          !iOS() && likeAnimation ? (
                            <>
                              <Favorite
                                className={classes.favorite}
                                style={{ visibility: 'hidden' }}
                              />
                              <CustomLottiePlayer
                                filePath="/lottiefiles/heart_animation.lottie"
                                style={{
                                  width: 65,
                                  height: 65,
                                  position: 'absolute',
                                  right: '-24px',
                                }}
                                autoplay={false}
                                loop={false}
                              />
                            </>
                          ) : likes?.length > 0 ? (
                            <Tooltip
                              classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.tooltipArrow,
                              }}
                              arrow
                              placement="top"
                              title={
                                <div
                                  style={{
                                    padding: '5px 9px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}>
                                  <div style={{ marginRight: '-5px' }}>
                                    <AvatarStack
                                      people={likedUsers}
                                      renderSmall={false}
                                      limit={4}
                                      LTR
                                      CustomLimitComponent={({ count }) => (
                                        <span className={classes.greyText}>
                                          + {count}
                                        </span>
                                      )}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      maxWidth: '150px',
                                      textWrap: 'wrap',
                                      textAlign: 'center',
                                      marginTop: '8px',
                                      lineHeight: '16px',
                                      whiteSpace: 'normal',
                                    }}>
                                    {namesString}
                                  </div>
                                </div>
                              }
                              TransitionComponent={Fade}>
                              {!isLiked ? (
                                <Favorite
                                  className={classes.favoriteInactive}
                                  onClick={() => {
                                    playSound(SOUNDS.heartChime);
                                    setLikeAnimation(true);
                                    setLike(true);
                                    const newLikes = [...likes, userId];
                                    setCount(newLikes);
                                    handleLikesActivity(newLikes);
                                    setTimeout(() => {
                                      setLikeAnimation(false);
                                    }, 1000);
                                  }}
                                />
                              ) : (
                                <Favorite
                                  className={classes.favorite}
                                  onClick={() => {
                                    playSound(SOUNDS.removeBaritone);
                                    setLike(false);
                                    const newLikes = [...likes];
                                    const userLikeIndex =
                                      newLikes.indexOf(userId);
                                    newLikes.splice(userLikeIndex, 1);
                                    setCount(newLikes);
                                    handleLikesActivity(newLikes);
                                  }}
                                />
                              )}
                            </Tooltip>
                          ) : (
                            <Favorite
                              className={classes.favoriteInactive}
                              onClick={() => {
                                setLikeAnimation(true);
                                playSound(SOUNDS.heartChime);
                                setLike(true);
                                const newLikes = [...likes, userId];
                                setCount(newLikes);
                                handleLikesActivity(newLikes);
                                setTimeout(() => {
                                  setLikeAnimation(false);
                                }, 1000);
                              }}
                            />
                          )
                        ) : null}
                      </Typography>
                    </Grid>
                  </Grid>
                  {mobileSingleClick && (
                    <DeleteOutlineRounded
                      className={classes.actionIcon}
                      onClick={handleDelete}
                    />
                  )}
                </Grid>
              ) : (
                <div
                  // {...provided.dragHandleProps}
                  style={{
                    margin: '8px 0% 0% 0px',
                  }}>
                  <InlineBlade
                    className="activity-inlineblade-container"
                    open={openInlineBlade}
                    id={id}
                    locationBias={locationBias}
                    setOpen={async (isOpen) => {
                      if (!isOpen && todo?.mapPin?.id === place?.pinId) {
                        setPlace(null);
                      }
                      setOpenInlineBlade(isOpen);
                      if (!isOpen)
                        await updateActivityTitle('Close', {
                          cost,
                          currency,
                          costPer: Number(costPer),
                          activityTime: date,
                          description: notes,
                          streetAddress: address,
                          links: link,
                          files: todo?.files,
                        });
                      if (isMobile) {
                        setActivityHovered(isOpen);
                        setActivityFocused(isOpen);
                      }
                    }}
                    handleEnterPress={async (newTitle) => {
                      setTitle(newTitle);
                      await updateActivityTitle('Close', {
                        title: newTitle,
                      });
                      setOpenInlineBlade(false);
                      if (newTitle !== '') {
                        await addLocalActivity();
                      }
                    }}
                    name={title}
                    updateNameCallback={handleTitleUpdate}
                    notes={notes}
                    updateNotesCallback={handleNotesUpdate}
                    hasDate
                    dateVal={date}
                    updateDateCallback={handleDateUpdate}
                    hasCost
                    costVal={{
                      amount: cost,
                      currency,
                      per: costPer,
                    }}
                    updateCostCallback={handleCostUpdate}
                    hasLink
                    linkVal={link}
                    updateLinkCallback={handleLinkUpdate}
                    isActivity
                    hasLocation
                    updateLocationCallback={handleLocationUpdate}
                    address={address}
                    setAddress={setAddress}
                    mapPinId={todo?.mapPin}
                    trackFileView={() =>
                      phTrackEvent({
                        event: EVENTS.PLAN_ACTIVITY.FILE_VIEW,
                      })
                    }
                    fileAttachmentModalProps={{
                      attachedToText: title,
                      attachedToID: todoId,
                      attachedToType: 'Activity',
                      attachedFiles: todo?.files,
                      attachFunc: (newFiles, tripid) => {
                        handleUpdate(
                          {
                            files: newFiles,
                          },
                          {
                            attachedToType: 'Activity',
                            tripId: tripid,
                          }
                        );
                      },
                    }}
                    isNewActivity={todo?.id.includes('local-todo')}
                    addActivityBelow={addLocalActivity}
                    activityVersion={todo?.version}
                  />
                </div>
              )}
            </div>
            {provided.placeholder}
          </Grid>
        )}
      </Draggable>
    </div>
  );
}

export default TodoItem;
