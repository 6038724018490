import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Box,
  Grid,
  Typography,
  Tooltip,
  Popper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Paper,
  Fade,
} from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import actions from '../../../redux/actions';
import { DraggableIconPlaceHolder, HeadingIcon } from '../../atoms/Icon';
import PortalAwareDraggableItem from './PortalAwareDraggableItem';
import useTour from '../../molecules/Tour/useTour';

const useStyles = makeStyles({
  listItemContainer: {
    cursor: 'default',
    height: '42px',
    '&:hover': {
      backgroundColor: '#f4f4f4',
      color: '#000000',
    },
  },
  listItemText: {
    '&:hover': {
      color: '#000000',
    },
    maxWidth: '150px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  arrowDropDown: {
    transform: 'rotate(90deg)',
    height: 22,
    width: 22,
  },
});

function HeadingButton({
  locations,
  thingsToDo,
  activeSectionId,
  commonStyles,
  headingAnchorEl,
  setHeadingAnchorEl,
  activeLocationId,
  handleClickAway,
  isHeadingClicked,
  handleAddSection,
  isArrowClicked,
  handleArrowClick,
  isKeyboardShortcutEnabled,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { getTour } = useTour();

  const handleSubMenuClick = (event) => {
    if (getTour().tourOpen) {
      return;
    }
    handleArrowClick();
    setHeadingAnchorEl(headingAnchorEl ? null : event.currentTarget);
  };
  const handleSectionClick = (sectionId) => {
    setHeadingAnchorEl(null);
    dispatch(
      actions.View.setCommandBar({
        activeSectionId: sectionId,
      })
    );
    dispatch(
      actions.View.setHighlightedHeading({
        section: null,
      })
    );
  };

  const renderSections = () => {
    const handleSectionHover = (e, highLightedsectionId) => {
      e.stopPropagation();
      const element = document.getElementById(
        `todo-header-${highLightedsectionId}`
      );
      if (element && !getTour().tourInProgress) {
        dispatch(
          actions.View.setHighlightedHeading({
            section: highLightedsectionId,
          })
        );
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    };

    const location = locations[activeLocationId];
    const sections =
      location?.thingsToDo?.map((thingsToDoId) => thingsToDo[thingsToDoId]) ||
      [];
    return sections?.map((section) => {
      const { id, name } = section;
      return (
        <ListItem
          key={id}
          className={classes.listItemContainer}
          onClick={() => handleSectionClick(id)}
          onMouseOver={(e) => {
            handleSectionHover(e, section?.id);
          }}
          onMouseLeave={() => {
            dispatch(
              actions.View.setHighlightedHeading({
                section: null,
              })
            );
          }}
          alignItems="center"
          style={{ padding: '2px 12px' }}>
          <ListItemText
            primary={name === '' ? 'Unnamed Section' : name}
            primaryTypographyProps={{ fontSize: '14px' }}
            className={classes.listItemText}
          />
        </ListItem>
      );
    });
  };

  return (
    <Grid item style={{ paddingLeft: 1 }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        id="heading-button-container">
        <Droppable
          droppableId="droppable-heading"
          type="SECTION"
          isDropDisabled>
          {(droppableProvided, droppableSnapshot) => (
            <Tooltip
              title={
                <Box display="flex" alignItems="center">
                  <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
                    Heading
                  </Typography>
                  {isKeyboardShortcutEnabled && (
                    <Box sx={commonStyles.shortcutKey}>H</Box>
                  )}
                </Box>
              }
              enterDelay={500}
              TransitionComponent={Fade}
              placement="top"
              arrow
              componentsProps={commonStyles.componentsProps}>
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}>
                <Draggable
                  type="ITEM"
                  key="heading"
                  draggableId="commandBar-heading"
                  index={0}
                  id={0}
                  disableInteractiveElementBlocking>
                  {(provided, snapshot) => (
                    <PortalAwareDraggableItem snapshot={snapshot}>
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Box
                          sx={
                            snapshot.isDragging
                              ? commonStyles.draggableExpandedState
                              : isHeadingClicked
                              ? commonStyles.draggableIconClickStyle
                              : commonStyles.hoverStyle
                          }
                          onClick={handleAddSection}>
                          <HeadingIcon />
                        </Box>
                      </div>
                    </PortalAwareDraggableItem>
                  )}
                </Draggable>
                {droppableProvided.placeholder &&
                  droppableSnapshot.isUsingPlaceholder && (
                    <DraggableIconPlaceHolder />
                  )}
              </div>
            </Tooltip>
          )}
        </Droppable>
        <Tooltip
          title={
            <Typography sx={{ fontSize: '13px', fontWeight: 300 }}>
              Select heading
            </Typography>
          }
          placement="top"
          arrow
          componentsProps={commonStyles.componentsProps}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '90px',
              ...(isArrowClicked
                ? commonStyles.arrowClickedStyle
                : commonStyles.clickableTextContainer),
            }}
            onClick={handleSubMenuClick}>
            <Typography
              sx={{
                maxWidth: '65px',
                marginRight: '4px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                marginLeft: 1,
                cursor: 'pointer',
              }}>
              {thingsToDo[activeSectionId]?.name || 'New Heading'}
            </Typography>

            <Box sx={commonStyles.arrowStyle}>
              <ArrowRightRoundedIcon className={classes.arrowDropDown} />
            </Box>
          </Box>
        </Tooltip>

        <Popper
          open={Boolean(headingAnchorEl)}
          anchorEl={headingAnchorEl}
          style={{ zIndex: 1123 }}
          placement="top-end"
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <Paper>
              <List style={{ maxHeight: 400, overflowY: 'auto' }}>
                {renderSections()}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </Grid>
  );
}

export default HeadingButton;
