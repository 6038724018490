import config from '../../../config';
import getCfConnector from '../../../cfConnector';

export const getHotel = async ({
  hotelKey,
  checkInDate,
  checkOutDate,
  rooms,
}) => {
  try {
    return (
      await (
        await getCfConnector()
      ).get(
        `${config.recommendationsAPI}/hotel?hotelKey=${hotelKey}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}&rooms=${rooms}`
      )
    )?.data?.data;
  } catch {
    return null;
  }
};

export const getInternalHotel = async ({
  hotelId,
  checkInDate,
  checkOutDate,
  rooms,
}) => {
  const sessionId = window.localStorage.getItem('searchSessionId');
  if (!sessionId && !(checkInDate && checkOutDate && rooms)) {
    const returnUrl = window.localStorage.getItem('returnUrl');
    window.location.href = returnUrl;
    return null;
  }
  return (
    await (
      await getCfConnector()
    ).post(`${config.bookingsAPI}/${hotelId}`, {
      sessionId,
      checkInDate,
      checkOutDate,
      rooms,
    })
  )?.data?.result;
};

export const getInternaHotelContent = async ({ hotelId }) => {
  return (
    await (
      await getCfConnector()
    ).post(`${config.bookingsAPI}/${hotelId}/static`)
  )?.data?.result;
};

export const getPrices = async ({
  hotelId,
  checkInDate,
  checkOutDate,
  refetch = 0,
  rooms,
}) => {
  const sessionId = window.localStorage.getItem('searchSessionId');
  if (!sessionId) {
    return null;
  }
  const response = (
    await (
      await getCfConnector()
    ).post(`${config.bookingsAPI}/${hotelId}/prices`, {
      sessionId,
      checkInDate,
      checkOutDate,
      refetch,
      rooms,
    })
  )?.data;
  if (response.sessionId) {
    window.localStorage.setItem('searchSessionId', response.sessionId);
  }
  return response?.result || {};
};

export const getCxlPolicy = async ({ packageId, hotelId, supplierId }) => {
  const sessionId = window.localStorage.getItem('searchSessionId');
  if (!sessionId) {
    return null;
  }
  return (
    await (
      await getCfConnector()
    ).post(`${config.bookingsAPI}/${hotelId}/cxlPolicy`, {
      packageId,
      sessionId,
      supplierId,
    })
  )?.data?.result;
};
