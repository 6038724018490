import { makeStyles, styled } from '@mui/styles';
import { Box, Typography, TextField } from '@mui/material';

const useStyles = makeStyles(() => ({
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#FFF',
  '& .MuiInputLabel-root': {
    color: '#8A8A8A',
    fontSize: 14,
  },
  '& .MuiInputLabel-shrink': {
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
  },
  '& .MuiInputBase-root': {
    '& fieldset': {
      border: '0.5px solid #D9D9D9 !important',
    },
  },
}));

function GuestRequests({ value, handleChange }) {
  const classes = useStyles();

  return (
    <Box className={classes.box} sx={{ p: 3, width: '100%' }}>
      <Typography variant="h2">
        Additional Information{' '}
        <span style={{ color: '#8A8A8A', fontWeight: 'normal', fontSize: 14 }}>
          (optional)
        </span>
      </Typography>
      <Typography variant="h5" color="text.secondary" mb={2}>
        The property will do its best to fulfill your requests, but all requests
        are not guaranteed.
      </Typography>
      <StyledTextField
        fullWidth
        variant="outlined"
        placeholder="Send a request to the hotel"
        value={value}
        onChange={handleChange}
      />
    </Box>
  );
}

export default GuestRequests;
