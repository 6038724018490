import {
  ErrorRounded,
  EventRounded,
  HotelRounded,
  PeopleRounded,
} from '@mui/icons-material';
import {
  Box,
  Modal,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { format } from 'date-fns';
import { CheckMarkIcon } from '../../atoms/Icon';

function ConfirmationCard({
  hotelName,
  roomsCount,
  guestsCount,
  checkInDate,
  checkOutDate,
  success,
  error,
  loading,
  isTestMode,
}) {
  return (
    <Card
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        padding: 2,
        minWidth: 220,
        backgroundColor: '#FFF',
        position: 'relative',
        overflow: 'visible',
      }}>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 120,
          }}>
          <CircularProgress size={24} />
        </div>
      ) : (
        <>
          <CardHeader
            titleTypographyProps={{
              variant: 'h1',
            }}
            title={hotelName}
            sx={{
              px: 1,
              py: 1,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 8,
              right: -20,
            }}>
            <Avatar
              sx={{
                bgcolor: success
                  ? 'green'
                  : loading
                  ? 'primary.extraLight'
                  : 'error.main',
              }}>
              {success ? <CheckMarkIcon /> : <ErrorRounded />}
            </Avatar>
          </Box>

          <CardContent sx={{ px: 1, '&:last-child': { pb: 1 }, py: 0 }}>
            {!(error && isTestMode) && (
              <Box display="flex" flexDirection="row">
                <EventRounded fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="h5" mr={2}>
                  {checkInDate && checkOutDate
                    ? format(new Date(checkInDate), 'MMM') !==
                      format(new Date(checkOutDate), 'MMM')
                      ? `${format(new Date(checkInDate), 'MMM dd')} - ${format(
                          new Date(checkOutDate),
                          'MMM dd'
                        )}`
                      : `${format(new Date(checkInDate), 'MMM dd')} - ${format(
                          new Date(checkOutDate),
                          'dd'
                        )}`
                    : ''}
                </Typography>
                <PeopleRounded fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="h5" mr={2}>
                  {guestsCount} guests
                </Typography>
                <HotelRounded fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="h5">{roomsCount} room</Typography>
              </Box>
            )}
            <Typography variant="h3" color="text.secondary" mt={2}>
              {error ? (
                <Typography variant="caption" color="error.main">
                  {isTestMode
                    ? "Bookings are currently not being processed as we're undergoing testing. Please contact support if you have any questions."
                    : 'An error occurred while processing your booking. Any payment made will be refunded later.'}
                </Typography>
              ) : (
                <Box display="flex" justifyContent="space-between">
                  <Box>
                    <Typography variant="h5" color="text.light">
                      Check-in
                    </Typography>
                    <Typography variant="h3">
                      {checkInDate &&
                        format(new Date(checkInDate), 'MMMM dd, yyyy')}
                    </Typography>
                  </Box>
                  <Box ml={6}>
                    <Typography variant="h5" color="text.light">
                      Check-out
                    </Typography>
                    <Typography variant="h3">
                      {checkOutDate &&
                        format(new Date(checkOutDate), 'MMMM dd, yyyy')}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Typography>
          </CardContent>
        </>
      )}
    </Card>
  );
}

function CheckoutSuccessModal({
  open,
  onClose = () => {},
  hotelData,
  success,
  error,
  loading,
  isTestMode,
}) {
  const {
    name: hotelName = '',
    cityName = '',
    checkInDate = '',
    checkOutDate = '',
    roomsCount = 0,
    guestsCount = 0,
    hotelImage = 'https://images.unsplash.com/photo-1618773928121-c32242e63f39?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  } = hotelData || {};
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableAutoFocus
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
      }}>
      <Box
        sx={{
          maxWidth: '976px',
          p: 8,
          mx: 'auto',
          borderRadius: 2,
          boxShadow: 3,
          background: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${hotelImage}) no-repeat center center / cover`,
          backgroundBlendMode: 'darken',
          position: 'relative',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          outline: 'none',
        }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
          }}
        />
        <Box
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          {cityName && !error && (
            <Typography
              variant="h1"
              mb={2}
              fontSize="32px"
              color="#FFF"
              textAlign="center"
              zIndex={2}>
              You&apos;re going to
              <br />
              <span style={{ fontSize: 48 }}>{cityName}!</span>
            </Typography>
          )}
          <Box style={{ display: 'flex' }}>
            <ConfirmationCard
              hotelName={hotelName || ''}
              checkInDate={checkInDate || ''}
              checkOutDate={checkOutDate || ''}
              success={success}
              error={error}
              isTestMode={isTestMode}
              loading={loading}
              roomsCount={roomsCount}
              guestsCount={guestsCount}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default CheckoutSuccessModal;
