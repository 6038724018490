import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { PaymentElement } from '@stripe/react-stripe-js';

// eslint-disable-next-line
const useStyles = makeStyles(() => ({
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

function PaymentForm({ setPaymentDisabled = () => {} }) {
  const classes = useStyles();

  return (
    <Box className={classes.box} sx={{ p: 3, width: '100%' }}>
      <Typography variant="h2" mb={2}>
        Payment Details <span style={{ color: 'red' }}>*</span>
      </Typography>
      <PaymentElement
        onChange={(e) => {
          setPaymentDisabled(!e?.complete);
        }}
        options={{
          layout: 'tabs',
          fields: { billingDetails: { address: 'auto' } },
        }}
      />
      <div style={{ marginTop: 12 }} />
      {/* <AddressElement options={{ mode: 'billing' }} /> */}
    </Box>
  );
}

export default PaymentForm;
