import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteSection as deleteReduxSection,
  updateSection as updateReduxSection,
} from '../../../../redux/slices/Section';
import { createSection } from '../../../../redux/slices/sharedThunks';

import BlockHeader from '../BlockHeader';

import actions from '../../../../redux/actions';
import { useItineraryDnd } from '../../ItineraryDndProvider';
import { parseISODate, removeTimezoneOffset } from '../../../../utils';
import { EVENTS, phTrackEvent } from '../../../../analytics';

function TodoHeader({
  locationId,
  sectionId,
  column,
  dragHandleProps,
  index,
  isDragging,
  isSectionHighlight,
}) {
  const highlightedSection = useSelector(
    (state) => state.View.highlightedHeading
  );
  const isHighlight = highlightedSection === sectionId;
  const { isNewSection, setNewSection } = useItineraryDnd();
  const {
    inProgress: tourInProgress,
    tourName,
    currentStep: tourCurrentStep,
  } = useSelector((state) => state.View.tour);

  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const { slug: tripId } = useParams();

  const handleDeleteSection = (e, columnToBeDeleted) => {
    dispatch(
      deleteReduxSection({
        variables: {
          locationId,
          id: columnToBeDeleted.id,
        },
        tripId,
      })
    );
  };

  const updateTitle = async (title) => {
    if (title || column.todos?.length) {
      // Update this section
      if (column.id.includes('local-section')) {
        const res = await dispatch(
          createSection({
            variables: {
              locationId,
              name: title,
              activityDate: new Date(),
              todos: [],
              index,
            },
            locationId,
            index,
            callback: () => {},
          })
        );
        dispatch(
          actions.View.setCommandBar({
            activeSectionId: res.payload.createThingsToDo.id,
          })
        );
        phTrackEvent({
          event: EVENTS.PLAN_HEADING.ADD_START,
        });
      } else {
        if (column.name === title) return;
        dispatch(
          updateReduxSection({
            variables: {
              id: column.id,
              name: title,
            },
          })
        );
        // // TODO: fix updateColumn to accomodate for a section with empty activities.
        // updateColumn(locationId, column.id, title);
      }
    } else {
      // Delete this section
      handleDeleteSection(null, column);
    }
  };

  const updateDate = (event) => {
    dispatch(
      updateReduxSection({
        variables: {
          id: column.id,
          startDate:
            event.from && removeTimezoneOffset(event.from).toISOString(),
          endDate: event.to && removeTimezoneOffset(event.to).toISOString(),
        },
      })
    );
  };

  const addNewSection = async () => {
    // TODO: replace with uuid
    const newColumnId = `local-section-${Date.now()}`;
    const newSection = {
      id: newColumnId,
      name: '',
      todos: [],
    };
    dispatch(
      actions.Section.addLocalSection({
        localId: newColumnId,
        section: newSection,
      })
    );
    dispatch(
      actions.Location.addLocalSectionToLocation({
        locationId,
        sectionId: newColumnId,
        index,
      })
    );

    setNewSection(newColumnId);
  };

  // To check if the section header needs to be focused on input
  useEffect(() => {
    if (isNewSection === column.id) {
      inputRef?.current?.focus();
      setNewSection(null);
    }
  }, [isNewSection]);

  return (
    <div
      id={`todo-header-${sectionId}`}
      onClick={() => {
        // if (tourInProgress && tourName.startsWith('quickStartFlow') && tourCurrentStep === 0) {
        //   dispatch(actions.View.setTourCurrentStep(1));
        // }
        if (
          tourInProgress &&
          tourName.startsWith('quickStartFlow') &&
          tourCurrentStep === 0
        ) {
          dispatch(actions.View.setTourCurrentStep(1));
        }
      }}>
      <BlockHeader
        updateDate={updateDate}
        updateTitle={updateTitle}
        inputRef={inputRef}
        dragHandleProps={dragHandleProps}
        defaultTitle={column.name}
        hideActions={column.id === 'local-section' ? true : null}
        inputProps={{
          placeholder: 'New Heading',
          inputProps: { style: { padding: 2 } },
          name: 'sectionName',
        }}
        isHighlight={isHighlight}
        iconDatePickerProps={{
          useRange: true,
          defaultDate: {
            from: column.startDate
              ? parseISODate(column.startDate, column?.version)
              : null,
            to: column.endDate
              ? parseISODate(column.endDate, column?.version)
              : null,
          },
          placement: 'bottom-end',
        }}
        handleDelete={(e) => handleDeleteSection(e, column)}
        handleAdd={addNewSection}
        isDragging={isDragging}
        isSectionHighlight={isSectionHighlight}
        location={locationId}
        section={sectionId}
      />
    </div>
  );
}

export default TodoHeader;
