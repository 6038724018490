import { useSelector } from 'react-redux';
import { ChevronRightRounded } from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { getCurrencyFormatter } from '../Stays/HotelDetails/utils';
import { StripePayButton } from './CartSummary';
// import CartSummary from './CartSummary';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '36px',
    justifyContent: 'center',
    borderTop: '1px solid #e0e0e0',
    boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
    // TODO: Make this part of theme
    zIndex: 2222,
    [breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
}));

function CheckoutBottomPriceBar({
  isDisabledBtn,
  onNextClick = () => {},
  isStripeStep,
  isPaymentInfoPending,
}) {
  const classes = useStyles();
  const { slug: hotelKey } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const selectedPackageId = useSelector(
    (state) =>
      state.Bookings.cart?.packages?.length > 0 &&
      state.Bookings.cart?.packages[0]
  );

  const roomPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelKey]?.packages?.find(
      (p) => p?.packageId === selectedPackageId
    )
  );

  const room = roomPackage?.rooms[0];

  if (!selectedPackageId || !roomPackage || !room) return null;

  return (
    <div className={classes.root}>
      <div className={classes.controlledWidth} style={{ width: '100%' }}>
        <div
          style={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
              sx={{
                mr: isMobile ? 1 : 4,
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}>
              <Typography variant={isMobile ? 'h4' : 'h3'}>
                {roomPackage?.totalPrice?.currency &&
                  getCurrencyFormatter(
                    roomPackage?.totalPrice?.currency
                  ).format(roomPackage?.totalPrice?.price)}
              </Typography>
              <Typography variant="caption" color="#4E4E4E" ml={1}>
                Total Price incl. tax
              </Typography>
            </Box>
            <Typography
              variant="caption"
              sx={{ color: '#8A8A8A', textDecoration: 'underline', mt: 1 }}>
              See price breakdown
            </Typography>
          </div>
          {isStripeStep ? (
            <StripePayButton isPaymentInfoPending={isPaymentInfoPending}>
              Pay and confirm
            </StripePayButton>
          ) : (
            <Button
              disabled={isDisabledBtn}
              sx={{ ml: 'auto', padding: '4px 32px' }}
              onClick={onNextClick}>
              Next
              <ChevronRightRounded />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckoutBottomPriceBar;
