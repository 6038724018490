import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ChevronLeftRounded,
  BookmarkRounded,
  AddRounded,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import PriceNav from './PriceNav';
import HotelPageDatePicker from './HotelPageDatePicker';
import Logo from '../../../../../molecules/Logo';
import actions from '../../../../../../redux/actions';

const useStyles = makeStyles(({ breakpoints }) => ({
  appbar: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #DEDDDD',
    padding: '24px 60px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '6px 8px',
    },
    zIndex: 1098,
  },
  toolbar: {
    minHeight: '40px',
    [breakpoints.down('lg')]: {
      minHeight: '65px',
    },
    [breakpoints.down('sm')]: {
      minHeight: '80px',
    },
  },
  startContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  endContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  saveIcon: (saved) => ({
    color: saved ? '#ED702E' : 'transparent',
    marginRight: 4,
    '& > path': {
      stroke: saved ? '#ED702E' : '#4E4E4E',
      strokeWidth: '1',
    },
  }),
}));

function HotelNavbar({ saved, handleSaveClick, handleAddToTripButtonClick }) {
  const { slug: hotelKey } = useParams();
  const dispatch = useDispatch();
  const hotelDetails = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.data[hotelKey]
  );
  const { checkInDate, checkOutDate } = useSelector(
    (state) => state.Bookings?.hotelDetailsPage?.searchProps
  );

  const navRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isGreaterThanLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [navHeight, setNavHeight] = useState(64);
  const [scrolled, setScrolled] = useState(false);

  const prevTripId = window.localStorage.getItem('prevTripId');
  const returnUrl = window.localStorage.getItem('returnUrl');

  const navigate = useNavigate();
  const navigateBack = () => {
    if (prevTripId || returnUrl) {
      dispatch(
        actions.Bookings.initializeTripState({
          tripId: prevTripId,
          startDate: checkInDate,
          endDate: checkOutDate,
        })
      );
      navigate(returnUrl || `/trips/${prevTripId}/explore`);
    } else {
      navigate(`/trips`);
    }
  };

  const classes = useStyles(saved);

  useEffect(() => {
    setNavHeight(navRef?.current?.clientHeight);
  }, [navRef?.current]);

  // TODO: Edit this
  const hotel = {
    ...hotelDetails,
    price: hotelDetails?.lowestRate,
  };

  const detailsPage = document.getElementById('hotel-details-page');

  const handleScroll = () => {
    detailsPage?.addEventListener('scroll', () => {
      if (detailsPage.scrollTop > 200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });

    if (!detailsPage) setScrolled(false);

    return () => {
      detailsPage?.removeEventListener('scroll', () => {});
    };
  };

  useEffect(() => {
    handleScroll();
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        className={classes.appbar}
        elevation={0}
        id="hotel-details-navbar"
        ref={navRef}>
        <Toolbar className={classes.toolbar}>
          {scrolled ? (
            <div>
              <div className={classes.startContainer}>
                <IconButton onClick={navigateBack}>
                  <ChevronLeftRounded />
                </IconButton>
                <Typography
                  variant="h1"
                  textOverflow="ellipsis"
                  color="text.primary"
                  sx={{
                    marginLeft: '4px',
                    fontSize: isMobile ? '16px' : 'auto',
                  }}>
                  {hotel?.name}
                </Typography>
              </div>

              {!isGreaterThanLg && (
                <div style={{ marginLeft: '40px' }}>
                  <HotelPageDatePicker />
                </div>
              )}
            </div>
          ) : (
            <div style={{ marginLeft: '10px' }}>
              <Logo fullLogo imageWidth="100px" />
            </div>
          )}
          <div className={classes.endContainer}>
            {
              // calendar
            }
            {isGreaterThanLg && <HotelPageDatePicker />}
            {!isMobile && <PriceNav navHeight={navHeight} />}
            {isMobile && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <AddRounded
                  style={{
                    border: '1px #D9D9D9 solid',
                    borderRadius: '50%',
                    padding: '5px',
                    width: '35px',
                    height: '35px',
                    color: '#4E4E4E',
                  }}
                  onClick={handleAddToTripButtonClick}
                />

                <BookmarkRounded
                  className={classes.saveIcon}
                  onClick={handleSaveClick}
                  style={{
                    border: '1px #D9D9D9 solid',
                    borderRadius: '50%',
                    padding: '5px',
                    width: '35px',
                    height: '35px',
                  }}
                />
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          minHeight: navHeight,
        }}
      />
    </>
  );
}

export default HotelNavbar;
