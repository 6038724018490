import {
  EventNoteOutlined,
  GroupOutlined,
  HotelOutlined,
  LocationOnOutlined,
} from '@mui/icons-material';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  createBooking,
  getCheckoutSession,
} from '../../../redux/slices/Bookings';
import CheckoutSuccessModal from './CheckoutSuccessModal';
import { makeBookingRequest } from '../Stays/V2/api';
import { getInternalHotel } from '../Stays/HotelDetails/api';
import { updateCheckoutSessionStatus } from '../../../graphql/api';
import { CHECKOUT_SESSION_STATUS } from './constants';
import actions from '../../../redux/actions';
import { useMapUtils } from '../MapUtils';
import { createAccommodation } from '../../../redux/slices/Accommodation';
import { firebaseAuth } from '../../../provider/AuthProvider';
import { geoDistanceCheck } from '../../../utils';
import { createLocation } from '../../../redux/slices/Location';
import getPlaceFromPlacesAutocomplete from '../Stays/V2/api/utils';
import { EVENTS, phTrackEvent } from '../../../analytics';

function BookingCard({ booking }) {
  const dispatch = useDispatch();
  const { slug: tripId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBookingInfoClick = () => {
    dispatch(
      actions.Trips.setActiveBookingId({
        bookingId: booking?.id,
        tripId,
      })
    );
    navigate(`/trips/${tripId}/planner`);
  };
  return (
    <Box
      onClick={handleBookingInfoClick}
      key={booking.id}
      sx={{
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '0px',
        marginBottom: '16px',
        display: 'flex',
        alignItems: isMobile ? 'flex-start' : 'center',
        overflow: 'hidden',
        flexDirection: isMobile ? 'column' : 'row',
        maxHeight: isMobile ? 'auto' : 180,
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
        },
      }}>
      <img
        src={booking.image}
        alt={booking.hotelName}
        style={{
          width: isMobile ? '100%' : '350px',
          height: isMobile ? '180px' : '100%',
          marginRight: isMobile ? '0px' : '16px',
          objectFit: 'cover',
        }}
      />
      <div
        style={{
          flex: 1,
          alignItems: 'center',
          padding: '12px',
        }}>
        <Typography
          variant={isMobile ? 'h3' : 'h2'}
          sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {booking.hotelName}{' '}
          <Typography
            variant="caption"
            style={{
              color:
                booking?.status === 'CANCELED' ? 'error.main' : 'success.main',
              backgroundColor:
                booking?.status === 'CANCELED' ? '#FBD7D7' : '#D6FBD7',
              padding: 6,
              borderRadius: 4,
              marginLeft: isMobile ? 'auto' : 8,
            }}>
            {!booking?.status || booking?.status === 'SUCCESS'
              ? 'Booked'
              : booking?.status === 'CANCELED'
              ? 'Canceled'
              : 'Pending'}
          </Typography>
          {!isMobile && (
            <Typography
              variant="h3"
              sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
              Booking ID:{' '}
              <Typography variant="h5" ml={1} color="text.secondary">
                {booking.confirmationCode}
              </Typography>
            </Typography>
          )}
        </Typography>
        {isMobile && (
          <Typography
            mt={1}
            variant="h5"
            sx={{ display: 'flex', alignItems: 'center' }}>
            Booking ID:{' '}
            <Typography variant="h5" ml={1} color="text.secondary">
              {booking.confirmationCode}
            </Typography>
          </Typography>
        )}
        <Box
          display="flex"
          alignItems="center"
          mt={1}
          {...(isMobile && {
            flexDirection: 'column',
            alignItems: 'flex-start',
            mt: 2,
          })}>
          <Box display="flex" alignItems="center">
            <EventNoteOutlined sx={{ mr: 1 }} />
            <Typography
              variant={isMobile ? 'caption' : 'h5'}
              color="text.secondary">
              {format(new Date(booking.checkInDate), 'MMM dd') !==
              format(new Date(booking.checkOutDate), 'MMM dd')
                ? `${format(
                    new Date(booking.checkInDate),
                    'MMM dd'
                  )} - ${format(new Date(booking.checkOutDate), 'MMM dd')}`
                : `${format(
                    new Date(booking.checkInDate),
                    'MMM dd'
                  )} - ${format(new Date(booking.checkOutDate), 'dd')}`}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2}>
            <GroupOutlined sx={{ mr: 1 }} />
            <Typography
              variant={isMobile ? 'caption' : 'h5'}
              color="text.secondary">
              {booking?.guestsCount} guests
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" ml={isMobile ? 0 : 2}>
            <HotelOutlined sx={{ mr: 1 }} />
            <Typography
              variant={isMobile ? 'caption' : 'h5'}
              color="text.secondary">
              {booking?.roomsCount} rooms
            </Typography>
          </Box>
        </Box>
        {isMobile && (
          <Typography
            mt={1}
            variant="caption"
            display="flex"
            alignItems="center"
            ml="auto"
            color="text.secondary">
            <LocationOnOutlined sx={{ mr: 0.5 }} />
            {booking.address}
          </Typography>
        )}
        <Box display="flex" alignItems="center" mt={3}>
          <Box display="flex" sx={{ flexDirection: 'column' }}>
            <Typography variant="caption">Check-in</Typography>
            <Typography variant={isMobile ? 'h4' : 'h2'}>
              {booking.checkInDate}
            </Typography>
          </Box>
          <Box display="flex" ml={6} sx={{ flexDirection: 'column' }}>
            <Typography variant="caption">Check-out</Typography>
            <Typography variant={isMobile ? 'h4' : 'h2'}>
              {booking.checkOutDate}
            </Typography>
          </Box>
          {!isMobile && (
            <Typography
              variant="h5"
              display="flex"
              alignItems="center"
              ml="auto"
              color="text.secondary">
              <LocationOnOutlined sx={{ mr: 0.5 }} />
              {booking.address}
            </Typography>
          )}
        </Box>
      </div>
    </Box>
  );
}

function BookingList() {
  const { slug: tripId } = useParams();
  const bookings = useSelector((state) =>
    state.Trips.trips[tripId].bookings?.map((booking) => ({
      id: booking?.id,
      status: booking?.status,
      hotelName: booking?.bookingDetails?.name,
      confirmationCode: booking?.id,
      checkInDate: booking?.bookingDetails?.checkInDate
        ? format(
            new Date(booking?.bookingDetails?.checkInDate),
            'MMMM dd, yyyy'
          )
        : '-',
      address: booking?.bookingDetails?.address,
      checkOutDate: booking?.bookingDetails?.checkOutDate
        ? format(
            new Date(booking?.bookingDetails?.checkOutDate),
            'MMMM dd, yyyy'
          )
        : '-',
      guestsCount: booking?.bookingDetails?.users?.length || 2,
      roomsCount: booking?.bookingDetails?.roomsCount || 1,
      checkInTime: '14:00',
      checkOutTime: '12:00',
      image:
        booking?.bookingDetails?.image ||
        'https://images.unsplash.com/photo-1455587734955-081b22074882?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    }))
  );
  const mapPinsObj = useSelector((state) =>
    Object.values(state.Map.mapPins[tripId])
  );
  const tripDestinations = useSelector((state) =>
    Object.values(state.Location.locations).filter(
      (loc) => loc.tripID === tripId && loc.name !== ''
    )
  );

  // read query params
  const [queryParams, setQueryParams] = useSearchParams();
  const [successModal, setSuccessModal] = useState(false);
  const STATUS_CODES = {
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    TEST_MODE: 'TEST_MODE',
  };
  const [hotelData, setHotelData] = useState(null);
  const [status, setStatus] = useState(STATUS_CODES.PENDING);
  const dispatch = useDispatch();
  const { getPlaceDetails, createMapPinForPlace, createMapPinForPlaceId } =
    useMapUtils();
  const { user } = useContext(firebaseAuth);

  const createBookingInTrip = async ({
    name: hotelName,
    address: hotelAddress,
    state,
    city,
    country,
    latitude,
    longitude,
    bookingId,
    checkInDate,
    checkOutDate,
    address,
  }) => {
    const hotel = await getPlaceFromPlacesAutocomplete({
      latitude,
      longitude,
      queryString: `${hotelName}, ${hotelAddress}, ${country}`,
      constraintType: 'lodging',
    });
    if (hotel) {
      const hotelDetails = await getPlaceDetails(
        hotel?.placeId,
        'ACCOMMODATION',
        true
      );

      let parentLocation = tripDestinations?.find((loc) => {
        // check if any of the parents of hotel names match
        const isValid = hotelDetails?.addressComponents?.find((component) => {
          return loc?.name
            ?.toLowerCase()
            ?.includes(component?.long_name?.toLowerCase());
        });
        if (isValid) {
          return true;
        }

        // check if hotel names match static content
        if (
          [country, state, city]
            ?.map((item) => item?.toLowerCase())
            .includes(loc?.name?.toLowerCase())
        )
          return true;

        // check distance for each location, if within 25km
        if (
          geoDistanceCheck({
            coordinates: [
              {
                latitude: hotelDetails?.lat,
                longitude: hotelDetails?.lng,
              },
              {
                latitude: mapPinsObj[loc?.mapPin]?.lat,
                longitude: mapPinsObj[loc?.mapPin]?.lng,
              },
            ],
            threshold: 25,
          })
        )
          return true;

        return false;
      });

      if (!parentLocation) {
        // create a location if no location is found.
        const locName =
          city ||
          hotelDetails?.addressComponents?.find((component) =>
            component.types.includes('locality')
          )?.long_name;

        const locPlace = await getPlaceFromPlacesAutocomplete({
          queryString: `${locName}, ${country}`,
          constraintType: 'locality',
        });

        let locMapPinId = null;
        if (locPlace?.placeId) {
          locMapPinId = await createMapPinForPlaceId(
            locPlace?.placeId,
            'LOCATION'
          );
        }
        const location = await dispatch(
          createLocation({
            variables: {
              name: locName || country,
              tripID: tripId,
              index: 0,
              mapPin: locMapPinId,
            },
          })
        );
        parentLocation = location?.payload?.createLocation;
      }
      if (parentLocation) {
        const hotelMapPin = await createMapPinForPlace(
          {
            ...hotelDetails,
          },
          'ACCOMMODATION'
        );

        dispatch(
          createAccommodation({
            variables: {
              user: [{ user: user.uid, option: '' }],
              type: 1,
              trip: tripId,
              index: 0,
              name: hotelName,
              city,
              checkInDate,
              checkOutDate,
              streetAddress: address,
              mapPin: hotelMapPin?.id,
              locationId: parentLocation?.id,
              bookingId,
              isBooking: true,
            },
            extra: { locationId: parentLocation?.id },
          })
        );
      }
    }
  };

  const fetchCheckoutSessionAndMakeBooking = async (checkoutSessionId) => {
    try {
      setStatus(STATUS_CODES.PENDING);
      updateCheckoutSessionStatus(
        checkoutSessionId,
        CHECKOUT_SESSION_STATUS.PAYMENT_SUCCESS_BOOKING_PENDING
      );
      const checkoutSession = (
        await dispatch(
          getCheckoutSession({
            variables: { id: checkoutSessionId },
          })
        )
      )?.payload?.getCheckoutSession;

      const { content: hotel, photos } = await getInternalHotel({
        hotelId: checkoutSession?.checkoutData?.hotelId,
      });
      setHotelData({
        ...hotel,
        checkInDate: checkoutSession?.checkoutData?.checkInDate,
        checkOutDate: checkoutSession?.checkoutData?.checkOutDate,
        roomsCount: checkoutSession?.checkoutData?.roomsCount,
        guestsCount: checkoutSession?.checkoutData?.users?.length,
        hotelImage: photos?.[0],
      });

      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        return setStatus(STATUS_CODES.TEST_MODE);
      }

      const confirmedBooking = await makeBookingRequest({
        packageId: checkoutSession?.checkoutData?.packageId,
        hotelId: checkoutSession?.checkoutData?.hotelId,
        sessionId: checkoutSession?.checkoutData?.sessionId,
        users: checkoutSession?.checkoutData?.users,
        checkoutSessionId,
      });

      if (confirmedBooking && !confirmedBooking.error) {
        updateCheckoutSessionStatus(
          checkoutSessionId,
          CHECKOUT_SESSION_STATUS.BOOKING_CONFIRMED
        );
        // eslint-disable-next-line no-unused-vars
        const { __typename = null, ...cxlPolicy } =
          checkoutSession?.checkoutData?.cxlPolicy || {};

        const booking = await dispatch(
          createBooking({
            variables: {
              input: {
                packageId: checkoutSession?.checkoutData?.packageId,
                user: checkoutSession?.checkoutData?.users?.find(
                  (u) => u?.isLeadUser === true
                )?.id,
                tripId: checkoutSession?.tripId,
                reference: confirmedBooking?.result?.reference,
                orderId: confirmedBooking?.result?.order?.orderId?.toString(),
                segmentId:
                  confirmedBooking?.result?.order?.segmentId?.toString(),
                status: CHECKOUT_SESSION_STATUS.SUCCESS,
                bookingDetails: {
                  checkInDate: checkoutSession?.checkoutData?.checkInDate,
                  checkOutDate: checkoutSession?.checkoutData?.checkOutDate,
                  hotelId: checkoutSession?.checkoutData?.hotelId,
                  name: hotel?.name,
                  image: photos?.[0],
                  address: hotel?.address,
                  city: hotel?.city,
                  state: hotel?.state,
                  country: hotel?.countryName,
                  latitude: hotel?.lat?.toString(),
                  longitude: hotel?.lng?.toString(),
                  zipCode: hotel?.zipCode,
                  users: checkoutSession?.checkoutData?.users?.map(
                    ({ __typename: _, ...u }) => u
                  ),
                  rooms: checkoutSession?.checkoutData?.rooms?.map(
                    ({ __typename: _, ...room }) => room
                  ),
                  cxlPolicy: {
                    ...cxlPolicy,
                    policies: cxlPolicy?.policies?.map(
                      ({ __typename: _, ...policy }) => policy
                    ),
                  },
                  roomsCount: checkoutSession?.checkoutData?.roomsCount,
                  price: {
                    total: checkoutSession?.price?.total,
                    taxes: checkoutSession?.price?.taxes,
                    currency: checkoutSession?.price?.currency,
                  },
                },
              },
            },
          })
        );

        createBookingInTrip({
          name: hotel?.name,
          address: hotel?.address,
          state: hotel?.state,
          city: hotel?.city,
          country: hotel?.countryName,
          latitude: hotel?.lat,
          longitude: hotel?.lng,
          checkInDate: checkoutSession?.checkoutData?.checkInDate,
          checkOutDate: checkoutSession?.checkoutData?.checkOutDate,
          bookingId: booking?.payload?.createBooking,
        });

        updateCheckoutSessionStatus(
          checkoutSessionId,
          CHECKOUT_SESSION_STATUS.SUCCESS
        );
        setStatus(STATUS_CODES.SUCCESS);
        phTrackEvent({
          event: EVENTS.CHECKOUT.BOOKING_SUCCESS,
          meta: {
            hotelId: hotel?.id,
            hotelName: hotel?.name,
            checkInDate: checkoutSession?.checkoutData?.checkInDate,
            checkOutDate: checkoutSession?.checkoutData?.checkOutDate,
            bookingId: booking?.payload?.createBooking,
            orderId: confirmedBooking?.result?.order?.orderId?.toString(),
            segmentId: confirmedBooking?.result?.order?.segmentId?.toString(),
            price: checkoutSession?.price?.total,
            currency: checkoutSession?.price?.currency,
            bookingName: checkoutSession?.checkoutData?.users?.find(
              (u) => u?.isLeadUser === true
            )?.firstName,
          },
        });
        setTimeout(() => {
          setSuccessModal(false);
        }, 4000);
      } else {
        setStatus(STATUS_CODES.ERROR);
        phTrackEvent({
          event: EVENTS.CHECKOUT.BOOKING_FAILED,
          meta: {
            hotelId: hotel?.id,
            hotelName: hotel?.name,
            checkInDate: checkoutSession?.checkoutData?.checkInDate,
            checkOutDate: checkoutSession?.checkoutData?.checkOutDate,
            price: checkoutSession?.price?.total,
            currency: checkoutSession?.price?.currency,
            error: {
              type: 'EXTERNAL_ERROR',
              message: confirmedBooking?.error,
            },
            checkoutSessionId,
          },
        });
      }
    } catch (error) {
      setStatus(STATUS_CODES.ERROR);
      phTrackEvent({
        event: EVENTS.CHECKOUT.BOOKING_FAILED,
        meta: {
          checkoutSessionId,
          error: {
            type: 'INTERNAL_ERROR',
          },
        },
      });
    }
    setQueryParams({});
  };

  useEffect(() => {
    const checkoutSessionId = queryParams.get('checkout_session');
    const checkoutStatus = queryParams.get('checkout_status');

    if (checkoutSessionId && checkoutStatus === 'success') {
      setSuccessModal(true);
      fetchCheckoutSessionAndMakeBooking(checkoutSessionId);
    }
  }, [queryParams]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div style={{ padding: isMobile ? '4px 24px' : 0 }}>
      {bookings.length > 0 && (
        <Typography variant="h2Sub" sx={{ marginTop: isMobile ? 0 : 24 }}>
          Your Bookings
        </Typography>
      )}
      {successModal && (
        <CheckoutSuccessModal
          open={successModal}
          onClose={() => setSuccessModal(false)}
          loading={status === STATUS_CODES.PENDING}
          success={status === STATUS_CODES.SUCCESS}
          error={
            status === STATUS_CODES.ERROR || status === STATUS_CODES.TEST_MODE
          }
          isTestMode={status === STATUS_CODES.TEST_MODE}
          hotelData={hotelData}
        />
      )}
      {bookings.map((booking) => (
        <BookingCard booking={booking} />
      ))}
    </div>
  );
}

export default BookingList;
