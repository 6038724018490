import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeftRounded } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from '../../molecules/Logo';

const useStyles = makeStyles(({ breakpoints }) => ({
  appbar: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #DEDDDD',
    padding: '24px 60px',
    [breakpoints.down('lg')]: {
      padding: '18px 8px',
    },
    [breakpoints.down('sm')]: {
      padding: '6px 8px',
    },
    zIndex: 1098,
  },
  toolbar: {
    minHeight: '40px',
    [breakpoints.down('lg')]: {
      minHeight: '65px',
    },
    [breakpoints.down('sm')]: {
      minHeight: '80px',
    },
  },
  startContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  endContainer: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
}));

function CheckoutNavbar({ handleBackButtonClick = () => {} }) {
  const classes = useStyles();

  const { slug: hotelId } = useParams();

  const hotelName = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.data[hotelId]?.name
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appbar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <div>
            <div className={classes.startContainer}>
              <IconButton onClick={handleBackButtonClick}>
                <ChevronLeftRounded />
              </IconButton>
              <Typography
                variant="h1"
                textOverflow="ellipsis"
                color="text.primary"
                sx={{
                  marginLeft: '4px',
                }}>
                {hotelName}
              </Typography>
            </div>
          </div>

          <div className={classes.endContainer}>
            <div style={{ marginLeft: '10px' }}>
              <Logo fullLogo imageWidth="100px" />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <div
        style={{
          minHeight: 93,
        }}
      />
    </>
  );
}

export default CheckoutNavbar;
