import {
  BathtubRounded,
  PoolRounded,
  AirportShuttleRounded,
  SportsTennisRounded,
  GolfCourseRounded,
  BeachAccessRounded,
  LuggageRounded,
  NetworkWifiRounded,
  ElevatorRounded,
  ShowerRounded,
  TvRounded,
  KitchenRounded,
  MicrowaveRounded,
  BlenderRounded,
  LocalFireDepartmentRounded,
  SmokeFreeRounded,
  WheelchairPickupRounded,
  FreeBreakfastRounded,
  BrunchDiningRounded,
  LocalBarRounded,
} from '@mui/icons-material';
import FEATURES from '../../../../assets/staysFeatures.json';

const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export const getNumberOfNights = (fromDate, toDate) => {
  try {
    const timeDiff = toDate.getTime() - fromDate.getTime();
    return Math.ceil(timeDiff / ONE_DAY_MS);
  } catch (err) {
    // error
  }
  return 1;
};

/*
    Function to get rating copies depending on rating.
*/
export const getRatingProps = (rating) => {
  if (rating > 9) return ['Excellent', '#43A047'];
  if (rating > 7) return ['Good', '#43A047'];
  if (rating > 5) return ['Average', 'yellow'];
  return ['Bad', '#E5493D'];
};

export const getCurrencyFormatter = (currencyCode) =>
  currencyCode &&
  new Intl.NumberFormat(undefined, {
    currency: currencyCode,
    style: 'currency',
  });

export const getFormattedNightlyPrice = (price, numberOfNights) => {
  if (price < 10) {
    return (price / numberOfNights).toFixed(2);
  }
  return Math.ceil(Math.ceil(price) / numberOfNights)?.toFixed(0);
};

export const ROOM_AMENITY_MAP = {
  'bed and breakfast': {
    text: 'Breakfast included',
    icon: FreeBreakfastRounded,
  },
  'full board': {
    text: 'All meals included',
    icon: BrunchDiningRounded,
  },
  'half board': {
    text: 'Breakfast & Dinner included',
    icon: BrunchDiningRounded,
  },
  wheelchair: {
    text: 'Wheelchair accessible',
    icon: WheelchairPickupRounded,
  },
  kitchen: {
    text: 'Kitchen',
    icon: BlenderRounded,
  },
  nonSmoking: {
    text: 'Non-smoking',
    icon: SmokeFreeRounded,
  },
  minibar: {
    text: 'Minibar',
    icon: LocalBarRounded,
  },
  refrigerator: {
    text: 'Refrigerator',
    icon: KitchenRounded,
  },
  tv: {
    text: 'TV',
    icon: TvRounded,
  },
  microwave: {
    text: 'Microwave',
    icon: MicrowaveRounded,
  },
  heat: {
    text: 'Heater',
    icon: LocalFireDepartmentRounded,
  },
  shower: {
    text: 'Shower',
    icon: ShowerRounded,
  },
  wifi: {
    text: 'Wifi',
    icon: NetworkWifiRounded,
  },
};

export const getSortedAmenities = (amenities) => {
  const roomAmenities = [];
  const parsedAmenities = [];
  amenities?.forEach((amenity) => {
    Object.keys(ROOM_AMENITY_MAP).forEach((key) => {
      if (
        amenity?.toLowerCase()?.includes(key) &&
        !parsedAmenities?.includes(key)
      ) {
        roomAmenities.push({ ...ROOM_AMENITY_MAP[key], key });
        parsedAmenities.push(key);
      }
    });
  });

  roomAmenities?.sort(
    (a, b) =>
      Object.keys(ROOM_AMENITY_MAP).indexOf(a.key) -
      Object.keys(ROOM_AMENITY_MAP).indexOf(b.key)
  );
  return roomAmenities;
};

export const computeAmenitiesV2 = (facilities) => {
  const topFeatures = [
    {
      ids: ['Wifi', 'Internet access'],
      text: 'Internet',
      Icon: NetworkWifiRounded,
    },
    { ids: ['Pool'], text: 'Pool', Icon: PoolRounded },
    { ids: ['Lifts'], text: 'Lifts', Icon: ElevatorRounded },
    {
      ids: ['Airport shuttle'],
      text: 'Shuttle service',
      Icon: AirportShuttleRounded,
    },
    {
      ids: ['Luggage storage'],
      text: 'Luggage storage',
      Icon: LuggageRounded,
    },
    {
      ids: ['Bath'],
      text: 'Bath',
      Icon: BathtubRounded,
    },
    {
      ids: [50],
      text: 'Golf course',
      Icon: GolfCourseRounded,
    },
    {
      ids: [395, 17],
      text: 'Beachfront',
      Icon: BeachAccessRounded,
    },
  ]?.filter(
    (f) =>
      f.ids?.map((fid) => facilities?.includes(fid))?.filter(Boolean)?.length >
      0
  );

  return {
    topFeatures,
    perksFeatures: facilities?.map((f) => f?.Facility),
  };
};

export const computeAmenities = (features) => {
  const topFeatures = [
    { ids: [54], text: 'Hot tub', Icon: BathtubRounded },
    { ids: [371], text: 'Pool', Icon: PoolRounded },
    {
      ids: [894],
      text: 'Shuttle service',
      Icon: AirportShuttleRounded,
    },
    {
      ids: [84],
      text: 'Tennis',
      Icon: SportsTennisRounded,
    },
    {
      ids: [50],
      text: 'Golf course',
      Icon: GolfCourseRounded,
    },
    {
      ids: [395, 17],
      text: 'Beachfront',
      Icon: BeachAccessRounded,
    },
  ]?.filter(
    (f) =>
      f.ids?.map((fid) => features?.includes(fid))?.filter(Boolean)?.length > 0
  );
  const perksFeatures = [
    388, 778, 361, 723, 489, 541, 335, 537, 11, 72, 36, 57, 58, 73,
  ]
    ?.filter((f) => features?.includes(f))
    ?.map((f) => ({
      id: f,
      text: FEATURES[f],
    }));

  return {
    topFeatures,
    perksFeatures,
  };
};

/*
  Function to map different inclusions available with a hotel deal, and map them to our custom copies
  returns a list of inclusion copies to display
*/
export const computeInclusions = ({
  inclusions,
  features,
  canPayLater,
  hasFreeCancellation,
}) => {
  const inclusionCopies = {
    hasFreeCancellation: 'Free cancellation',
    canPayLater: 'Pay Later',
    0: 'Breakfast included',
    1: 'Lunch included',
    2: 'Dinner included',
    3: 'Meals included',
    4: 'All inclusive',
    freeInternet: 'Free Internet',
  };

  return [
    hasFreeCancellation && inclusionCopies.hasFreeCancellation,
    (features?.includes(361) || features?.includes(388)) &&
      inclusionCopies.freeInternet,
    ...(inclusions?.map((inclusion) => inclusionCopies[inclusion]) || []),
    canPayLater && inclusionCopies.canPayLater,
  ]?.filter(Boolean);
};
