import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
// import { LoginRounded, LogoutRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

// eslint-disable-next-line
const useStyles = makeStyles(({ breakpoints }) => ({
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

function HouseRules() {
  const classes = useStyles();
  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);
  const cxlPolicy = useSelector(
    (state) => state.Bookings.cart.cxlPolicy[packageId]
  );

  function htmlDecode(input) {
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  const formattedHouseRules = useMemo(
    () => htmlDecode(cxlPolicy?.summary),
    [cxlPolicy]
  );

  return (
    formattedHouseRules && (
      <Box
        className={classes.box}
        sx={{
          p: 3,
          width: '100%',
          display: cxlPolicy?.summary ? 'auto' : 'none',
        }}>
        <Typography variant="h2" mt={2} mb={2}>
          Policy and terms
        </Typography>
        <Typography
          variant="h5"
          mb={1}
          dangerouslySetInnerHTML={{ __html: formattedHouseRules }}
        />
      </Box>
    )
  );
}

export default HouseRules;
