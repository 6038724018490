import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authMethods from '../firebase/AuthMethods';
import actions from '../redux/actions';
import { updateUser } from '../redux/slices/Auth';

export const firebaseAuth = createContext();

function AuthProvider(props) {
  // delete modal
  const [toSignup, setToSignup] = useState(false);

  const user = useSelector((state) => state.Auth.firebaseUser);
  const errors = useSelector((state) => state.Auth.error);
  const dispatch = useDispatch();
  const handleError = (error) => {
    dispatch(actions.Auth.setError(error));
  };

  // resets the error message as we move across pages.
  const location = useLocation();
  useEffect(() => {
    if (errors !== '') handleError('');
  }, [location]);

  // TODO: find a better way to wrap this
  const setLoading = (loaded) => {
    dispatch(actions.Auth.setAuthLoading(loaded));
  };

  const handleSignup = async ({ email, password, firstName, lastName }) => {
    setLoading(true);
    await authMethods.signup(email, password, firstName, lastName, handleError);
    setLoading(false);
  };

  const handleSignin = async ({ email, password }) => {
    setLoading(true);
    await authMethods.signin(email, password, handleError);
    setLoading(false);
  };

  const handleFacebookSignin = async () => {
    setLoading(true);
    await authMethods.providerSignin(handleError, 'Facebook');
    setLoading(false);
  };

  const handleGoogleSignin = async () => {
    setLoading(true);
    await authMethods.providerSignin(handleError, 'Google');
    setLoading(false);
  };

  const handleAppleSignin = async () => {
    setLoading(true);
    await authMethods.providerSignin(handleError, 'Apple');
    setLoading(false);
  };

  const handleSignout = (accountDeleted = false) => {
    setToSignup(accountDeleted);
    authMethods.signout(handleError);
  };

  const handlePasswordRecovery = (values) => {
    authMethods.passwordRecovery(values.email, handleError);
  };

  const handleAddEmail = async (email) => {
    setLoading(true);
    try {
      await authMethods.addEmail(email, handleError);
      dispatch(
        updateUser({
          variables: {
            id: user.uid,
            email,
          },
        })
      );
      dispatch(actions.Auth.setFirebaseUser({ ...user, email }));
    } catch (error) {
      handleError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const sendVerificationEmail = () => {
    authMethods.sendVerificationEmail(user.email);
  };

  const fetchSignInMethodsForEmail = async (email) => {
    return authMethods.fetchSignInMethodsForEmail(email);
  };

  const sendSignInLinkToEmail = async (email) => {
    authMethods.sendSignInLinkToEmail(email, handleError);
  };

  const isSignInWithEmailLink = async () => {
    const val = authMethods.isSignInWithEmailLinkLocal();
    return val;
  };

  const handleSignInWithEmailLink = async (
    { email, password, firstName, lastName },
    signInSession
  ) => {
    setLoading(true);
    authMethods.handleSignInWithEmailLink(
      email,
      password,
      firstName,
      lastName,
      signInSession,
      handleError
    );
    setLoading(false);
  };

  const confirmPasswordReset = async (oobCode, newPassword) => {
    authMethods.confirmPasswordReset(oobCode, newPassword, handleError);
  };

  const handleTokenSignIn = async (token, isNewUser) =>
    authMethods.signInWithToken(token, handleError, isNewUser);

  return (
    <firebaseAuth.Provider
      value={{
        handleSignup,
        handleSignin,
        handleSignout,
        handlePasswordRecovery,
        handleFacebookSignin,
        handleGoogleSignin,
        handleAddEmail,
        sendVerificationEmail,
        toSignup,
        setToSignup,
        user,
        fetchSignInMethodsForEmail,
        sendSignInLinkToEmail,
        isSignInWithEmailLink,
        confirmPasswordReset,
        handleSignInWithEmailLink,
        handleTokenSignIn,
        handleAppleSignin,
      }}>
      {props.children}
    </firebaseAuth.Provider>
  );
}

export default AuthProvider;
