export function Highlight(props) {
  return (
    <svg
      width="437"
      height="95"
      viewBox="0 0 437 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M9.5 31.5L0 20.5L437 0L423.5 4.5L437 14L429.5 20.5L437 31.5L423.5 43.5L437 57.5L429.5 62L437 74.5L2.5 95L14.5 81L2.5 71L9.5 62L0 43.5L9.5 31.5Z"
        fill="#FFF1E0"
      />
    </svg>
  );
}

export function Tape() {
  return (
    <svg
      style={{
        position: 'absolute',
        top: -20,
        right: 50,
      }}
      width="244"
      height="57"
      viewBox="0 0 244 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_9409_4215)">
        <path
          d="M8.86521 11.1802L4.29764 4.90707L237.712 13.344L230.33 15.1407L237.092 20.7927L232.814 23.9191L236.318 30.1037L228.604 35.8907L235.167 43.937L230.977 45.9993L234.414 52.9819L2.32999 44.6556L9.33433 37.7381L3.39236 31.8864L7.51512 27.4078L3.27953 17.1443L8.86521 11.1802Z"
          fill="#FFF1E0"
          fillOpacity="0.4"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9409_4215"
          x="0.330078"
          y="0.907227"
          width="243.382"
          height="56.0742"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9409_4215"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9409_4215"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
export function ManMapin(props) {
  return (
    <svg
      {...props}
      width="54"
      height="62"
      viewBox="0 0 54 62"
      fill="none"
      style={{
        position: 'absolute',
        bottom: 15,
        left: '45%',
      }}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.8408 32.5642C42.455 40.5056 26.2216 50.2926 19.2597 54.2182C17.5941 55.1509 15.6053 54.303 15.1251 52.4554C13.0589 44.6807 8.95951 26.2249 12.3453 18.2835C16.4532 8.64842 27.2813 4.0347 36.531 7.97827C45.7771 11.9204 49.9487 22.9292 45.8408 32.5642Z"
        fill="#8AD6B0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.9096 20.7368C33.8974 21.1579 35.0501 20.6943 35.4712 19.7066C35.8923 18.7188 35.4287 17.5661 34.441 17.145C33.4532 16.7239 32.3006 17.1875 31.8794 18.1752C31.4583 19.163 31.9219 20.3157 32.9096 20.7368ZM25.4678 30.2981L26.9367 28.2715L28.0567 30.8713L26.1425 35.361C25.932 35.8548 26.1638 36.4312 26.6576 36.6417C27.1515 36.8523 27.7278 36.6205 27.9384 36.1266L30.0976 31.0623C30.3081 30.5684 30.3097 30.0173 30.096 29.5229L29.3068 27.6903L30.9941 25.2261C31.4801 26.7492 32.5312 28.1418 33.9864 29.0593C34.4907 29.3698 35.1594 29.1456 35.3929 28.5978C35.5805 28.1578 35.4142 27.6519 35.0048 27.3924C33.7357 26.586 32.9488 25.2955 32.799 23.9795L32.5136 22.1599C32.3841 21.468 31.9985 20.8792 31.3699 20.6112C31.1005 20.4963 30.8827 20.5096 30.6133 20.3947L26.3963 20.3796C25.6094 20.3836 24.9027 20.8464 24.5926 21.5737L23.6853 23.7018C23.4748 24.1957 23.7065 24.772 24.2004 24.9826C24.6943 25.1931 25.2706 24.9613 25.4812 24.4675L26.4 22.3124L28.2842 22.373L23.7466 29.0337L20.533 26.8147C20.0902 26.5092 19.4803 26.6205 19.1748 27.0633L19.1595 27.0992C18.854 27.542 18.9654 28.1519 19.4081 28.4574L22.7847 30.7671C23.6561 31.3615 24.8465 31.158 25.4678 30.2981Z"
        fill="white"
      />
    </svg>
  );
}
