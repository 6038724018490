import { createPortal } from 'react-dom';

function PortalAwareDraggableItem({ snapshot, children }) {
  const usePoral = snapshot?.isDragging;
  if (!usePoral) {
    return children;
  }
  return createPortal(children, document.body);
}

export default PortalAwareDraggableItem;
