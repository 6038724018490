/* eslint-disable */
import { useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getNumberOfNights } from '../Stays/bookingsUtils';
import { createCheckoutSession } from '../../../redux/slices/Bookings';
import { getBookingParams } from '../Stays/V2/api';
import getCfConnector from '../../cfConnector';
import config from '../../config';
import { getCurrencyFormatter } from '../Stays/HotelDetails/utils';
import { CHECKOUT_SESSION_STATUS } from './constants';
import { EVENTS, phTrackEvent } from '../../../analytics';

const useStyles = makeStyles(() => ({
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
  },
}));

export function StripePayButton({ children, isPaymentInfoPending }) {
  const user = useSelector((state) => state.Auth.userData?.user);
  const userId = user?.id;

  const tripId = window.localStorage.getItem('prevTripId');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { slug: hotelId } = useParams();
  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);
  const hotel = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.data[hotelId]
  );
  const selectedPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
      (p) => p.packageId === packageId
    )
  );
  const rooms = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.data[hotelId]?.rooms
  );
  const searchProps = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps
  );
  const cxlPolicy = useSelector((state) => state.Bookings.cart.cxlPolicy);

  const packagePrice = selectedPackage?.totalPrice;
  const guests = useSelector((state) => state.Bookings.cart.guests);

  const handlePayAndBook = async () => {
    if (!stripe || !elements) return;
    phTrackEvent({
      event: EVENTS.CHECKOUT_PAYMENT_INFO.NEXT_SUBMIT,
    });
    await elements.submit();
    setLoading(true);
    const currency = packagePrice?.currency?.toLowerCase();

    const checkoutSessionId = (
      await dispatch(
        createCheckoutSession({
          variables: {
            input: {
              price: {
                total: packagePrice.price,
                taxes: packagePrice.taxes,
                currency: packagePrice.currency,
              },
              status: CHECKOUT_SESSION_STATUS.PAYMENT_PENDING,
              initatedBy: userId,
              tripId,
              checkoutData: {
                ...getBookingParams({
                  packageId,
                  hotelId,
                  guests: Object.values(guests),
                  rooms: searchProps.rooms,
                  packageRooms: selectedPackage?.rooms?.map((r) => ({
                    roomId: r.id,
                    childrenAges: r?.guests?.children,
                    adultsCount: r?.guests?.adults,
                  })),
                }),
                supplierId: selectedPackage?.supplierId,
                hotelImage:
                  hotel?.additional?.photos?.length > 0
                    ? hotel?.additional?.photos[0]
                    : null,
                checkInDate: new Date(searchProps?.checkInDate),
                checkOutDate: new Date(searchProps?.checkOutDate),
                rooms: selectedPackage?.rooms?.map((r) => ({
                  roomId: r?.id,
                  type: r?.type,
                  name: r?.name,
                  bed: r?.bed,
                  basis: r?.basis,
                  price: r?.price?.price,
                  currency: r?.price?.currency,
                  amenities: rooms[r.key]?.amenities,
                  images: rooms[r.key]?.images,
                })),
                cxlPolicy: {
                  isRefundable: selectedPackage?.isRefundable,
                  refundableUntil: selectedPackage?.refundableUntil,
                  policies: cxlPolicy?.cxlPolicies?.map((c) => ({
                    fromDate: c?.fromDate,
                    toDate: c?.toDate,
                    cancellationFee: c?.amount,
                    currency: c?.currency,
                  })),
                  remarks: cxlPolicy?.summary,
                },
              },
            },
          },
        })
      )
    )?.payload?.createCheckoutSession;
    const clientSecret = (
      await (
        await getCfConnector()
      ).post(`${config.paymentsAPI}/create-payment-intent`, {
        amount: Math.round(
          (packagePrice.price + (packagePrice.taxes || 0)) * 100
        ),
        currency,
        userId,
        userEmail: user?.email,
        metadata: {
          tripId,
          checkoutSessionId,
        },
      })
    ).data?.clientSecret;
    if (checkoutSessionId) {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        window.location = `${window.location.origin}/trips/${tripId}/files?checkout_session=${checkoutSessionId}&checkout_status=success`;
        return null;
      }
      const { error = false } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/trips/${tripId}/files?checkout_session=${checkoutSessionId}&checkout_status=success`,
        },
      });
      if (error) {
        console.error(error);
        phTrackEvent({
          event: EVENTS.CHECKOUT_PAYMENT_INFO.PAYMENT_ERROR,
        });
      }
    }

    setLoading(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      fullWidth
      sx={{ mt: isMobile ? 0 : 3 }}
      onClick={handlePayAndBook}
      disabled={loading || isPaymentInfoPending}>
      {loading ? (
        <CircularProgress size={24} sx={{ color: '#FFF' }} />
      ) : (
        children
      )}
    </Button>
  );
}

function CartSummary({
  onNextClick = () => {},
  btnCopy = 'Next',
  disabledBtn,
  isStripeStep,
  isPaymentInfoPending,
}) {
  const classes = useStyles();
  const { slug: hotelId } = useParams();
  const packageId = useSelector((state) => state.Bookings.cart.packages[0]);

  const selectedPackage = useSelector((state) =>
    state.Bookings.hotelDetailsPage?.data[hotelId]?.packages?.find(
      (p) => p.packageId === packageId
    )
  );
  const searchProps = useSelector(
    (state) => state.Bookings.hotelDetailsPage?.searchProps
  );
  const numberOfNights = getNumberOfNights({
    from: new Date(searchProps?.checkInDate),
    to: new Date(searchProps?.checkOutDate),
  });
  const room = selectedPackage?.rooms[0];
  const packagePrice = selectedPackage?.totalPrice;

  return (
    <Box className={classes.box} sx={{ p: 3, width: '100%' }}>
      <Typography variant="h3" mb={1.5} fontSize={18}>
        Price Breakdown
      </Typography>
      <Typography variant="caption" sx={{ color: 'text.light' }}>
        {selectedPackage?.rooms?.length} x {room?.name}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">
          {getCurrencyFormatter(packagePrice?.currency).format(
            (packagePrice.price / numberOfNights).toFixed(2)
          )}{' '}
          x {numberOfNights} nights
        </Typography>
        <Typography variant="h4">
          {getCurrencyFormatter(packagePrice?.currency).format(
            packagePrice.price.toFixed(2)
          )}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Typography variant="h4">Taxes</Typography>
        <Typography variant="h4">
          {getCurrencyFormatter(packagePrice?.currency).format(
            (packagePrice.taxes || 0).toFixed(2)
          )}
        </Typography>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h2" fontWeight="bold">
          Total
        </Typography>
        <Typography variant="h2" fontWeight="bold">
          {getCurrencyFormatter(packagePrice?.currency).format(
            (packagePrice.price + (packagePrice.taxes || 0)).toFixed(2)
          )}
        </Typography>
      </Box>
      {isStripeStep ? (
        <StripePayButton isPaymentInfoPending={isPaymentInfoPending}>
          {btnCopy}
        </StripePayButton>
      ) : (
        <Button
          fullWidth
          sx={{
            mt: 4,
            '&:disabled': {
              backgroundColor: '#FFF',
              border: '1px solid #D9D9D9',
            },
          }}
          disabled={disabledBtn}
          onClick={onNextClick}>
          {btnCopy}
        </Button>
      )}
    </Box>
  );
}

export default CartSummary;
