/* eslint-disable */
import getCfConnector from '../../../../cfConnector';

const getPlaceFromPlacesAutocomplete = async ({
  latitude,
  longitude,
  queryString,
  constraintType = 'lodging',
}) => {
  try {
    const response = await (
      await getCfConnector()
    ).get(process.env.REACT_APP_PLACES_API, {
      params: {
        query: queryString,
        latitude,
        longitude,
        constraintType,
      },
    });
    return response?.data?.result || null;
  } catch (err) {
    // handle error
  }
  return null;
};

export default getPlaceFromPlacesAutocomplete;
