import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  InputBase,
  IconButton,
  Typography,
  Popper,
  Box,
  ClickAwayListener,
  Autocomplete,
  Checkbox,
  Chip,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import {
  SearchRounded,
  CloseRounded,
  MoreHorizRounded,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  AttachFile,
  Close,
} from '@mui/icons-material';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { OutlinedButton, ButtonDefault } from '../atoms/Button/index';
import { getFileIcon, handleFileDelete } from '../../utils';
import { UploadIcon } from '../atoms/Icon';
import { updateTrip } from '../../redux/slices/Trips';
import actions from '../../redux/actions';
import UserAvatar from '../molecules/UserAvatar';
import OptionsMenu from '../molecules/Menu/OptionsMenu';

import {
  FileUploadModal,
  FilePreviewModal,
  DeleteModal,
} from '../molecules/Modal';
import { attachFiletoItems, updateFile } from '../../redux/slices/Files';
import { updateActivity } from '../../redux/slices/Activity';
import { updateAccommodation } from '../../redux/slices/Accommodation';
import { updateFlight } from '../../redux/slices/Transportation';
import { EVENTS, phTrackEvent } from '../../analytics';
import BookingList from './Checkout/BookingList';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  // searchbar
  searchBar: {
    maxWidth: 240,
    backgroundColor: '#F4F4F4',
    borderRadius: 6,
    padding: 7,
    marginRight: 10,
    border: '1px solid transparent',
    '&:hover': {
      boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    },
  },
  searchbarFocused: {
    backgroundColor: '#FFF',
    boxShadow: `0px 0px 0px 2px ${palette.primary.extraLight}`,
    border: `1px solid ${palette.primary.light}`,
  },
  closeButton: {
    color: '#000',
    padding: 2,
    marginRight: 4,
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  // files tab
  root: {
    marginTop: '40px',
    padding: '0px 14vw',
    [breakpoints.down('lg')]: {
      padding: '0px 10vw',
    },
    [breakpoints.down('md')]: {
      padding: '0px 5vw',
    },
    [breakpoints.down('sm')]: {
      padding: '0',
    },
  },
  container: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      padding: '0 10px',
    },
  },
}));

function FileSearchbar({ handleSubmit = () => {} }) {
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputBase
      disableUnderline
      className={{
        [classes.searchBar]: true,
        [classes.searchbarFocused]: isFocused,
      }}
      placeholder="Search for files"
      value={searchString}
      onChange={(e) => {
        setSearchString(e.target.value);
        handleSubmit(e.target.value);
      }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleSubmit(searchString);
      }}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      inputProps={{
        style: {
          padding: 0,
        },
      }}
      startAdornment={
        <SearchRounded
          style={{
            color: '#8A8A8A',
            width: 16,
            height: 16,
            marginRight: 8,
            marginLeft: 4,
          }}
        />
      }
      endAdornment={
        searchString !== '' && isFocused ? (
          <IconButton
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setSearchString('');
              handleSubmit('');
            }}
            className={classes.closeButton}
            size="large">
            <CloseRounded
              style={{
                color: '#000',
                width: 14,
                height: 14,
              }}
            />
          </IconButton>
        ) : null
      }
    />
  );
}

const attachmentPopperStyles = makeStyles((theme) => ({
  attachPopper: {
    borderRadius: 4,
    width: '28.5vw',
    backgroundColor: '#FFF',
  },
  attachmentOption: {
    padding: '2px 0',
    borderBottom: '1px solid #DEDDDD',
    cursor: 'pointer',
    '&:hover': {
      background: '#F4F4F4',
    },
  },
  AutoCompleteListbox: {
    background: '#FFFFFF',
    boxShadow: 'none',
    padding: 0,
  },
  searchInput: {
    boxShadow: '0 0 0 1px #7E7E7E',
    padding: '4px 14px',
    background: '#F4F4F4',
    borderRadius: 4,
  },
  AutoCompleteListboxPopper: {
    padding: 14,
    boxShadow: '0 0 0 1px #7E7E7E',
    borderRadius: 4,
    width: '100%',
    minWidth: '200px',
  },
  sectionLabel: {
    fontSize: '12px',
    color: '#4E4E4E',
    fontWeight: 'bold',
    borderBottom: '1px solid #DEDDDD',
    paddingBottom: '8px',
    padding: '8px 0',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  sectionUl: {
    marginBottom: '8px',
  },
  attachItemsButton: {
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 4,
  },
  chip: {
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    fontSize: 12,
    padding: '13px 4px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
    },
  },
  attachButton: {
    marginTop: 8,
  },
  emptyAttachmentText: {
    color: 'transparent',
    '&:hover': {
      color: '#BCBCBC',
    },
    padding: '8px 4px',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

function ItemAttachment({ fileId, tripId, tripFiles }) {
  const classes = attachmentPopperStyles();
  const [anchorElm, setAnchorEl] = useState(null);
  const [openPopper, setOpen] = useState(false);
  const id = openPopper ? 'attachment-popper' : undefined;
  const dispatch = useDispatch();

  const tripItems = useSelector((state) => state.Trips.trips[tripId].items);
  const activities = useSelector((state) => state.Activity.todos);
  const accommodation = useSelector((state) => state.Accommodation.hotels);
  const locations = useSelector((state) => state.Location.locations);
  const sections = useSelector((state) => state.Section.sections);
  const transportation = useSelector(
    (state) => state.Transportation.transports
  );
  const fileRelations = useSelector(
    (state) => state.Files.fileRelations[tripId]
  );

  const getName = (type, elemId) => {
    if (type === 'Activity') {
      return activities[elemId].title;
    }
    if (type === 'Accommodation') {
      return accommodation[elemId].name;
    }
    if (type === 'Transportation') {
      const transportObj = Object.values(transportation).find((transport) =>
        transport?.details?.map((detail) => detail.id)?.includes(elemId)
      );
      return transportObj?.details?.find((detail) => detail.id === elemId)
        ?.flightNumber;
    }
    return '';
  };

  const attachedItems = fileRelations
    ?.filter(
      (relation) =>
        relation.fileId === fileId && relation.attachedToType !== 'Trip'
    )
    .map((relation) => {
      return {
        id: relation.attachedToId,
        title: getName(relation.attachedToType, relation.attachedToId),
        type: relation.attachedToType,
      };
    });

  const tripItemsList = useMemo(() => {
    const activitesList = [];
    const accommodationList = [];
    const transportationList = [];

    tripItems?.forEach((item) => {
      if (item?.transportation) {
        transportation[item?.transportation]?.details?.forEach((detail) => {
          transportationList.push({
            id: detail.id,
            title: detail.flightNumber,
            type: 'Transportation',
          });
        });
      }
      if (item?.location) {
        locations[item.location]?.hotels?.forEach((hotel) => {
          accommodationList.push({
            id: hotel,
            title: accommodation[hotel].name,
            type: 'Accommodation',
          });
        });
        locations[item.location]?.thingsToDo?.forEach((section) => {
          sections[section]?.todos.forEach((todo) => {
            if (!todo?.includes('local-todo')) {
              activitesList.push({
                id: todo,
                title: activities[todo].title,
                type: 'Activity',
              });
            }
          });
        });
      }
    });

    return [...transportationList, ...accommodationList, ...activitesList];
  }, [
    tripItems,
    locations,
    sections,
    accommodation,
    activities,
    transportation,
  ]);

  const [val, setValue] = useState([...attachedItems]);

  const arrCompare = (arr1, arr2) => {
    let equal = false;
    if (arr1.length === arr2.length) {
      equal = true;
      arr1.forEach((element) => {
        if (!arr2.includes(element)) equal = false;
      });
    }
    return equal;
  };

  const itemsArr = val?.map((item) => item.id);
  const attachedItemsArr = attachedItems?.map((item) => item.id);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    if (anchorElm) {
      anchorElm.focus();
    }
    setOpen(false);
    setAnchorEl(null);
  };

  const handleAttach = async () => {
    if (!arrCompare(itemsArr, attachedItemsArr)) {
      const attachmentItems = val
        ?.filter((item) => !attachedItemsArr.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            type: item.type,
          };
        });
      const detachedItems = attachedItems
        ?.filter((item) => !itemsArr.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            type: item.type,
          };
        });
      const toBeAdded = !fileRelations?.some(
        (relation) =>
          relation.fileId === fileId &&
          !detachedItems?.map((item) => item.id).includes(relation.attachedToId)
      );

      await new Promise(() => {
        dispatch(
          attachFiletoItems({
            variables: {
              fileId,
              tripId,
              items: attachmentItems,
              itemsToRemove: detachedItems,
              addToTrip: toBeAdded,
            },
          })
        ).then(() => {
          if (toBeAdded) {
            dispatch(actions.Trips.addTripFile({ tripId, fileId }));
          }
          const getBladeItems = (items, type) => {
            return items?.filter((item) => item.type === type);
          };
          dispatch(
            actions.Activity.handleActivityFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Activity'),
              itemsToDetach: getBladeItems(detachedItems, 'Activity'),
            })
          );
          dispatch(
            actions.Accommodation.handleAccommodationFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Accommodation'),
              itemsToDetach: getBladeItems(detachedItems, 'Accommodation'),
            })
          );
          dispatch(
            actions.Transportation.handleTransportationFiles({
              fileId,
              itemsToAttach: getBladeItems(attachmentItems, 'Transportation'),
              itemsToDetach: getBladeItems(detachedItems, 'Transportation'),
            })
          );
          handleClose();
        });
      });
    }
  };

  const handleDelete = async (itemId, type) => {
    await new Promise(() => {
      (() => {
        if (type === 'Activity') {
          return dispatch(
            updateActivity({
              variables: {
                id: itemId,
                files: activities[itemId]?.files?.filter(
                  (file) => file !== fileId
                ),
              },
              attachedToType: 'Activity',
              tripId,
            })
          );
        }
        if (type === 'Accommodation') {
          return dispatch(
            updateAccommodation({
              variables: {
                id: itemId,
                files: accommodation[itemId]?.files?.filter(
                  (file) => file !== fileId
                ),
              },
              attachedToType: 'Accommodation',
              tripId,
            })
          );
        }
        if (type === 'Transportation') {
          const transportId = Object.values(transportation).find((transport) =>
            transport?.details?.map((detail) => detail.id)?.includes(itemId)
          )?.id;
          return dispatch(
            updateFlight({
              variables: {
                id: itemId,
                files: transportation[transportId]?.details
                  ?.find((flight) => flight.id === itemId)
                  ?.files?.filter((file) => file !== fileId),
              },
              extra: { transportId },
              attachedToType: 'Transportation',
              tripId,
            })
          );
        }
        return null;
      })().then(() => {
        setValue((prevVal) => prevVal.filter((item) => item.id !== itemId));
        if (
          attachedItemsArr?.length === 0 &&
          fileRelations?.filter((relation) => relation.attachedToId === itemId)
            .length === 0
        ) {
          dispatch(
            updateTrip({
              variables: {
                id: tripId,
                files: [...(tripFiles || []), fileId],
              },
            })
          );
        }
      });
    });
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return (
      <div {...other} className={classes.AutoCompleteListboxPopper}>
        {props.children}
        {arrCompare(itemsArr, attachedItemsArr) ? (
          <ButtonDefault
            startIcon={<AttachFile />}
            className={classes.attachButton}
            disabled>
            Attach file
          </ButtonDefault>
        ) : (
          <ButtonDefault
            startIcon={<AttachFile />}
            className={classes.attachButton}
            onClick={() => handleAttach()}>
            Attach file
          </ButtonDefault>
        )}
      </div>
    );
  }

  return (
    <>
      <Box onClick={handleClick} className={classes.attachItemsButton}>
        {attachedItems.length > 0 ? (
          attachedItems.map((item) => {
            return (
              <Chip
                key={item.id}
                size="small"
                label={item.title}
                className={classes.chip}
                variant="outlined"
                onDelete={() => handleDelete(item.id, item.type)}
                deleteIcon={<Close />}
              />
            );
          })
        ) : (
          <Typography className={classes.emptyAttachmentText}>
            Click to search activity
          </Typography>
        )}
      </Box>
      <Popper
        id={id}
        open={openPopper}
        anchorEl={anchorElm}
        className={classes.attachPopper}
        placement="bottom-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -39],
            },
          },
        ]}>
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              onClose={(event, reason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              disableCloseOnSelect
              value={val}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  event.key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setValue(newValue);
              }}
              PopperComponent={PopperComponent}
              options={tripItemsList}
              getOptionLabel={(option) => option.title}
              noOptionsText="No items to attach"
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  className={classes.attachmentOption}
                  key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              groupBy={(option) => option.type}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  placeholder="Search activities..."
                  className={classes.searchInput}
                  sx={{ width: `${anchorElm?.offsetWidth}px` }}
                />
              )}
              ListboxProps={{
                sx: { padding: 0 },
              }}
              classes={{
                paper: classes.AutoCompleteListbox,
                groupLabel: classes.sectionLabel,
                groupUl: classes.sectionUl,
              }}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

const filesListStyles = makeStyles(() => ({
  headerText: {
    fontSize: 12,
    color: '#4E4E4E',
    textTransform: 'uppercase',
    padding: '0 4px',
  },
  mainText: {
    fontSize: 14,
    color: '#000',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  secondaryText: {
    fontSize: 14,
    color: '#8A8A8A',
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '6px 12px',
  },
  nameRow: {
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  nameRowFocused: {
    background: '#FFFFFF',
    boxShadow: 'inset 0px 0px 0px 2px #F4F4F4',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  filesListContainer: {
    // 100vh - (static space above the files list + navbar height + static padding below the files list).
    maxHeight: 'calc(100vh - 234px - 60px)',
    overflowY: 'auto',
  },
  fileContainer: {
    borderBottom: '1px solid #DEDDDD',
    padding: '4px 0px',
  },
  fileWrapper: {
    borderRadius: 4,
    padding: '0 12px',
  },
  flexRow: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '8px 4px',
  },
  moreVertButton: {
    padding: 2,
  },
  moreVertIcon: {
    width: 16,
    height: 16,
  },
  fileLogo: {
    marginRight: 9,
    height: 20,
    width: 20,
    viewBox: '0 0 20 20',
    minWidth: 20,
  },
  fileAttachTrigger: {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    padding: '0 4px',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  renameInput: {
    padding: 0,
    fontSize: 14,
  },
}));

function FileRow({ file, handleTriggerDeleteFile, tripId, tripFiles }) {
  const classes = filesListStyles();
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const [optionsMenuAnchor, setOptionsMenuAnchor] = useState(false);
  const [isTextFocused, setIsTextFocused] = useState(false);
  const [fileName, setFileName] = useState(file.name?.split('.')[0]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // remove the parameters from the file link url and extract the file extension
  const url = file.url.split('?')[0];
  const extension = url.split('.').pop();

  const Logo = getFileIcon(extension);

  const handleNameSubmit = () => {
    if (file.name !== `${fileName}.${file.name.split('.')[1]}`) {
      dispatch(
        updateFile({
          variables: {
            id: file.id,
            name: `${fileName}.${file.name.split('.')[1]}`,
          },
        })
      );
      phTrackEvent({ event: EVENTS.TRIP_FILES.FILE_NAME_UPDATE });
    }
  };

  return (
    <Grid
      item
      container
      className={classes.fileContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <Grid container className={classes.fileWrapper}>
        <Grid
          item
          xs={4}
          className={`${classes.flexRow} ${classes.nameRow} ${
            (isTextFocused && classes.nameRowFocused) || ''
          }`}
          {...(!isTextFocused
            ? {
                onClick: () => {
                  dispatch(
                    actions.Files.setFilePreview({
                      extension,
                      ...file,
                    })
                  );
                },
              }
            : {})}>
          <Logo className={classes.fileLogo} />
          {!isTextFocused ? (
            <Typography className={classes.mainText}>{file.name}</Typography>
          ) : (
            <ClickAwayListener
              onClickAway={() => {
                handleNameSubmit();
                setIsTextFocused(false);
              }}>
              <form
                onSubmit={() => {
                  handleNameSubmit();
                  setIsTextFocused(false);
                }}>
                <InputBase
                  value={fileName?.split('.')[0]}
                  classes={{
                    input: classes.renameInput,
                  }}
                  inputRef={(input) => input && input.focus()}
                  inputProps={{ 'aria-label': 'Rename File' }}
                  onChange={(e) => {
                    setFileName(e.target.value);
                  }}
                />
              </form>
            </ClickAwayListener>
          )}
        </Grid>
        <Grid item xs={5} className={classes.fileAttachTrigger}>
          {/* <Typography className={classes.secondaryText}>
            {file.attachedToText}
          </Typography> */}
          <ItemAttachment
            tripId={tripId}
            fileId={file?.id}
            tripFiles={tripFiles}
          />
        </Grid>
        <Grid item xs={3} className={classes.flexRow}>
          {file.createdBy && (
            <UserAvatar
              user={file.createdBy}
              index={0}
              avatarProps={{
                style: {
                  marginRight: 8,
                  height: 16,
                  width: 16,
                  fontSize: 8,
                },
              }}
            />
          )}
          <Typography className={classes.secondaryText}>
            {`${file?.createdBy?.firstName || ''} ${
              file?.createdBy?.lastName || ''
            }`}
          </Typography>
          {isHovered ? (
            <IconButton
              className={classes.moreVertButton}
              onClick={(e) => setOptionsMenuAnchor(e.currentTarget)}>
              <MoreHorizRounded
                className={classes.moreVertIcon}
                aria-controls={`${file.id}-file-menu`}
                aria-haspopup="true"
              />
            </IconButton>
          ) : null}
          <OptionsMenu
            id={`${file.id}-file-menu`}
            state={optionsMenuAnchor}
            setState={setOptionsMenuAnchor}
            callbacks={[
              () => {
                setIsTextFocused(true);
              },
              () => setDeleteModalOpen(true),
            ]}
            options={['Rename', 'Delete']}
            menuItemProps={{
              style: {
                fontSize: 12,
                padding: '4px 16px',
              },
            }}
            menuProps={{
              MenuListProps: {
                style: {
                  padding: '4px 0',
                },
              },
            }}
          />
          <DeleteModal
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            executeFunc={() => {
              handleTriggerDeleteFile(file);
              setDeleteModalOpen(false);
            }}
            alertText="Are you sure?"
            descriptionText="This action will detach your file from all itinerary items and remove your file from Pilot permanently."
            confirmText="Delete"
            cancelText="Cancel"
            emoji="⚠️"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function FilesList({ files, handleTriggerDeleteFile, tripId, tripFiles }) {
  const classes = filesListStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: 26 }}>
      <Grid item container className={classes.headerContainer}>
        <Grid item xs={4}>
          <Typography className={classes.headerText}>File Name</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography className={classes.headerText}>Attached To</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.headerText}>Uploaded by</Typography>
        </Grid>
      </Grid>
      <Grid item container className={classes.filesListContainer}>
        {files?.map((file) => (
          <FileRow
            file={file}
            key={file.id}
            handleTriggerDeleteFile={handleTriggerDeleteFile}
            tripId={tripId}
            tripFiles={tripFiles}
          />
        ))}
      </Grid>
    </Grid>
  );
}

function FilesTab({ trip }) {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
  const dispatch = useDispatch();
  const files = useSelector(
    (state) =>
      Object.values(state.Files.files).filter(
        (file) => file?.tripId === trip?.id
      ),
    shallowEqual
  );

  const { Transportation, Files } = useSelector((state) => state);

  const handleTriggerDeleteFile = (file) => {
    handleFileDelete(file, trip?.id, dispatch, Transportation, Files);
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Helmet>
        <title>
          {trip?.title ? `${trip?.title} - Files | Pilot` : 'Files | Pilot'}
        </title>
      </Helmet>
      <Grid
        item
        className={classes.container}
        sx={{ marginTop: { xs: '10px', sm: 0 } }}>
        <Grid item style={{ flex: 1 }}>
          <FileSearchbar
            handleSubmit={(searchFilter) => setFilter(searchFilter)}
          />
        </Grid>
        <Grid item>
          <OutlinedButton
            onClick={() => setOpenFileUploadModal(true)}
            startIcon={<UploadIcon />}>
            Upload
          </OutlinedButton>
        </Grid>
        <FileUploadModal
          open={openFileUploadModal}
          handleClose={() => setOpenFileUploadModal(false)}
          attachedToID={trip.id}
          attachedFiles={trip?.files}
          attachFunc={(newFiles) => {
            return new Promise((resolve, reject) => {
              dispatch(
                updateTrip({
                  variables: {
                    id: trip?.id,
                    files: newFiles,
                  },
                })
              )
                .then((res) => resolve(res))
                .catch((err) => reject(err));
            });
          }}
          filesTab
        />
        <FilePreviewModal fileUploadTrigger={setOpenFileUploadModal} />
      </Grid>
      <Grid item mt={2}>
        <BookingList />
      </Grid>
      <Grid item className={classes.container} style={{ maxWidth: '100vw' }}>
        <FilesList
          files={files?.filter((file) =>
            file?.name?.toLowerCase()?.includes(filter?.toLowerCase())
          )}
          handleTriggerDeleteFile={handleTriggerDeleteFile}
          tripId={trip?.id}
          tripFiles={trip?.files}
        />
      </Grid>
    </Grid>
  );
}

export default FilesTab;
