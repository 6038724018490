/* eslint-disable */
import { format } from 'date-fns';
import { getRoomSplit, guestToStringV2 } from '../../bookingsUtils';
import config from '../../../../config';
import getCfConnector from '../../../../cfConnector';

export const getSearchParams = ({
  sessionId,
  date,
  latitude,
  longitude,
  guestCount,
  filters,
  pageIndex,
  isPaginatedSearch = false,
}) => {
  const checkInDate =
    date?.from || new Date(Date.now() + 2 * 24 * 60 * 60 * 1000);
  const checkOutDate =
    date?.to || new Date(Date.now() + 4 * 24 * 60 * 60 * 1000);

  const params = {
    sessionId,
    latitude: `${latitude}`,
    longitude: `${longitude}`,
    checkInDate: format(checkInDate, 'yyyy-MM-dd'),
    checkOutDate: format(checkOutDate, 'yyyy-MM-dd'),
    clientCountryCode: 'US',
    rooms: getRoomSplit(guestCount),
    filters,
    ...(isPaginatedSearch && {
      pageIndex,
      searchType: 'PAGE',
    }),
  };

  return params;
};

export const getHotelSearchResults = async ({
  latitude,
  longitude,
  title,
  date,
  guestCount,
  handleError,
  filters,
  pageIndex,
  isPaginatedSearch = false,
}) => {
  const { location } = window;
  const sessionId = window.localStorage.getItem('searchSessionId');

  // TODO: Make search params in line with search props
  const urlParams = new URLSearchParams(location.search);
  const searchProps = {
    p: title,
    ll: `${latitude},${longitude}`,
    checkInDate: date?.from && format(date?.from, 'yyyy-MM-dd'),
    checkOutDate: date?.to && format(date?.to, 'yyyy-MM-dd'),
    rooms: guestToStringV2(guestCount),
  };

  Object.keys(searchProps).forEach((prop) => {
    if (searchProps[prop]) {
      urlParams.set(prop, searchProps[prop]);
    }
  });

  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${urlParams.toString()}`
  );

  try {
    const searchParams = getSearchParams({
      latitude,
      longitude,
      date,
      guestCount,
      filters,
      pageIndex,
      ...(isPaginatedSearch && { sessionId }),
      isPaginatedSearch,
    });
    const url = `${config.bookingsAPI}/search`;

    const response = (await (await getCfConnector()).post(url, searchParams))
      .data;
    if (response?.sessionId)
      window.localStorage.setItem('searchSessionId', response.sessionId);
    return response;
  } catch (err) {
    handleError(err);
  }
  return null;
};

export const getBookingParams = ({
  packageId,
  guests,
  hotelId,
  rooms,
  packageRooms,
}) => {
  const leadUser = guests?.[0];
  const packageRoomIds = packageRooms?.map((r) => r.roomId);
  const sessionId = window.localStorage.getItem('searchSessionId');
  const roomSplit = packageRooms?.map((r) => ({
    adults: r.adultsCount,
    children: r.childrenAges,
  }));
  const users = [];
  roomSplit?.forEach((room, idx) => {
    const { adults, children } = room;
    new Array(adults)?.fill(0)?.forEach(() => {
      users.push({
        ...(guests?.length > idx ? guests[idx] : leadUser),
        id: `${leadUser?.id}${users?.length === 0 ? '' : users?.length}`,
        isLeadUser: users?.length === 0,
        assignedRoomId: packageRoomIds[idx],
        type: 0,
        ...(guests?.length - 1 >= idx
          ? {
              firstName: guests[idx]?.firstName,
              lastName: guests[idx]?.lastName,
            }
          : {}),
      });
    });
    new Array(children?.length)?.fill(0)?.forEach((_, index) => {
      users.push({
        ...leadUser,
        id: `${leadUser?.id}${users?.length}`,
        isLeadUser: false,
        assignedRoomId: packageRoomIds[idx],
        type: 1,
        age: children[index],
      });
    });
  });
  return {
    sessionId,
    packageId,
    hotelId,
    users,
    roomsCount: roomSplit?.length,
  };
};

export const cancelBooking = async ({ bookingId, cancellationReason }) => {
  try {
    const url = `${config.bookingsAPI}/cancel`;
    const params = { bookingId, cancellationReason };
    const response = (await (await getCfConnector()).post(url, params)).data;
    return response;
  } catch (err) {
    return null;
  }
};

export const makeBookingRequest = async ({
  packageId,
  users,
  hotelId,
  sessionId,
  checkoutSessionId,
}) => {
  try {
    const url = `${config.bookingsAPI}/book`;
    const params = { packageId, users, hotelId, sessionId, checkoutSessionId };
    const response = (await (await getCfConnector()).post(url, params)).data;
    return response;
  } catch (err) {
    return null;
  }
};
