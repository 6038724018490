import { Box, Typography, Stack } from '@mui/material';
import { Triangle } from './Icon';

const trianglePositionStyles = {
  top: {
    bottom: '-26px',
    left: '50%',
    transform: 'rotate(180deg)',
  },
  bottom: {
    top: '-26px',
    left: '45%',
  },
  center: {
    visibility: 'hidden',
  },
};

function GuideStep({
  heading,
  description,
  footer,
  position = 'top',
  currentStep,
  totalSteps,
  arrowStyles = {},
  containerStyles = {},
}) {
  return (
    <Box
      bgcolor="#fff"
      borderRadius="20px"
      pt="20px"
      px="16px"
      pb="16px"
      position="relative"
      mt={position === 'bottom' ? '26px' : '0px'}
      mb={position === 'top' ? '26px' : '0px'}
      width="300px"
      {...containerStyles}>
      <Box
        position="absolute"
        sx={{
          ...trianglePositionStyles[position],
          ...arrowStyles,
        }}>
        <Triangle />
      </Box>
      <Stack gap="8px">
        <Typography textAlign="left" fontWeight="bold" fontSize={18}>
          {heading}
        </Typography>
        <Typography fontSize={14}>{description}</Typography>
        <Typography pt="2px" fontSize={12} fontStyle="italic">
          {footer}
        </Typography>
        <Stack color="#8A8A8A" direction="row" alignSelf="flex-end">
          <Typography fontSize={12}>
            Tip {currentStep} / {totalSteps}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default GuideStep;
