import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import BlockHeader from './BlockHeader';
import { useMapUtils } from '../MapUtils';
import { updateLocation } from '../../../redux/slices/Location';
import classList from '../../classList';
import { removeTimezoneOffset, parseISODate } from '../../../utils';
import { useSounds, SOUNDS } from '../../../sounds';
import useTour from '../../molecules/Tour/useTour';
import { stepName } from '../../../assets/onboarding/steps';

const useItineraryHeaderStyles = makeStyles((theme) => ({
  titleInput: {
    ...theme.typography.h2,
    fontSize: '24px',
    paddingRight: '1em',
    width: '100%',
  },
}));

function ItineraryHeader({
  location,
  isCollapsed,
  setCollapsed,
  handleDelete,
  handleAdd,
  dragHandleProps,
  locationRef,
  isDragging,
  isSectionHighlight,
  citySuggestion,
}) {
  const classes = useItineraryHeaderStyles();
  const dispatch = useDispatch();
  const { playSound } = useSounds();
  const { getTour } = useTour();
  const { createMapPinForPlaceId, handleLocationSelect } = useMapUtils();

  // callback to update the title onBlur
  const updateHeaderTitle = async (title, placeId = null) => {
    if (title) {
      if (location?.name === title || title === '') return;

      let mapPinId;
      if (location?.mapPin && placeId) {
        mapPinId = (
          await handleLocationSelect(placeId, location?.mapPin, 'LOCATION')
        )?.id;
      } else if (placeId) {
        mapPinId = await createMapPinForPlaceId(placeId, 'LOCATION');
      }
      playSound(SOUNDS.mapZoom);
      try {
        await dispatch(
          updateLocation({
            variables: {
              id: location.id,
              name: title,
              mapPin: mapPinId,
              oldMapPin: location.mapPin?.id,
            },
          })
        );
        getTour().onActiveStep(stepName.SET_HEADING).openTour();
      } catch (err) {
        // handle error here
      }
    }
  };

  // callback to update the location start date and end date
  const updateLocationDate = async (event) => {
    const newStartDate = event?.from === undefined ? null : event.from;
    const newEndDate = event?.to === undefined ? null : event.to;
    try {
      dispatch(
        updateLocation({
          variables: {
            id: location.id,
            arrivalDate:
              newStartDate && removeTimezoneOffset(newStartDate)?.toISOString(),
            departureDate:
              newEndDate && removeTimezoneOffset(newEndDate)?.toISOString(),
          },
        })
      );
    } catch (err) {
      // handle error here
    }
  };

  return (
    <BlockHeader
      updateDate={updateLocationDate}
      updateTitle={updateHeaderTitle}
      handleDelete={handleDelete}
      handleAdd={handleAdd}
      inputRef={locationRef}
      dragHandleProps={dragHandleProps}
      defaultTitle={location?.name}
      inputProps={{
        placeholder: citySuggestion?.current?.suggestion
          ? `Try "${citySuggestion.current.suggestion}"`
          : 'Where do you want to visit?',
        name: 'locationName',
        className: classes.titleInput,
      }}
      classIdentifier={classList.itineraryHeader}
      location={location.id}
      isCollapsed={isCollapsed}
      setCollapsed={setCollapsed}
      iconDatePickerProps={{
        useRange: true,
        defaultDate: {
          from: location?.arrivalDate
            ? parseISODate(location.arrivalDate, location?.version)
            : null,
          to: location?.departureDate
            ? parseISODate(location.departureDate, location?.version)
            : null,
        },
        placement: 'bottom-end',
      }}
      mapPin={location.mapPin}
      isDragging={isDragging}
      isSectionHighlight={isSectionHighlight}
    />
  );
}

export default ItineraryHeader;
