import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import { styled } from '@mui/styles';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import CHECKOUT_STEPS from './constants';

const CustomStepConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
    zIndex: -1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ED702E',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ED702E',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#D9D9D9',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function CheckoutProgress({ activeStep }) {
  const steps = Object.values(CHECKOUT_STEPS) || [];
  return (
    <Stepper
      activeStep={steps.indexOf(activeStep)}
      alternativeLabel
      connector={<CustomStepConnector />}>
      {steps.map((label) => (
        <Step
          key={label}
          sx={{
            '& text': {
              display: 'none',
            },
            '& .MuiStepIcon-root.Mui-active': {
              color: '#ED702E',
            },
            '& .MuiStepIcon-root.Mui-completed': {
              color: '#ED702E',
            },
            '& .MuiStepIcon-root': {
              color: '#D9D9D9',
            },
          }}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default CheckoutProgress;
