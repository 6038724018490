import { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {
  EventNoteOutlined,
  GroupOutlined,
  HotelOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import CancelNavbar from '../organisms/Stays/V2/CancelNavbar';
import { getNumberOfNights } from '../organisms/Stays/bookingsUtils';
import { getCurrencyFormatter } from '../organisms/Stays/HotelDetails/utils';
import { cancelBooking } from '../organisms/Stays/V2/api';
import actions from '../../redux/actions';
import { EVENTS, phTrackEvent } from '../../analytics';

const useStyles = makeStyles(() => ({
  roomImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    background: 'linear-gradient(180deg, #000000 0%, #000000 20%)',
  },
  box: {
    border: '1px solid #D9D9D9',
    borderRadius: 8,
    overflow: 'hidden',
    height: 140,
  },
}));

function CancelReason({ setCancelReason, handleNext }) {
  const [selectedReason, setSelectedReason] = useState(0);
  const [otherReason, setOtherReason] = useState('');
  const reasons = [
    'My trip has been cancelled',
    'The booking is too expensive',
    'I want to add/remove guests',
    'I want to change the booking dates',
    'Other',
  ];

  const handleNextStep = () => {
    if (!selectedReason && selectedReason !== 0) return;
    phTrackEvent({
      event: EVENTS.CHECKOUT_CANCEL_BOOKING.REVIEW_CANCELLATION_CLICK,
    });
    setCancelReason(
      selectedReason === 4 ? otherReason : reasons[selectedReason]
    );
    handleNext();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 80px)',
        padding: '90px 0px',
      }}>
      <div
        style={{
          padding: '40px',
          height: '100%',
          maxWidth: '664px',
          width: '100%',
          marginTop: '10vh',
          margin: '0 auto',
          border: '1px solid #D9D9D9',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Typography variant="h2" gutterBottom>
            Why do you need to cancel?
          </Typography>
          <RadioGroup sx={{ marginTop: 4 }} value={selectedReason}>
            {reasons?.map((reason, idx) => (
              <FormControlLabel
                value={idx}
                onChange={() => setSelectedReason(idx)}
                control={<Radio />}
                labelPlacement="start"
                sx={{
                  justifyContent: 'space-between',
                  margin: 0,
                }}
                label={reason}
              />
            ))}
          </RadioGroup>
          {selectedReason === 4 && (
            <TextField
              id="otherReason"
              placeholder="Reason for cancellation"
              fullWidth
              defaultValue={otherReason}
              multiline
              rows={3}
              inputProps={{
                maxLength: 300,
                style: { fontSize: 14 },
              }}
              minRows={5}
              sx={{
                padding: 0,
                mt: 1,
                backgroundColor: '#FCFCFE',
                '& .MuiFormHelperText-contained': {
                  marginLeft: 0,
                  marginRight: 0,
                },
              }}
              onChange={(e) => {
                setOtherReason(e.target.value);
                phTrackEvent({
                  event: EVENTS.CHECKOUT_CANCEL_BOOKING.OTHER_OPTION_UPDATE,
                });
              }}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flex: 0,
            justifyContent: 'flex-end',
            width: '100%',
          }}>
          <Button
            style={{
              padding: '8px 24px',
              marginLeft: 'auto',
            }}
            onClick={handleNextStep}
            disabled={
              !(selectedReason === 4 ? otherReason : reasons[selectedReason])
            }>
            Review cancellation
          </Button>
        </div>
      </div>
    </div>
  );
}

function RoomSummary({ booking }) {
  const classes = useStyles();

  const checkInDate = booking?.bookingDetails?.checkInDate
    ? new Date(booking?.bookingDetails?.checkInDate)
    : null;
  const checkOutDate = booking?.bookingDetails?.checkOutDate
    ? new Date(booking?.bookingDetails?.checkOutDate)
    : null;

  return (
    <Grid container className={classes.box}>
      <Grid item xs={4}>
        <img
          src={booking?.bookingDetails?.image}
          alt="hotel-img"
          style={{
            objectFit: 'cover',
          }}
          className={classes.roomImage}
        />
      </Grid>
      <Grid container xs={8} flexDirection="column" p={4} pb={2}>
        <Typography variant="h2">{booking?.bookingDetails?.name}</Typography>
        <Box display="flex" alignItems="center" mt={1}>
          {checkInDate && checkOutDate && (
            <Box display="flex" alignItems="center">
              <EventNoteOutlined sx={{ mr: 1 }} />
              <Typography variant="body1" color="text.secondary">
                {format(checkInDate, 'MMM dd') !==
                format(new Date(checkOutDate), 'MMM dd')
                  ? `${format(new Date(checkInDate), 'MMM dd')} - ${format(
                      new Date(checkOutDate),
                      'MMM dd'
                    )}`
                  : `${format(new Date(checkInDate), 'MMM dd')} - ${format(
                      new Date(checkOutDate),
                      'dd'
                    )}`}
              </Typography>
            </Box>
          )}

          <Box display="flex" alignItems="center" ml={3}>
            <GroupOutlined sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {booking?.bookingDetails?.users?.length} guests
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" ml={3}>
            <HotelOutlined sx={{ mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              {booking?.bookingDetails?.rooms?.length} room
              {booking?.bookingDetails?.rooms?.length > 1 ? 's' : ''}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

function CancellationSummary({ booking, onComplete }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const totalPrice = booking?.bookingDetails?.price?.total;
  const currency = booking?.bookingDetails?.price?.currency;

  const numberOfNights = getNumberOfNights({
    from: booking?.bookingDetails?.checkInDate,
    to: booking?.bookingDetails?.checkOutDate,
  });

  const isRefundableBooking = booking?.bookingDetails?.cxlPolicy?.isRefundable;
  const isRefundable =
    isRefundableBooking &&
    new Date(booking?.bookingDetails?.cxlPolicy?.refundableUntil).getTime() >
      new Date().getTime();

  const cancellationFee = isRefundable ? 0 : totalPrice;

  const handleCancelButtonClick = async () => {
    setLoading(true);
    phTrackEvent({
      event: EVENTS.CHECKOUT_CANCEL_BOOKING.CONFIRM_CANCELLATION_CLICK,
      meta: {
        bookingId: booking?.id,
      },
    });
    const result = await onComplete();
    if (result === 'ERROR') {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div>
      <div
        style={{ padding: 24, border: '1px solid #D9D9D9', borderRadius: 8 }}>
        <Typography variant="h2">Confirm cancellation</Typography>
        <div style={{ marginTop: '12px' }} />
        <Box display="flex" mb={2}>
          <Box>
            <Typography variant="caption" sx={{ fontWeight: 400 }}>
              You&apos;ve paid
            </Typography>
            <Typography variant="h2">
              {getCurrencyFormatter(currency).format(totalPrice.toFixed(2))}
            </Typography>
          </Box>
          <Box ml={4}>
            <Typography
              variant="caption"
              sx={{ fontWeight: 400 }}
              color="success.main">
              Your refund
            </Typography>
            <Typography variant="h2">
              {getCurrencyFormatter(currency).format(
                (totalPrice - cancellationFee).toFixed(2)
              )}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="caption" color="text.light">
            Original reservation
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={0.2}>
            <Typography variant="h5">
              {getCurrencyFormatter(currency).format(
                (totalPrice / numberOfNights).toFixed(2)
              )}{' '}
              x {numberOfNights} night{numberOfNights > 1 ? 's' : ''}
            </Typography>
            <Typography variant="h5">
              {getCurrencyFormatter(currency).format(totalPrice.toFixed(2))}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <Typography variant="caption" color="text.light">
            Cancellation fee
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={0.2}>
            <Typography variant="h5">
              {getCurrencyFormatter(currency).format(
                (cancellationFee / numberOfNights).toFixed(2)
              )}{' '}
              x {numberOfNights} night{numberOfNights > 1 ? 's' : ''}
            </Typography>
            <Typography variant="h5">
              -
              {getCurrencyFormatter(currency).format(
                cancellationFee.toFixed(2)
              )}
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="caption"
          color="text.light"
          display="flex"
          alignItems="center"
          mt={2}>
          <InfoOutlined sx={{ mr: 1, fontSize: 14 }} />
          Cancellation policy:{' '}
          {isRefundableBooking
            ? `100% full refund until ${format(
                new Date(booking?.bookingDetails?.cxlPolicy?.refundableUntil),
                'MMM dd, yyyy hh:mm a (z)'
              )}`
            : '100% of the cancellation fee, no refund'}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box
          display="flex"
          justifyContent="space-between"
          mt={2}
          alignItems="center">
          <Typography variant="h2">Total refund</Typography>
          <Typography variant="h2">
            {getCurrencyFormatter(currency).format(
              (totalPrice - cancellationFee).toFixed(2)
            )}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ mt: 2, display: 'block' }}>
          Your reservation will be cancelled immediately and you will receive
          your refund shown above to your original payment method within 5-10
          business days.
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0,
          justifyContent: 'flex-end',
          width: '100%',
          marginTop: '12px',
          marginLeft: 'auto',
          alignItems: 'center',
        }}>
        {error && (
          <Typography variant="caption" color="error" mr={2}>
            Something went wrong, try cancelling again, or contact support.
          </Typography>
        )}
        <Button
          style={{
            padding: '8px 40px',
            minWidth: 185,
          }}
          onClick={handleCancelButtonClick}
          disabled={loading}>
          {loading ? (
            <CircularProgress size={24} sx={{ color: '#FFF' }} />
          ) : (
            'Cancel booking'
          )}
        </Button>
      </div>
    </div>
  );
}

function CancelConfirmation({ booking, onComplete }) {
  return (
    <div
      style={{
        maxWidth: '694px',
        margin: '20px auto 0 auto',
        paddingTop: '16px',
      }}>
      <Typography variant="h2">Your selection</Typography>
      <div style={{ marginTop: '16px' }} />
      <RoomSummary booking={booking} />
      <div style={{ marginTop: '24px' }} />
      <CancellationSummary booking={booking} onComplete={onComplete} />
    </div>
  );
}

function CancelBooking() {
  const CANCEL_BOOKING_STEPS = {
    CANCEL_REASON: 0,
    CANCEL_CONFIRMATION: 1,
  };

  const { bookingId, slug: tripId } = useParams();
  const [cancelReason, setCancelReason] = useState('');
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const tripBooking = useSelector((state) =>
    state.Trips?.trips[tripId]?.bookings?.find(
      (booking) => booking?.id === bookingId
    )
  );

  // eslint-disable-next-line
  const dispatch = useDispatch();

  const handleCancellation = async () => {
    try {
      const response = await cancelBooking({
        bookingId,
        cancellationReason: cancelReason,
      });
      if (response.error) {
        phTrackEvent({
          event: EVENTS.CHECKOUT_CANCEL_BOOKING.CANCEL_BOOKING_ERROR,
          meta: {
            bookingId,
          },
        });
        return 'ERROR';
      }

      if (response?.result?.success) {
        phTrackEvent({
          event: EVENTS.CHECKOUT_CANCEL_BOOKING.CANCEL_BOOKING_SUCCESS,
          meta: {
            bookingId,
          },
        });
        dispatch(actions.Trips.setActiveBookingId(bookingId));
        navigate(`/trips/${tripId}/planner?forceRefresh=true`);
        return 'SUCCESS';
      }
    } catch (e) {
      // handle error
    }
    phTrackEvent({
      event: EVENTS.CHECKOUT_CANCEL_BOOKING.CANCEL_BOOKING_ERROR,
      meta: {
        bookingId,
      },
    });
    return 'ERROR';
  };

  if (!tripId) {
    return <Navigate to="/trips" />;
  }
  if (!tripBooking) {
    return <Navigate to={`/trips/${tripId}/planner`} />;
  }

  return (
    <div>
      <CancelNavbar
        hotelName={tripBooking?.bookingDetails?.name}
        tripId={tripId}
      />
      {step === CANCEL_BOOKING_STEPS.CANCEL_REASON ? (
        <CancelReason
          setCancelReason={setCancelReason}
          cancelReason={cancelReason}
          handleNext={() => setStep(CANCEL_BOOKING_STEPS.CANCEL_CONFIRMATION)}
        />
      ) : (
        <CancelConfirmation
          booking={tripBooking}
          onComplete={handleCancellation}
        />
      )}
    </div>
  );
}

export default CancelBooking;
